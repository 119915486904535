import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FilterContainer, InputFilter, Option, SelectFilter, TD } from '../../../styles/Table.styled';
import DateFilter from '../../UI/DateFilter/DateFilter';
import { showColumn } from '../../../shared/utility'
function TableFilters(props) {
    const { showFilters, tasksFilters, filtersChange, applyFilters, columns } = props;
    const [show, setShow] = useState(null)
    const [dateField, setDateField] = useState('')
    const { t } = useTranslation();

    const inputChangeHandler = (e) => {
        console.log("inputChangeHandler")
        console.log(tasksFilters)
        let key = e.target.name;
        let newFilters = {
            ...tasksFilters,
            [key]: e.target.value
        }
        console.log(newFilters)
        filtersChange(newFilters);
        applyFilters(newFilters)
    }
    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])

    const closeModalHandler = () => {
        setShow(false);
    }
    const dateShowHandler = (field) => {
        setShow({
            [field]: true
        })
        setDateField(field);
    }
    const convertDate = (date) => {
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]), formatDate(+dateArray[1])]
    }

    let [dateInputValue, setDateInputValue] = useState({
        creationDate: tasksFilters?.creationDate ? convertDate(tasksFilters.creationDate) : ''
    });

    const getCreationdate = () => {
        return tasksFilters?.creationDate ? convertDate(tasksFilters.creationDate) : [];
    }

    const dateChangeHandler = (date) => {
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(date.startDate) + '-' + formatDate(date.endDate)
        })
        let key = date.field;
        let newFilters = {
            ...tasksFilters,
            [key]: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...tasksFilters,
            [field]: null
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    return (
        <>
            <tr className="tableFilters" style={{ display: showFilters ? 'table-row' : 'none' }}>

                <TD style={{ display: !showColumn(columns, "object") && 'none' }}>
                    <FilterContainer>

                        <InputFilter
                            placeholder={t("object")}
                            onChange={(e) => inputChangeHandler(e)}
                            value={tasksFilters?.object || ''}
                            name="object"
                            type="text"
                            autoComplete="off" />

                    </FilterContainer>
                </TD>
                {/* <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={tasksFilters?.countryCode || ''} name="countryCode" type="text" autoComplete="off" />
                    </div>
                </td> */}
                <TD style={{ display: !showColumn(columns, "processType") && 'none' }}>
                    <FilterContainer>
                        <SelectFilter
                            onChange={(e) => inputChangeHandler(e)}
                            value={tasksFilters?.processType || ''}
                            name="processType"
                        >
                            <Option value=""></Option>
                            <Option value="GFF">{t("processTypeOptions:supplierInvoiceManagment", "Gestion des factures fournisseurs")}</Option>
                            <Option value="NSA">{t("processTypeOptions:supplierReferencing", "Référencement")}</Option>
                            <Option value="PS">{t("processTypeOptions:recoveryManagment", "Parapheur")}</Option>
                        </SelectFilter>
                    </FilterContainer>
                </TD>
                <TD style={{ display: !showColumn(columns, "status") && 'none' }}>
                    <FilterContainer>
                        <SelectFilter
                            onChange={(e) => inputChangeHandler(e)}
                            value={tasksFilters?.status || ''}
                            name="status"
                        >
                            <Option value=""></Option>
                            <Option value="TO_BE_CONTROLLED">{t("invoiceStatus:TO_BE_CONTROLLED", "À contrôler")} </Option>
                            <Option value="TO_BE_VALIDATED">{t("invoiceStatus:TO_BE_VALIDATED", "À valider")}</Option>
                            <Option value="ARCHIVED">{t("invoiceStatus:ARCHIVED", "Archivé")}</Option>
                            <Option value="LITIGATION">{t("invoiceStatus:LITIGATION", "Litigation")}</Option>
                            <Option value="REJECTED">{t("invoiceStatus:REJECTED", "Rejetée")}</Option>
                            <Option value="DEADLINEEXCEEDED">{t("invoiceStatus:DEADLINEEXCEEDED", "Dépassement délai")}</Option>

                        </SelectFilter>
                    </FilterContainer>
                </TD>

                <TD style={{ display: !showColumn(columns, "creationDate") && 'none' }}>
                    <FilterContainer>
                        <DateFilter
                            modalTitle={t("invoiceList:creationDate", "Date de Création")}
                            from={getCreationdate()[0] || ''}
                            to={getCreationdate()[1] || ''}
                            name="creationDate"
                            activeReset={tasksFilters?.creationDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.creationDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) => dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                {/* <td></td> */}
                {/* <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={tasksFilters?.businessCode || ''} name="businessCode" type="text" autoComplete="off" />
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <input className="filter__input" onChange={(e) => inputChangeHandler(e)} value={tasksFilters?.businessName || ''} name="businessName" type="text" autoComplete="off" />
                    </div>
                </td>
                <td>
                    <div className="filterContainer">
                        <select className="form-control filter__select" onChange={(e) => inputChangeHandler(e)} value={tasksFilters?.receivesAttachment || ''} name="receivesAttachment" style={{height:"35px", fontSize: "13px"}}>
                            <option value=""></option>
                            <option value="1">{t("global:yes")}</option>
                            <option value="0">{t("global:no")}</option>
                        </select>
                    </div>
                </td>
                <td></td> */}
            </tr>
        </>
    )
}

export default TableFilters
