import * as actionTypes from './actionTypes';
import Axios from '../../axios-proas';

export const instanceStart = () => ({
    type: actionTypes.INSTANCE_START
})

export const instanceSetPage = (page) => ({
    type: actionTypes.INSTANCE_SET_PAGE,
    currentPage: page
})

export const instanceSetPageSize = (pageSize) => ({
    type: actionTypes.INSTANCE_SET_PAGESIZE,
    pageSize: pageSize
})

export const instanceSuccess = (data, count) => ({
    type: actionTypes.INSTANCE_SUCCESS,
    data: data,
    count: count
});

export const instanceFail = (error) => ({
    type: actionTypes.INSTANCE_FAIL,
    error: error
})

export const instanceGetPage = (page, filters, sort, reverse, pageSize) => {
    let newFilters = {
        page: page,
        pageSize: pageSize
    }
    if (filters) {
        for (const key in filters) {
            if (filters[key])
                newFilters = {
                    ...newFilters,
                    [key]: filters[key]
                }
        }
    }
    if (sort) {
        newFilters = {
            ...newFilters,
            orderBy: sort
        }
    }
    if (reverse) {
        newFilters = {
            ...newFilters,
            reverse: reverse[sort]
        }
    }
    //console.log(sort)
    return dispatch => {
        dispatch(instanceStart());
        Axios.get('/entity//instance?type=owner', {
            params: { ...newFilters }
        }).then(response => {
            dispatch(instanceSuccess(response.data.result, response.data.count))
        }).catch(err => {
            dispatch(instanceFail(err.response?.data?.error))
        })
    }
}


export const instanceSetFilterQuery = (filtersQuery) => ({
    type: actionTypes.INSTANCE_SET_FILTERSQUERY,
    filters: filtersQuery,
    filtering: instanceUpdateFiltering(filtersQuery)
});

export const instanceUpdateFiltering = (filtersQuery) => {
    let filter = false;
    if (filtersQuery) {
        for (const key in filtersQuery) {
            if (filtersQuery[key]) {
                filter = true
            }
        }
    }
    return filter
}

export const instanceUpdateShowFilters = (show) => ({
    type: actionTypes.INSTANCE_UPDATE_SHOWFILTERS,
    showFilters: show
});

export const instanceSetSortQuery = (sortQuery) => ({
    type: actionTypes.INSTANCE_SET_SORTQUERY,
    sortQuery: sortQuery
})

export const instanceSetReverseSort = (reverse) => ({
    type: actionTypes.INSTANCE_SET_REVERSESORT,
    reverse: reverse
})