import React, { useMemo } from 'react'
import Header from '../../../components/Header/Header'
import DocumentationView from '../../../components/Documentation/DocumentationVisualisation/DocumentationView'
import { AppBody, AppContainer, AppContent } from '../../../styles/App.styled';
import { Navigate, Route, useLocation, Routes, useNavigate } from 'react-router-dom';
import Menu from '../../Menu/Menu'
import EditPassword from '../../EditPassword/EditPassword';
import ReferentielFournisseur from '../../ReferentielFournisseur/ReferentielFournisseur';
import Entity from '../../Entity/Entity';
import EntityPPF from '../../EntityPPF/EntityPPF';
import UserForm from '../../UserForm/UserForm';
import ToDoListe from '../../ToDo/ToDo';
import SupplierAccountRequest from '../../SupplierAccountRequest/SupplierAccountRequest';
import ReferentielClient from '../../ReferentielClient/ReferentielClient';
import SuiviInvoices from '../../SuiviInvoices/SuiviInvoices';
import SuiviCommands from '../../SuiviCommands/SuiviCommands';
import PaymentSignature from '../../PaymentSignature/PaymentSignature';
import Documentation from '../../Documentation/Documentation';
import NewDocumentation from '../../NewDocumentation/NewDocumentation';
import UploadInvoice from '../../UploadInvoice/UploadInvoice';
import InvoiceDetail from '../../InvoiceDetail/InvoiceDetail';
import CommandesDeadlineExceededDetail from '../../CommandesDeadlineExceededDetail/CommandesDeadlineExceededDetail'
import InvoiceIntegratedDetail from '../../InvoiceIntegratedDetail/InvoiceIntegratedDetail';
import Extraction from '../../Extraction/Extraction';
import ExtractionForm from '../../ExtractionForm/ExtractionForm';
import Campaigns from '../../Campaigns/Campaigns';
import CampaignForm from '../../CampaignForm/CampaignForm';
import EntitiesManager from '../../EntitiesManager/EntitiesManager';
import EmailsSettings from '../../EmailsSettings/EmailsSettings';
import Settings from '../../Settings/Settings';
import KPISupplier from '../../KPISupplier/KPISupplier';
import Home from '../../Home/Home';
import HomeIcon from '@mui/icons-material/Home';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import BadgeIcon from '@mui/icons-material/Badge';
import ArticleIcon from '@mui/icons-material/Article';
import SaveIcon from '@mui/icons-material/Save';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useTranslation } from 'react-i18next';
import ClientHome from '../../ClientHome/KpiClient'
import HomeSupplier from '../../HomeSupplier/KpiSupplier';
import UploadDoc from '../../UploadDocument/UploadDoc';
import Reporting from '../../Ereporting/Ereporting'
import EreportingInvoices from '../../EreportingInvoices/EreportingInvoices';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SupplierRequestDetail from '../../../containers/SupplierRequestDetail/SupplierRequestDetail';
import InvoiceCommand from '../../InvoiceCommand/InvoiceCommand';
import PDPDashboard from '../../../containers/PDPDashboard/Home';
import PaymentSignatureDetail from '../../../containers/PaymentSignatureDetail/PaymentSignatureDetail';
import commandeDetail from '../../CommandeDetail/CommandeDetail';
import CommandesDeadlineExceeded from '../../CommandesDeadlineExceeded/CommandesDeadlineExceeded';
import CommandeDetail from '../../CommandeDetail/CommandeDetail';
import ReferentielPPF from '../../ReferentielPPF/ReferentielPPF'
import EchangePDP from '../../EchangePDP/EchangePDP';
import EchangeDetail from '../../EchangeDetail/EchangeDetail';
import ViewInArTwoToneIcon from '@mui/icons-material/ViewInArTwoTone';
import ConsultationLogs from "../../ConsultationLogs/ConsultationLogs"; import LogTypeDetail from "../../../containers/LogTypeDetail/LogTypeDetail";
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'
import LogDetail from "../../../containers/LogDetail/LogDetail";// import WalletIcon from '@mui/icons-material/Wallet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InstanceManagment from '../../InstanceManagment/InstanceManagment';
import UserManagment from '../../UserManagment/UserManagment';
// import AddNewUser from '../../AddNewUser/AddNewUser';


function OwnerRoot({ userLevel, userType }) {
    const { t } = useTranslation();

    const menuItems = useMemo(() => (

        [
            { isLink: true, key: 'Accueil', label: t("menu:home", 'Accueil'), pathname: '/', icon: <HomeIcon /> },
            { isLink: true, key: 'Mes tâches', label: t('global:tasks', 'Mes tâches'), pathname: '/ToDoListe', icon: <FontAwesomeIcon icon={faClipboardList} style={{ fontSize: "1.4rem", marginLeft: "4px" }} /> },
            {
                isLink: false, label: t('global:supplierN', 'Fournisseurs'), key: 'supplier', icon: <MoveToInboxIcon />, subMenu: [
                    { path: '/referentielFournisseur', hidden: false, label: t('global:reference', 'Référentiel') },
                    { path: '/fournisseurInvoices', hidden: false, label: t('invoiceList:invoiceManagment', 'Suivi des factures') },
                    "'validator".includes(userLevel) && { path: '/fournisseurInvoicesIntegrated', hidden: false, label: t('invoiceList:invoiceValidation', 'Validation des factures') },
                    { path: '/fournisseurInvoicesConciliation', hidden: false, label: t('invoiceList:invoiceControl', 'Contrôle des factures') },

                    { path: '/kpiSupplier', hidden: false, label: t('navbar:kpi', 'KPI') },
                    { path: '/suppliersAccountRequest', hidden: false, label: t('invoiceDetail:referenceRequest', 'Demande de référencement') },
                    // { path: '/embarquementSupplier', hidden: false, label: t('invoiceDetail:boarding', 'Embarquement') },
                    { path: '/fournisseurCommandes', hidden: false, label: t('invoiceDetail:commandManagment', 'Suivi des commandes') },

                ]
            },
            {
                isLink: false, label: t("client:customers", 'Client'), key: 'client', icon: <BadgeIcon />, subMenu: [
                    { path: '/referentielClient', hidden: false, label: t('global:reference', 'Référentiel') },
                    { path: '/clientInvoices', hidden: false, label: t('invoiceList:invoiceManagment', 'Suivi des factures') },
                    { path: '/clientCommandesDeadlineExceeded', hidden: false, label: t("global:recovery", 'Recouvrement') },
                    { path: '/kpiClient', hidden: false, label: t('navbar:kpi', 'KPI') },
                    // { path: '/embarquementClient', hidden: false, label: t('invoiceDetail:boarding', 'Embarquement') }
                ]
            },

            "superAccountant, accountant".includes(userLevel) && { isLink: true, key: 'Parapheur', label: t("menu:paymentTerminal", 'Parapheur'), pathname: '/paymentSignature', icon: <ArticleIcon /> },
            {
                isLink: false, label: 'PDP', pathname: '/pdpdashboard', key: 'pdp', isDiff: true, icon: <AdminPanelSettingsIcon />, subMenu: [
                    { path: '/pdpdashboard', hidden: false, hidden: true, label: t('navbar:emails', 'Dashboard') },
                    { path: '/annuairePlateFormePublic', hidden: false, label: t("invoiceEvent:PPFReference", 'Annuaire PPF') },
                    { path: '/annuairesppflogs', hidden: false, label: t('navbar:elogs', 'Logs') },
                    { path: '/annuaireechangespdpppf', hidden: false, label: t('menu:ppfExchange', 'Échanges PDP PPF') }
                ]
            },
            { isLink: true, key: 'Documentation', label: 'Documentation', pathname: '/documentation', icon: <ArticleIcon /> },
            // {
            //     isLink: false, label: 'Communication', key: 'communication', icon: <ChatBubbleIcon />, subMenu: [
            //         { path: '/suiviCampagnes', hidden: false, label: t('global:campaignsManagment', 'Suivi des campagnes') }
            //     ]
            // },
            // { isLink: true, key: 'Extraction', label: 'Extraction', pathname: '/extraction', icon: <SaveIcon /> },
            {
                isLink: false, label: 'Administration', key: 'administration', icon: <AdminPanelSettingsIcon />, subMenu: [
                    { path: '/entitiesManager', hidden: false, label: t('invoiceDetail:entityManagment', 'Gestion des entités') },
                    { path: '/emailsContentSettings', hidden: false, label: t('menu:settings', 'Emails') },
                    { path: '/emailsSettings', hidden: false, label: t('menu:emailSendingConfig', 'Configuration envoi mail') },
                    { path: '/instanceManagment', hidden: false, label: t('menu:customerInstance', 'Gestion des instances clients') },
                    { path: '/usersManagment', hidden: false, label: t('menu:usersManagment', 'Gestion des utilisateurs') }

                ]
            },
            { isLink: true, key: 'reporting',label: 'E-Reporting', pathname: '/reporting', icon: <AssessmentIcon /> }

        ]

    ), [t])



    const submenu = () => {
        let kk = menuItems;
        let k = kk.filter(item => item !== false)
        let j = k.map(item => {
            if (item.subMenu !== undefined) {
                return { ...item, subMenu: item.subMenu.filter(item => item !== false) }
            } else {
                return item
            }
        })
        return j


    }



    const isOverflow = () => {
        console.log(location.pathname.split('/')[1])
        if (location.pathname.split('/')[1] == "invoiceCommand") {
            return false;
        }
        else {
            return true;
        }
    }
    return (
        <>
            <AppContainer>
                <Header />
                <AppBody>
                    <Menu items={submenu()} userType={userType} />
                    <AppContent isOverflow={isOverflow()}>
                        <Routes>
                            <Route path="/editPassword" element={<EditPassword />} />
                            <Route path="/referentielFournisseur" element={<ReferentielFournisseur entityFilter={"supplier"} />} />
                            <Route path="/reporting" element={<Reporting entityFilter={"supplier"} />} />
                            <Route path="/entity" element={<Entity newAccountRequest={false} />} />

                            <Route path="/annuairePlateFormePublic" element={<ReferentielPPF />} />
                            <Route path="/logTypeDetail" element={<LogTypeDetail />} />
                            <Route path="/logDetail" element={<LogDetail />} />
                            <Route path="/annuairesppflogs" element={<ConsultationLogs />} />

                            <Route path="/entity/:uid" element={<Entity newAccountRequest={false} />} />
                            <Route path="/echangeDetail/:uid" element={<EchangeDetail />} />
                            <Route path="/userForm/:uid" element={<UserForm />} />
                            <Route path="/userForm" element={<UserForm />} />
                            <Route path="/pdpdashboard" element={<PDPDashboard />} />

                            <Route path="/referentielClient" element={<ReferentielClient entityFilter={"client"} />} />
                            <Route path="/clientInvoices" element={<SuiviInvoices entityFilter={"client"} />} />
                            <Route path="/fournisseurInvoices" element={<SuiviInvoices entityFilter={"supplier"} />} />
                            <Route path="/fournisseurInvoicesIntegrated" element={<SuiviInvoices entityFilter={"supplier"} status={"TO_BE_VALIDATED"} />} />
                            <Route path="/fournisseurInvoicesConciliation" element={<SuiviInvoices entityFilter={"supplier"} status={"TO_BE_CONTROLLED"} />} />
                            <Route path="/fournisseurCommandes" element={<SuiviCommands entityFilter={"supplier"} />} />
                            <Route path="/invoiceCommand/:uid" element={<InvoiceCommand />} />
                            <Route path="/clientCommandesDeadlineExceeded" element={<CommandesDeadlineExceeded entityFilter={"client"} />} />
                            <Route path="/eReportingInvoices/:uid" element={<EreportingInvoices />} />
                            <Route path="/suppliersAccountRequest" element={<SupplierAccountRequest />} />
                            <Route path="/paymentSignature" element={<PaymentSignature />} />
                            <Route path="/supplierRequestDetail/:uid" element={<SupplierRequestDetail />} />
                            <Route path="/paymentSignatureDetail/:uid" element={<PaymentSignatureDetail />} />
                            <Route path="/DocumentationView" element={<DocumentationView />} />
                            <Route path="/documentation" element={<Documentation />} />
                            <Route path="/addDocumentation" element={<NewDocumentation />} />
                            <Route path="/upload" element={<UploadInvoice userType={userType} />} />
                            <Route path="/UploadDoc/:uid" element={<UploadDoc />} />
                            <Route path="/CommandesDeadlineExceededDetail/:uid" element={<CommandesDeadlineExceededDetail />} />
                            <Route path="/commandeDetail/:uid" element={<CommandeDetail />} />
                            <Route path="/invoiceDetail/:uid" element={<InvoiceDetail />} />
                            {/* <Route path="/commandeDetail/:uid" element={<InvoiceDetail />} /> */}
                            <Route path="/CommandesDeadlineExceededDetail/:uid" element={<CommandesDeadlineExceededDetail />} />
                            <Route path="/invoiceIntegratedDetail/:uid" element={<InvoiceIntegratedDetail />} />
                            <Route path="/invoiceDetail/:uid/comments" element={<InvoiceDetail />} />
                            <Route path="/extraction" element={<Extraction />} />
                            <Route path="/extractionForm" element={<ExtractionForm />} />
                            <Route path="/extractionForm/:uid" element={<ExtractionForm />} />
                            <Route path="/suiviCampagnes" element={<Campaigns />} />
                            <Route path="/newCampaign" element={<CampaignForm />} />
                            <Route path="/newCampaign/:uid" element={<CampaignForm />} />
                            <Route path="/entitiesManager" element={<EntitiesManager />} />
                            <Route path="/emailsContentSettings" element={<EmailsSettings />} />
                            <Route path="/emailsSettings" element={<Settings />} />
                            <Route path="/kpiSupplier" element={<HomeSupplier />} />
                            <Route path="/embarquementSupplier" element={<Campaigns />} />
                            <Route path="/kpiClient" element={<ClientHome />} />
                            <Route path="/embarquementClient" element={<Campaigns />} />
                            <Route path="/EntityPPF/:uid" element={<EntityPPF />} />
                            <Route path="/annuaireechangespdpppf" element={<EchangePDP />} />
                            <Route path="/ToDoListe" element={<ToDoListe />} />
                            <Route path="/usersManagment" element={<UserManagment />} />
                            <Route path="/instanceManagment" element={<InstanceManagment />} />
                            {/* <Route path="/addNewUser" element={<AddNewUser />} /> */}


                            <Route path="/" element={<Home />} />
                        </Routes>
                    </AppContent>
                </AppBody>
            </AppContainer>
        </>
    )
    //   return (
    //     <>
    //         <AppContainer>
    //             <Header />
    //             <AppBody>
    //                 <Menu items={menuItems} />
    //                 <AppContent>
    //                     <Routes>
    //                         <Route path="/editPassword" element={<EditPassword />}/>
    //                         <Route path="/referentielFournisseur" element={<ReferentielFournisseur entityFilter={"supplier"} />} />
    //                         <Route path="/reporting" element={<Reporting entityFilter={"supplier"} />} />
    //                         <Route path="/entity" element={<Entity newAccountRequest={false} />} />
    //                         <Route path="/entity/:uid" element={<Entity newAccountRequest={false} />} />
    //                         <Route path="/userForm/:uid" element={<UserForm />} />
    //                         <Route path="/userForm" element={<UserForm />} />
    //                         <Route path="/referentielClient" element={ <ReferentielClient entityFilter={"client"} />}/>
    //                         <Route path="/clientInvoices" element={ <SuiviInvoices entityFilter={"client"} />}/>
    //                         <Route path="/fournisseurInvoices" element={<SuiviInvoices entityFilter={"supplier"} />}/>
    //                         <Route path="/eReportingInvoices/:uid" element={<EreportingInvoices />}/>
    //                         <Route path="/documentation" element={<Documentation/>} />
    //                         <Route path="/addDocumentation" element={<NewDocumentation/>} />
    //                         <Route path="/upload" element={<UploadInvoice />} />
    //                         <Route path="/UploadDoc/:uid" element={<UploadDoc />} />
    //                         <Route path="/invoiceDetail/:uid" element={<InvoiceDetail />} />
    //                         <Route path="/invoiceDetail/:uid/comments" element={<InvoiceDetail />} />
    //                         <Route path="/invoiceCommand/:uid" element={<InvoiceCommand />} />
    //                         <Route path="/extraction" element={<Extraction/>} />
    //                         <Route path="/extractionForm" element={<ExtractionForm/>} />
    //                         <Route path="/extractionForm/:uid" element={<ExtractionForm/>} />
    //                         <Route path="/suiviCampagnes" element={<Campaigns />} />
    //                         <Route path="/newCampaign" element={<CampaignForm />} />
    //                         <Route path="/newCampaign/:uid" element={<CampaignForm />} />
    //                         <Route path="/entitiesManager" element={<EntitiesManager />} />
    //                         <Route path="/emailsContentSettings" element={<EmailsSettings />}/>
    //                         <Route path="/emailsSettings" element={<Settings />}/>
    //                         <Route path="/kpiSupplier" element={<HomeSupplier />} />
    //                         <Route path="/embarquementSupplier" element={<Campaigns />} />
    //                         <Route path="/kpiClient" element={<ClientHome />} />
    //                         <Route path="/embarquementClient" element={<Campaigns />} />
    //                         <Route path="/" element={<Home />} />
    //                     </Routes>
    //                 </AppContent>
    //             </AppBody>
    //         </AppContainer>
    //     </>
    //   )
}

export default OwnerRoot
