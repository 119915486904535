import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import appReducer from './store/reducers/app';
import authReducer from './store/reducers/auth';
import invoiceReducer from './store/reducers/invoice'
import commandeReducer from './store/reducers/commande'
import tasks from './store/reducers/tasks'
import clientsReducer from './store/reducers/clients'
import suppliersReducer from './store/reducers/suppliers'
import documentationReducer from './store/reducers/documentation'
import entitiesReducer from './store/reducers/entities'
import instanceReducer from './store/reducers/instanceManagment'
import entitiesPPFReducer from './store/reducers/entitiesPPF'
import echangePDPReducer from './store/reducers/echangePDP'
import campaignsReducer from './store/reducers/campaigns'
import accountantsReducer from './store/reducers/accountants'
import logsReducer from './store/reducers/logs'
import usersReducer from './store/reducers/users'
import supplierRequest from './store/reducers/supplierRequest'
import paymentSignature from './store/reducers/paymentSignature'
import invoiceDetailReducer from './store/reducers/invoiceDetail'
import supplierRequestDetailReducer from './store/reducers/supplierRequestDetail'
import extractionReducer from './store/reducers/extraction'
import reportingReducer from './store/reducers/reporting'
import invoiceHomeClientsReducer from './store/reducers/invoiceHomeClients.js'
import commandesDeadlineExceededReducer from './store/reducers/commandesDeadlineExceeded.js'
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  invoice: invoiceReducer,
  clients: clientsReducer,
  suppliers: suppliersReducer,
  tasks: tasks,
  accountants: accountantsReducer,
  logs: logsReducer,
  users: usersReducer,
  supplierRequest: supplierRequest,
  paymentSignature: paymentSignature,
  invoiceDetail: invoiceDetailReducer,
  documentation: documentationReducer,
  campaigns: campaignsReducer,
  supplierRequestDetail: supplierRequestDetailReducer,
  entities: entitiesReducer,
  extraction: extractionReducer,
  reporting: reportingReducer,
  invoiceHomeClients: invoiceHomeClientsReducer,
  commande: commandeReducer,
  commandesDeadlineExceeded: commandesDeadlineExceededReducer,
  entitiesPPF: entitiesPPFReducer,
  echangePDP: echangePDPReducer,
  instance: instanceReducer
})
const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
))

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={(<div>loading ...</div>)}>
      <React.StrictMode>
        <BrowserRouter >
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
