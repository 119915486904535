import React, { useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import { DangerNotyf, DocsTabContainer, DocsTable, Notyfs, SuccessNotyf, WarningNotyf } from './Documents.styled';
import { Table, TBody, TD, TH, THead } from '../../../styles/Table.styled';
import Download from '../../Download/Download';
import { formatDate, getNotyfObject } from '../../../shared/utility';
import CloseIcon from '@mui/icons-material/Close';
import { EIFormGroup, EIFormLabel, Input, EIFormGroupChecked, ToggleDiv, InputCheckbox, EntitiesContainer } from '../../../containers/NewDocumentation/NewDocumentation.styled'
import ConfirmationModal from '../../UI/ConfirmationModal/ConfirmationModal';
import Axios from "../../../axios-proas";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import './Documents.css'
import { useTranslation } from 'react-i18next';

function Documents({ documents, Admin, docTypes, type, setDownloadDate, setUpdate }) {
    const notyf = getNotyfObject();
    const { t } = useTranslation();
    const docType = {
        KBIS: t("supplier:Kbis", "KBIS"),
        IBAN: t("supplier:iban", "RIB"),
        URSSAF: t("supplier:urssaf", "Attestation URSSAF"),
        INSURANCE: t("supplier:insurance", "Attestation d'assurance"),
        FOREIGN: t("supplier:foreignWorkers", "Travailleurs étrangers")
    }
    const [document, setDocument] = useState()
    const [selectedOption, setSelectedOption] = useState()
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const inputChangeHandler2 = (e, uid) => {
        let value = e.target.value;
        setSelectedOption(e.target.value)
        switch (value) {
            case "valider":
                let docTemp1 = {
                    admin: { ...Admin[0] },
                    uid: uid,
                    valider: 1,
                    refuser: 0,
                }
                setDocument(docTemp1)
                break;
            case "refuser":
                let docTemp = {
                    admin: { ...Admin[0] },
                    uid: uid,
                    valider: 0,
                    refuser: 1
                }
                setDocument(docTemp)
                break;

            default:
                break;
        }
        setConfirmationModalShow(true)
    }
    const updateStatusDocument = (data) => {

        Axios.put("supplierdocument//updateStatusDocumentFile", data,).then(response => {
            notyf.success(t("global:success", "document modifié"));
            setUpdate(true)
            setConfirmationModalShow(false)
            localStorage.setItem("show", "document");
        }).catch(err => {
            notyf.error("err lors de la modification de statut de fichier");
        })
    }

    console.log(documents)

    return (
        <DocsTabContainer style={{ marginTop: "1.2rem" }}>
            {/* <Notyfs>
            {docTypes?.map(e=>(
                e.attent ?
                <WarningNotyf title="Ce document n'est pas encore validé ">
                    <HourglassBottomIcon style={{fontSize: "1rem"}} />
                    <span>{e.label}</span>
               </WarningNotyf>
            : e.alert ?
                <WarningNotyf title="Ce document expirera bientôt ">
                    <InfoIcon style={{fontSize: "1rem"}} />
                    <span>{e.label}</span>
                </WarningNotyf>
            :!e.valide?
                <DangerNotyf title="Document expiré ou non disponible">
                    <CloseIcon style={{fontSize: "1rem"}} />
                    <span>{e.label}</span>
                </DangerNotyf>
            : null


            ))
        }
        </Notyfs> */}

            <DocsTable>
                <Table>
                    <THead>
                        <tr>
                            <TH scope="col" >Document</TH>
                            <TH scope="col" >Type</TH>
                            <TH scope="col" >{t("supplier:expirationDate", "Date d'expiration")}</TH>
                            <TH scope="col" >{t("supplier:uploadDate", "Date de téléchargement")}</TH>
                            {/* <TH scope="col" >{type=="supplier"?"Statut":"Action"}</TH>  */}
                        </tr>
                    </THead>
                    <TBody>
                        {
                            documents?.length > 0 ? documents.map(d =>
                                <tr key={d.uid}>
                                    <TD><Download className="download" fileName={d.document} url={"/supplierdocument/" + d.uid + "/download"} clicked={() => setDownloadDate(d)} /></TD>
                                    <TD>{docType[d.type]}</TD>
                                    <TD>{d.expirationDate ? formatDate(Number(d.expirationDate)) : ""}</TD>
                                    <TD>{d.creationDate ? formatDate(+d.creationDate) : ""}</TD>

                                </tr>)
                                : null
                        }
                    </TBody>
                </Table>
            </DocsTable>
            <ConfirmationModal
                show={confirmationModalShow}
                modalClosed={() => setConfirmationModalShow(false)}
                confirm={() => updateStatusDocument(document)}
                cancel={() => setConfirmationModalShow(false)}
                title={"Confirmation de désactivation"}
                message={`Êtes-vous sûr de vouloir désactiver cet utilisateur `}
            />
        </DocsTabContainer>
    );
}

export default Documents;