import React, { useCallback } from 'react'
import Axios from '../../../axios-proas';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TD } from '../../../styles/Table.styled';
import { CustomerLink } from '../../../containers/ReferentielClient/ReferentielClient.Styled';
import { getChannelWithKey, getEntityStatusWithKey, showColumn, getAlignPosition } from '../../../shared/utility';

function TableBody(props) {
    const { clientsData, userRole, columns } = props;
    const history = useNavigate();
    const { t } = useTranslation();

    const handleBoxChecked = (event, row) => {
        Axios.put('/clientbusiness/' + row.uid + '/receivesAttachment').then(response => {
            event.target.checked = response.data;
            row.receivesAttachment = event.target.checked;
        }).catch(error => {
            console.error(error);
        })
    }
    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])


    const getTokenVisualisation = (uid) => {
        Axios.get('/clientbusiness/' + uid + '/userTokenVisualisation')
            .then(response => {
                Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                //window.location.reload(false);
                //Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('loginVisualisation', response.data.login)
                history.push("/userVisualisation")
            })
    }

    const getDematType = (client) => {
        var dematExpression = new Array()
        if (client?.edi == 1) dematExpression.push('EDI');
        if (client?.chorus == 1) dematExpression.push('CHORUS');
        if (client?.paper == 1) dematExpression.push('PAPER');
        if (client?.sendMail == 1) dematExpression.push('EMAIL');
        if (client?.accessPortal == 1) dematExpression.push('Accès portail');
        return dematExpression
    }


    return (
        <>
            {
                clientsData ? clientsData.map(row => (
                    <tr key={row.id} >
                        <TD style={{ display: !showColumn(columns, "code") && 'none', textAlign: getAlignPosition(columns, "code") }}>{row.code}</TD>

                        <TD style={{ display: !showColumn(columns, "name") && 'none', textAlign: getAlignPosition(columns, "name") }}>
                            <CustomerLink
                                to={`/entity/${row.uid}`}
                                state={{ entityUid: row.uid, entityType: 'client' }}
                            >
                                {row.name}
                            </CustomerLink>
                        </TD>
                        <TD style={{ display: !showColumn(columns, "zipCode") && 'none', textAlign: getAlignPosition(columns, "zipCode") }}>{row.zipCode}</TD>
                        <TD style={{ display: !showColumn(columns, "city") && 'none', textAlign: getAlignPosition(columns, "city") }}>{row.city}</TD>
                        <TD style={{ display: !showColumn(columns, "channel") && 'none', textAlign: getAlignPosition(columns, "channel") }}>{t("global:" + row?.channel?.toLowerCase(), getChannelWithKey(row?.channel))}</TD>
                        <TD style={{ display: !showColumn(columns, "creationDate") && 'none', textAlign: getAlignPosition(columns, "creationDate") }}>{formatDate(+row.creationDate)}</TD>
                        <TD style={{ display: !showColumn(columns, "status") && 'none', textAlign: getAlignPosition(columns, "status") }}>
                            {getEntityStatusWithKey(row.status)}
                        </TD>
                        {/* <TD style={{display: !showColumn(columns,"visu") && 'none', textAlign: getAlignPosition(columns, "visu")}}>
                            <CustomerLink  
                                to= "#"
                               state= {{clientUid: row.uid}}
                            >
                                    Visualiser
                            </CustomerLink>
                        </TD> */}
                    </tr>
                )) : null
            }
        </>
    )
}

export default TableBody
