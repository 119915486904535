import { updateObject } from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    data: [],
    loading: false,
    error: null,
    count: 0,
    currentPage: 1,
    pageSize: 100,
    filtering: false,
    showFilters: false,
    filtersQuery: null,
    sortQuery: 'name',
    reverse: {
        name: false
    }
}

const instanceStart = (state, action) => updateObject(state, {
    error: null,
    loading: true
})

const instanceSuccess = (state, action) => updateObject(state, {
    data: action.data,
    count: action.count ? action.count : 0,
    error: null,
    loading: false
})

const instanceFail = (state, action) => updateObject(state, {
    data: [],
    error: action.error,
    loading: false,
    count: 0,
    currentPage: 1
})

const instanceSetPage = (state, action) => updateObject(state, {
    currentPage: action.currentPage
})

const instanceSetPageSize = (state, action) => updateObject(state, {
    pageSize: action.pageSize
})

const instanceSetSortQuery = (state, action) => updateObject(state, {
    sortQuery: action.sortQuery
})

const instanceSetReverseSort = (state, action) => {
    return {
        ...state,
        reverse: { ...action.reverse }
    }
}

const instanceUpdateShowFilters = (state, action) => {
    return updateObject(state, { showFilters: action.showFilters })
}

const instanceSetFiltersQuery = (state, action) => updateObject(state, {
    filtersQuery: {
        ...action.filters
    },
    filtering: action.filtering
}
)

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INSTANCE_START: return instanceStart(state, action)
        case actionTypes.INSTANCE_SUCCESS: return instanceSuccess(state, action)
        case actionTypes.INSTANCE_FAIL: return instanceFail(state, action)
        case actionTypes.INSTANCE_SET_PAGE: return instanceSetPage(state, action)
        case actionTypes.INSTANCE_SET_PAGESIZE: return instanceSetPageSize(state, action)
        case actionTypes.INSTANCE_SET_FILTERSQUERY: return instanceSetFiltersQuery(state, action)
        case actionTypes.INSTANCE_SET_SORTQUERY: return instanceSetSortQuery(state, action)
        case actionTypes.INSTANCE_SET_REVERSESORT: return instanceSetReverseSort(state, action)
        case actionTypes.INSTANCE_UPDATE_SHOWFILTERS: return instanceUpdateShowFilters(state, action)
        default:
            return state
    }
}
export default reducer