
import "./AccountRequest.css";
import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { FormECILabel, FormECItem, FormECIValue, FormECLabel, FormECListContainer, FormEntCanal, SendButton, FormLabel, FormGroup, FormInput, CancelBtnOutlineAction, PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, FormECListContainerAccountRequest } from "../../styles/Common";
import { Link } from 'react-router-dom';
import logo from '../../assets/images/LogoRotiflex.png'
import { useTranslation } from 'react-i18next';
import LanguageList from '../LanguageList/LanguageList';
import EntitiesDropDown from "./EntitiesDropDown/EntitiesDropDown";
import CloseIcon from '@mui/icons-material/Close';
import Axios from '../../axios-proas';
import { useLocation, useNavigate } from 'react-router-dom';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import InformationModal from '../UI/ConfirmationModal/InformationModal';
import DropZone from '../UI/DropZone/DropZone'
import PopoverComp from '../UI/PopoverComp/PopoverComp'
import { useTheme } from 'styled-components';
import { Notyf } from "notyf";
import { convertFileToBase64 } from "../../shared/utility"
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Spinner } from 'react-bootstrap';

function AccountRequest() {
    const { t } = useTranslation();
    const notyf = new Notyf();
    const [showDuplicatedDocumentTypeError, setShowDuplicatedDocumentTypeError] = useState(false);
    const [showDuplicatedDocumentTypeErrorOnSubmit, setShowDuplicatedDocumentTypeErrorOnSubmit] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const supplierCanalList = useMemo(() => ([
        { value: 'PORTAL', label: t("global:portal", "Portail") },
        { value: 'EMAIL', label: t("signin:canalEmail", "Email") },
        { value: 'PAPER', label: t("global:paper", "Papier") },
        { value: 'EDI', label: t("signin:edi", "EDI") },
        { value: 'PDP', label: t("signin:pdp", "PDP") },
        { value: 'PPF', label: t("signin:pdp", "PPF") }
    ]), [t]);
    const [entity, setEntity] = useState({
        entityAdmin: "",
        name: "",
        siret: "",
        siren: "",
        vat: "",
        zipCode: "",
        city: "",
        country: "",
        address: "",
        countryCode: "fr",
        family: "ADMINISTRATION",
        lang: "fr",
        attachments: [],
        owners: []
    });
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const theme = useTheme();
    const navigate = useNavigate();
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [formErrors, setFormErrors] = useState([]);

    const documentsInformation =
        <>
            <span style={{ color: "#809FB8" }}>{t("supplier:knownDocument", "Liste des documents connus :")}</span>
            <documentsList >
                <li>{t("supplier:kbis", "Justificatif d’Immatriculation KBIS")}</li>
                <li>{t("supplier:urssafSign", "Attestation sociale de vigilance URSSAF")}</li>
                <li>{t("supplier:insurance", "Attestation d'assurance")}</li>
                <li>{t("supplier:iban", "RIB")}</li>
                <li>{t("supplier:foreignEmployeesList", "Liste des salariés étrangers soumis à autorisation de travail")}</li>
            </documentsList>
        </>


    useEffect(() => {
        setShowDuplicatedDocumentTypeError(false)
        return () => {
            setEntity({
                name: "",
                siret: "",
                siren: "",
                vat: "",
                zipCode: "",
                city: "",
                country: "",
                entityAdmin: "",
                address: "",
                countryCode: "fr",
                family: "ADMINISTRATION",
                lang: "fr",
                attachments: [],
                owners: []
            })
        }
    }, []);

    useEffect(() => {
    }, [entity]);


    const handleInputChange = (e) => {
        const key = e.target.name;
        var value = e.target.value;
        if (key == "actif") {
            e.target.checked ? value = 1 : value = 0;
        }
        const newSupplier = { ...entity, [key]: value };
        setEntity(newSupplier);
    }

    const canalChangeHandler = (value) => {
        let entityTmp = {
            ...entity,
            channel: value
        };
        setEntity(entityTmp);
    }

    const supplierCanalChangeHandler = (field, value) => {
        let entityTmp = { ...entity };
        supplierCanalList.forEach(canal => {
            entityTmp[canal.field] = canal.field === field ? value : 0;
        });
        setEntity(entityTmp);
    }

    const ownersChangeHandler = (ownersList) => {
        let entityTemp = {
            ...entity,
            owners: ownersList
        }
        setEntity(entityTemp)
    }
    const retour = () => {
        setConfirmationModalShow(false)
        navigate("/");
    }

    const checkFormRequest = () => {
        const entityForm = entity
        let errors = []

        if ((entityForm?.attachments?.find(attachment => attachment.docType == ""))) errors.push("Chaque document doit avoir un type approprié")
        if (entityForm?.attachments?.filter(attachment => attachment.docType == "IBAN").length > 1 ||
            entityForm?.attachments?.filter(attachment => attachment.docType == "URSSAF").length > 1 ||
            entityForm?.attachments?.filter(attachment => attachment.docType == "INSURANCE").length > 1 ||
            entityForm?.attachments?.filter(attachment => attachment.docType == "FOREIGN").length > 1)
            errors.push("Type document en double")
        if (entityForm?.name?.length == 0) errors.push("Entité name")
        if (entityForm?.siret?.length == 0) errors.push("Entité siret")
        if (entityForm?.siren?.length == 0) errors.push("Entité siren")
        if (entityForm?.vat?.length == 0) errors.push("Entité tva")
        if (entityForm?.address?.length == 0) errors.push("Entité adresse")
        if (entityForm?.zipCode?.length == 0) errors.push("Entité code postal")
        if (entityForm?.city?.length == 0) errors.push("Entité ville")
        if (entityForm?.entityAdmin?.length == 0) errors.push("Entité contact administrateur")
        else {
            let email = entity?.entityAdmin?.split('@')
            if (email[1]) {
                let tempEmail = email[1]?.split('.')
                if (tempEmail[1]) null
                else errors.push("Email contact error")
            } else
                errors.push("Email contact error")
        }

        if (entityForm?.telephone_number?.length == 0) errors.push("Téléphone contact")

        // setFormErrors(errors)
        if (errors.length > 0) return false
        else return true
    }

    const requestSupplierclickHandler = () => {
        if (checkFormRequest()) {
            let entityAttachments = entity.attachments

            if (entityAttachments?.find(attachment => attachment.docType == "")) {
                setShowDuplicatedDocumentTypeErrorOnSubmit(true)
            } else {
                const entityToAdd = {
                    ...entity,
                    type: "supplier",
                    owners: selectedEntities,
                    i18nextLng: localStorage.getItem("i18nextLng")
                }
                setShowSpinner(true)
                Axios.post('entity//request', entityToAdd).then(response => {
                    setShowSpinner(false)
                    if (response.data.hasOwnProperty('success')) {
                        notyf.success(t("global:success", "La création de l'entité a bien été demandée"))
                        // navigate("/");
                    } else if (response.data.hasOwnProperty('error')) {
                        notyf.error("Adresse admin contact exist déjà")
                    }

                }).catch(response => {
                    setShowSpinner(false)
                    notyf.error(t("global:success", "Une erreur s'est produite"))
                })
            }
        } else
            notyf.error("Tous les champs sont obligatoires, sauf complément")
    }

    const deleteOwnerclickHandler = (owner) => {

        let entityTemp = { ...entity }
        if (entity?.id && !owner?.new) {
            entityTemp = {
                ...entityTemp,
                ownersToDelete: entityTemp?.ownersToDelete ? [...entityTemp?.ownersToDelete, owner.uid] : [owner.uid]
            }
        }
        let entityOwners = [...entity?.owners]
        let ownerIndex = entityOwners.findIndex(ent => ent.uid === owner.uid);
        if (ownerIndex >= 0) {
            entityOwners.splice(ownerIndex, 1)
        }
        setEntity({
            ...entityTemp,
            owners: entityOwners
        })
    }

    const disableCanal = (e) => {
        setEntity({ ...entity, [e]: false });
    }

    const enableCanal = (e) => {
        setEntity({ ...entity, [e]: true });
    }

    const onDrop = (acceptedFiles) => {
        let attachmentsTmp = entity?.attachments
        // let fileB64 = "";
        acceptedFiles.map(file => {
            if (file) {
                let existFile = entity?.attachments.find(row => row.name === file.path)
                if (existFile) {
                    notyf.error("Un fichier avec le même nom a déjà été ajouté")
                } else {
                    // if (file.size > 5242880)
                    convertFileToBase64(file).then(result => {
                        let fileB64 = result;
                        if (fileB64 != "" && fileB64 != "data:") {
                            attachmentsTmp.push({
                                name: file.name,
                                type: file.type,
                                content: fileB64,
                                docType: ""
                            })
                            setEntity({
                                ...entity,
                                attachments: attachmentsTmp
                            })
                        } else
                            notyf.error(t("Document endommagé"))
                    }).catch(err => {
                        notyf.error(t("mandat:generatedErrorMessage", "Une erreur s'est produite"))
                    })
                }
            }

        })
    };

    const checkDocumentsType = (e, offset) => {
        setShowDuplicatedDocumentTypeErrorOnSubmit(false)
        let tempEntityAttachments = entity.attachments
        let index = entity?.attachments.find(attachment => attachment.docType == e.target.value)
        if (index && e.target.value != "") {
            setShowDuplicatedDocumentTypeError(true)
        } else {
            setShowDuplicatedDocumentTypeError(false)
            tempEntityAttachments[offset].docType = e.target.value
            setEntity({
                ...entity,
                attachments: tempEntityAttachments
            })
        }

    }

    const deleteAttachment = (index) => {
        setShowDuplicatedDocumentTypeErrorOnSubmit(false)
        setShowDuplicatedDocumentTypeError(false)
        let tempAttachments = entity?.attachments
        tempAttachments.splice(index, 1)
        setEntity({ ...entity, attachments: tempAttachments })
    }

    return (
        <div className="accountRequest__container">
            <div className="accountRequest__FromWrap">
                <div className="accountRequest__fromContent">
                    {/* <div className="signin_language_container">
                    <LanguageList
                    // iconColor="#1e98d7"
                    iconColor="#6C5CE7"
                    />
                </div> */}
                    <div className="accountRequest__header">
                        <Link to="/" className="accountRequest__Icon">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>
                    <FormLabel style={{ justifyContent: "end", fontSize: "11px" }}>{t("global:mandatoryFields", "Tous les champs sont obligatoires")}</FormLabel>
                    <div className="accountRequest__form">
                        <FormGroup className="accountRequest__formGroup">
                            <FormLabel htmlFor="docs" >{t("global:name", "Nom")}</FormLabel>
                            <FormInput
                                style={{ padding: "0px 0px 0px 9px" }}
                                className="accountRequest__formInput"
                                type="text"
                                name="name"
                                value={entity?.name || ""}
                                // placeholder={t("signin:name", "Nom")}
                                onChange={handleInputChange}

                            />
                        </FormGroup>
                        <FormGroup className="accountRequest__formGroup">
                            <FormLabel htmlFor="docs" >{t("global:secondIdentifier", "Identifiant 1:N° Siren")}</FormLabel>
                            <FormInput
                                style={{ padding: "0px 0px 0px 9px" }}
                                className="accountRequest__formInput"
                                type="text"
                                value={entity?.siren || ""}
                                name="siren"
                                // placeholder={t("signin:login", "Identifiant  1:N° Siren")}
                                onChange={handleInputChange}

                            />
                        </FormGroup>
                        <FormGroup className="accountRequest__formGroup">
                            <FormLabel htmlFor="docs" >{t("global:secondIdentifier", "Identifiant 2:N° Siret")}</FormLabel>
                            <FormInput
                                style={{ padding: "0px 0px 0px 9px" }}
                                className="accountRequest__formInput"
                                type="text"
                                value={entity?.siret || ""}
                                name="siret"
                                // placeholder={t("signin:login", "Identifiant 2:N° Siret")}
                                onChange={handleInputChange}

                            />
                        </FormGroup>
                        <FormGroup className="accountRequest__formGroup">
                            <FormLabel htmlFor="docs" >{t("supplier:vatNumber", "N° TVA")}</FormLabel>
                            <FormInput
                                style={{ padding: "0px 0px 0px 9px" }}
                                className="accountRequest__formInput"
                                type="text"
                                value={entity?.vat || ""}
                                name="vat"
                                // placeholder={t("signin:login", "N° TVA")}
                                onChange={handleInputChange}

                            />
                        </FormGroup>
                        <FormGroup className="accountRequest__formGroup">
                            <FormLabel htmlFor="docs" >{t("supplier:address", "Adresse")}</FormLabel>
                            <FormInput
                                style={{ padding: "0px 0px 0px 9px" }}
                                className="accountRequest__formInput"
                                type="text"
                                name="address"
                                value={entity?.address || ""}
                                // placeholder={t("signin:login", "Adresse")}
                                onChange={handleInputChange}

                            />
                        </FormGroup>
                        <FormGroup className="accountRequest__formGroup">
                            <FormLabel htmlFor="docs" >{t("supplier:additionalAddress", "Complément d'adresse")}</FormLabel>
                            <FormInput
                                style={{ padding: "0px 0px 0px 9px" }}
                                className="accountRequest__formInput"
                                type="text"
                                value={entity?.addressComplement || ""}
                                name="addressComplement"
                                // placeholder={t("signin:addressComplement", "Complément d'adresse")}
                                onChange={handleInputChange}

                            />
                        </FormGroup>
                        <FormGroup className="accountRequest__formGroup">
                            <FormLabel htmlFor="docs" >{t("supplier:zipCode", "Code postal")}</FormLabel>
                            <FormInput
                                style={{ padding: "0px 0px 0px 9px" }}
                                className="accountRequest__formInput"
                                type="text"
                                name="zipCode"
                                value={entity?.zipCode || ""}
                                // placeholder={t("signin:zipCode", "Code postal")}
                                onChange={handleInputChange}

                            />
                        </FormGroup>
                        <FormGroup className="accountRequest__formGroup">
                            <FormLabel htmlFor="docs" >{t("supplier:city", "Ville")}</FormLabel>
                            <FormInput
                                style={{ padding: "0px 0px 0px 9px" }}
                                className="accountRequest__formInput"
                                type="text"
                                name="city"
                                value={entity?.city || ""}
                                // placeholder={t("signin:city", "Ville")}
                                onChange={handleInputChange}

                            />
                        </FormGroup>
                        <FormGroup className="accountRequest__formGroup" style={{ justifyContent: 'start' }}>
                            <FormLabel htmlFor="docs" >{t("supplier:country", "Pays")}</FormLabel>
                            <select
                                id="countryCode"
                                className="accountRequest__formInput"
                                value={entity?.countryCode || ""}
                                name="countryCode"
                                onChange={handleInputChange}
                            >
                                <option value="fr">FRANCE</option>
                            </select>
                        </FormGroup>
                        <FormGroup className="accountRequest__formGroup">
                            <FormLabel htmlFor="docs" >{t("supplier:adminContact", "Contact administrateur")}</FormLabel>
                            <FormInput
                                style={{ padding: "0px 0px 0px 9px" }}
                                className="accountRequest__formInput"
                                type="email"
                                name="entityAdmin"
                                value={entity?.entityAdmin || ""}
                                // placeholder={t("signin:adminContact", "Contact administrateur")}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup className="accountRequest__formGroup">
                            <FormLabel htmlFor="docs" >{t("user:phone", "Téléphone")}</FormLabel>
                            <FormInput
                                style={{ padding: "0px 0px 0px 9px" }}
                                className="accountRequest__formInput"
                                type="email"
                                name="telephone_number"
                                value={entity?.telephone_number || ""}
                                // placeholder={t("signin:adminContact", "Contact administrateur")}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormEntCanal>
                            <FormECLabel >{t("supplier:exchangeChannels", "Canal d'échange")}</FormECLabel>
                            <FormECListContainerAccountRequest>
                                {
                                    supplierCanalList.map((canal, index) => (
                                        <FormECItem>
                                            <FormECILabel>{canal?.label} </FormECILabel>
                                            <FormECIValue isActive={entity?.channel === canal.value}>
                                                {
                                                    entity?.channel === canal.value ? (
                                                        <ToggleOnIcon
                                                            style={{ marginLeft: '0.25rem', color: theme.colors.success, cursor: 'pointer' }}
                                                            fontSize="small"
                                                            onClick={() => canalChangeHandler(null)}
                                                        />
                                                    ) :
                                                        <ToggleOffIcon
                                                            style={{ marginLeft: '0.25rem', cursor: 'pointer' }}
                                                            fontSize="small"
                                                            onClick={() => canalChangeHandler(canal?.value)}
                                                        />

                                                }
                                            </FormECIValue>
                                        </FormECItem>
                                    ))
                                }
                            </FormECListContainerAccountRequest>
                        </FormEntCanal>

                        <FormGroup>
                            <FormLabel style={{ whiteSpace: 'initial' }}>{t("supplier:leGalDocumentDeposit", "Dépôt de documents légaux (KBIS, Attestation URSAF, ...)")}
                                <PopoverComp message={documentsInformation} />
                            </FormLabel>
                            <DropZone checkDocumentsType={checkDocumentsType} deleteAttachment={deleteAttachment} onDrop={onDrop} accept={"pdf/*"} entity={entity} />
                            {
                                showDuplicatedDocumentTypeError &&
                                <p style={{ color: "#EE5A5A", width: "170px", margin: "auto" }}>{t("supplier:alreadyAssigned", "Le type choisi et déjà attribué")}</p>
                            }
                            {
                                showDuplicatedDocumentTypeErrorOnSubmit &&
                                <p style={{ color: "#EE5A5A", width: "265px", margin: "auto" }}>{t("supplier:requiredDocumentType", "Chaque document doit avoir un type approprié")}</p>
                            }
                        </FormGroup>
                        <div>
                            <EntitiesDropDown deleteOwnerclickHandler={deleteOwnerclickHandler} selectedEntities={selectedEntities} setSelectedEntities={setSelectedEntities} addEntity={ownersChangeHandler} />
                        </div>

                        <div className="accountRequest__formButtons">
                            <PrimaryBtnOutlineAction
                                onClick={() => requestSupplierclickHandler()}
                            // disabled={ !checkFormRequest()}
                            // style={{background : !checkFormRequest() ? "#dddddd": ""}}
                            >
                                {
                                    showSpinner &&
                                    <Spinner animation="border" variant="primary" />
                                }
                                {t("supplier:sendRequest", "Envoyer la demande")}
                            </PrimaryBtnOutlineAction>
                            <CancelBtnOutlineAction onClick={() => retour()}>
                                {t("global:cancel", "Annuler")}
                            </CancelBtnOutlineAction>
                        </div>

                        <div className="accountRequest__formButtons">
                            {
                                formErrors?.map(error => {
                                    <p>{error}</p>
                                })
                            }
                        </div>

                    </div>
                    <div className="accountRequest__footer">
                        {t("default:copyright", `© ${new Date().getFullYear()} ICD International`)}
                    </div>
                </div>
            </div>

            <InformationModal
                show={confirmationModalShow}
                modalClosed={() => retour(false)}
                confirm={() => retour(document)}
                cancel={() => retour(false)}
                title={"Confirmation de création"}
                message={`Vous recevrez un message électronique vous invitant à réinitialiser votre mot de passe.`}
            />
        </div>
    )
}

export default AccountRequest;
