import React, { useCallback } from 'react'
import Axios from '../../../axios-proas';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TD } from '../../../styles/Table.styled';
import { CustomerLink } from '../../../containers/ReferentielClient/ReferentielClient.Styled';
import { getChannelWithKey, getStatusWithKey, showColumn, getAlignPosition } from '../../../shared/utility';


function TableBody(props) {
    const { clientsData, tasks, userRole, columns, userType } = props;
    const history = useNavigate();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleBoxChecked = (event, row) => {
        Axios.put('/clientbusiness/' + row.uid + '/receivesAttachment').then(response => {
            event.target.checked = response.data;
            row.receivesAttachment = event.target.checked;
        }).catch(error => {
            console.error(error);
        })
    }
    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])

    const getProcessType = (event) => {
        switch (event?.processType) {
            case 'GFF': return t("processTypeOptions:supplierInvoiceManagment", 'Gestion des factures fournisseurs'); break;
            case 'NSA': return t("processTypeOptions:supplierReferencing", "Référencement fournisseurs"); break;
            case 'PS': return t("processTypeOptions:parapheurPaymentManagment", "Gestion de parapheur de paiement"); break;
            case 'RECOVERY': return t("processTypeOptions:recoveryManagment", "Gestion recouvrement"); break;
            default: return ""; break;
        }
    }

    const getInvoiceEventMessage = (event) => {
        switch (event?.snapshot) {
            case "NEW":
                return event?.message ? t("invoiceEvent:invoice", "Facture : ") + `${event?.data?.number} (` + getInvoiceEventRealTitle(event) + `)` : t("invoiceEvent:invoice", "Facture : ") + + event?.data?.number;
                break;
            case "TO_BE_VALIDATED":
                return true ? t("invoiceEvent:invoice", "Facture : ") + `${event?.data?.number} (` + getInvoiceEventRealTitle(event) + `)` : t("invoiceEvent:invoice", "Facture : ") + + event?.data?.number;
                break;
            case "INTEGRATED":
                return true ? t("invoiceEvent:invoice", "Facture : ") + `${event?.data?.number} (` + getInvoiceEventRealTitle(event) + `)` : t("invoiceEvent:invoice", "Facture : ") + + event?.data?.number;
                break;
            case "ERROR":
                return true ? t("invoiceEvent:invoice", "Facture : ") + `${event?.data?.number} (` + getInvoiceEventRealTitle(event) + `)` : t("invoiceEvent:invoice", "Facture : ") + + event?.data?.number;
                break;
            case "TO_BE_CONTROLLED":
                return true ? t("invoiceEvent:invoice", "Facture : ") + `${event?.data?.number} (` + getInvoiceEventRealTitle(event) + `)` : t("invoiceEvent:invoice", "Facture : ") + + event?.data?.number;
                break;
            case "LITIGATION":
                return t("invoiceEvent:invoice", "Facture : ") + `${event?.data?.number} (` + getInvoiceEventRealTitle(event) + `)`;
                break;

            case "REFUSED":
            case "DEADLINEEXCEEDED":
            case "RELANCE":
            case "REJECTED":
                return true ? t("invoiceEvent:invoice", "Facture : ") + `${event?.data?.number} (` + getInvoiceEventRealTitle(event) + `)` : t("invoiceEvent:invoice", "Facture : ") + + event?.data?.number;
                break;
            default:
                // return ( event?.snapshot)  ? event?.snapshot : "MessageVide";
                return event?.type == 'USER_CHAT_EVENT'
                    ? t("invoiceEvent:invoice", "Facture : ") + `${event?.data?.number} (` + getInvoiceEventRealTitle(event) + `)` : "";
        }
    }
    const getInvoiceEventRealTitle = (eventTmp) => {
        if (userType === 'client')
            return eventTmp?.data?.clientName ? eventTmp.data.clientName : eventTmp?.data?.supplierName;
        return eventTmp?.data?.supplierName ? eventTmp.data.supplierName : eventTmp?.user?.entityName;
    }

    const getMessage = (event) => {
        switch (event?.eventType) {
            case "invoice":
            case "relance":
                return getInvoiceEventMessage(event);
                break;
            case "entity":
                return t("invoiceEvent:newSupplierAccount", `Référencement`) + ` REF${event?.id} (${event?.name})`;
                break;
            case "parapheurs":
                return `Parapheur ${event?.paymentNumber}`;
            default: return ""
                break;
        }
    }


    const getTokenVisualisation = (uid) => {
        Axios.get('/clientbusiness/' + uid + '/userTokenVisualisation')
            .then(response => {
                Axios.defaults.headers.authorization = 'Bearer ' + response?.data?.token;
                //window.location.reload(false);
                //Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('loginVisualisation', response.data.login)
                history.push("/userVisualisation")
            })
    }

    const getDematType = (client) => {
        var dematExpression = new Array()
        if (client?.edi == 1) dematExpression.push('EDI');
        if (client?.chorus == 1) dematExpression.push('CHORUS');
        if (client?.paper == 1) dematExpression.push('PAPER');
        if (client?.sendMail == 1) dematExpression.push('EMAIL');
        if (client?.accessPortal == 1) dematExpression.push('Accès portail');
        return dematExpression
    }


    const actionTo = (eventTmp) => {
        if (eventTmp?.eventType === "entity") {
            navigate("/supplierRequestDetail/" + eventTmp?.uid)
        }
        if (eventTmp?.eventType === "invoice") {
            if (eventTmp?.snapshot === "TO_BE_CONTROLLED") {
                eventTmp?.data ? navigate("/invoiceCommand/" + eventTmp?.data?.invoiceUid) : null
            } else {
                if (eventTmp?.snapshot === "DEADLINEEXCEEDED") {
                    eventTmp?.data ? navigate("/CommandesDeadlineExceededDetail/" + eventTmp?.data?.invoiceUid) : null
                } else if (eventTmp?.snapshot === "TO_BE_VALIDATED") {
                    eventTmp?.data ? navigate("/invoiceIntegratedDetail/" + eventTmp?.data?.invoiceUid) : null
                } else {
                    eventTmp.hasOwnProperty("data") && eventTmp?.data ? navigate("/invoiceDetail/" + eventTmp?.data?.invoiceUid) : null
                }
            }

        }
        if (eventTmp?.eventType === "parapheurs") {
            eventTmp?.uidParapheur ? navigate("/paymentSignatureDetail/" + eventTmp?.uidParapheur) : null
        }
    }



    return (
        <>
            {
                tasks ? tasks.map(row => (
                    <tr key={row.id} >
                        <TD style={{ color: '#8094ad', cursor: 'pointer', display: !showColumn(columns, "object") && 'none', textAlign: getAlignPosition(columns, "object") }} onClick={() => actionTo(row)}>
                            {getMessage(row)}
                        </TD>
                        <TD style={{ display: !showColumn(columns, "processType") && 'none', textAlign: getAlignPosition(columns, "processType") }}>
                            {getProcessType(row)}
                        </TD>
                        <TD style={{ display: !showColumn(columns, "status") && 'none', textAlign: getAlignPosition(columns, "status") }}>

                            {
                                t("invoiceStatus:" + ("NEW_SUPPLIER_ACCOUNT,PAYMENT_SIGNATURE".includes(row?.type) ? row.type : row?.data?.status), ("NEW_SUPPLIER_ACCOUNT,PAYMENT_SIGNATURE".includes(row?.type) ? row.type : row?.data?.status))
                            }
                        </TD>
                        <TD style={{ display: !showColumn(columns, "creationDate") && 'none', textAlign: getAlignPosition(columns, "creationDate") }}>{formatDate(+row.creationDate)}</TD>

                    </tr>
                )) : null
            }
        </>
    )
}

export default TableBody
