import React, { useCallback, useEffect, useState } from 'react';
import Axios from '../../axios-proas';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import * as actions from '../../store/actions/index';
import TableBody from '../../components/CommandesDeadlineExceeded/TableBody/TableBody';
import TableActions from '../../components/CommandesDeadlineExceeded/TableActions/TableActions';
import TableHead from '../../components/CommandesDeadlineExceeded/TableHead/TableHead';
import TableFilters from '../../components/CommandesDeadlineExceeded/TableFilters/TableFilters';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import AttachmentModal from '../../components/CommandesDeadlineExceeded/TableBody/AttachmentModal/AttachmentModal';
import { useTranslation } from 'react-i18next';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SearchIcon from '@mui/icons-material/Search';
import DateRange from '../../components/InvoiceTable/TableFilters/DateRange/DateRange';
import { getNotyfObject } from '../../shared/utility';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import { NoContent, SearchInput, SearchInputContainer, SpinnerContainer, Table, TableAction, TBody } from '../../styles/Table.styled';
import { SIBody, SIContainer, SITableContainer } from './CommandesDeadlineExceeded.styled';
import SelectPeriod from '../../components/UI/SelectPeriod/SelectPeriod';


function CommandesDeadlineExceeded(props) {
    let {
        entityFilter,
        userLevel,
        userType,
        token,
        loading,
        invoicesData,
        count,
        currentPage,
        pageSize,
        setInvoicePage,
        setInvoicePageSize,
        getInvoicesPage,
        setInvoiceFilters,
        filtersQuery,
        quickFilter,
        setInvoiceQuickFilter,
        filtering,
        sortQuery,
        reverse,
        setSortQuery,
        setReverse,
        updateShowFilters,
        showFilters,
        selectedRows,
        setSelectedRows,
        getCompressedDoc,
        selectedAllRows,
        setSelectedAllRows,
        amountBTTotal,
        amountATTotal,
        amountBTValues,
        amountATValues,
        status
    } = props;
    const [attachmentModalShow, setAttachmentModalShow] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [showQuickDateInterval, setShowQuickDateInterval] = useState(false)
    const [newMessages, setNewMessages] = useState(0);
    const [messagesToggleOn, setMessagesToggleOn] = useState(false);
    const [density, setDensity] = useState("2.25rem")
    const [columns, setColumns] = useState([])
    let notyf = getNotyfObject();

    const { t } = useTranslation();

    const initialFiltersQuerry = {
        visualisation: false,
        entityFilter,
        commandesDeadlineExceeded: true,
        status
    }

    useEffect(() => {
        const columnsCopy = [];
        columnsCopy.push({ title: t("client:customer", "Client"), field: 'clientName', width: "210px", show: true, alignSelf: "center" });

        // columnsCopy.push({title: t("logs:type"), field: 'type', width: "100px" , show:true, alignSelf:"left"});
        columnsCopy.push({ title: t("invoiceEvent:invoiceNumber", "N° Facture"), field: 'number', width: "210px", show: true, alignSelf: "center" });


        columnsCopy.push({ title: t("invoiceList:dueDate", "Date d'échéance"), field: 'issuingDate', width: "210px", show: true, alignSelf: "center" });
        // columnsCopy.push({title: t("invoiceList:dueDate"), field: 'dueDate', width: "140px", show:true, alignSelf:"center"});

        //  columnsCopy.push({title: t("invoiceList:issuingSupplier"), field: 'supplierName', width: "200px", show:true, alignSelf:"left"});

        columnsCopy.push({ title: t("invoiceDetail:deadlineExceeded", "Dépassement"), field: 'dueDate', width: "200px", show: true, alignSelf: "center" });
        columnsCopy.push({ title: t("campaignList:toRelaunch", "Relance"), field: 'numberReminds', width: "200px", show: true, alignSelf: "center" });


        setColumns(columnsCopy);
    }, [entityFilter])



    const dateFilterOptions = [
        { label: "-- " + t("dashboard:period", 'Période') + " --", value: "" },
        { label: t("homeFilter:thisYear", 'Cette année'), value: 'thisYear' },
        { label: t("homeFilter:thisMonth", 'Ce mois-ci'), value: 'thisMonth' },
        { label: t("homeFilter:lastMonth", 'Mois précédent'), value: 'lastMonth' }
    ];
    const convertDate = (date) => {
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]), formatDate(+dateArray[1])]
    }

    const getNewMessages = () => {
        Axios.get('/invoice//checkNewMessages', {
            params: {
                entityFilter: entityFilter
            }
        }).then(response => {
            setNewMessages(response.data.length);
        }).catch(err => {
        })
    }

    useEffect(() => {
        let newFilters = {
            ...initialFiltersQuerry
        }
        updateShowFilters(false)
        setInvoiceFilters(newFilters)
        getInvoicesPage(currentPage, newFilters, "clientName", { clientName: false }, pageSize);
        getNewMessages();
    }, [getInvoicesPage, entityFilter, pageSize, status])

    const isEmptyFilters = () => {
        if (filtersQuery === null)
            return true;
        let filtersList = columns.map(column => column.field)
        filtersList.push("documentType");
        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (!showFilters && !isEmpty) {
            handleReset()
        }
    }, [showFilters]);

    const getQuickDateInterval = () => {
        if (filtersQuery?.quickCreationDate) {
            return `Du : ${convertDate(filtersQuery.quickCreationDate)[0]} À : ${convertDate(filtersQuery.quickCreationDate)[1]} `
        } else
            return '';
    }


    const handleShowFilters = () => {
        updateShowFilters(!showFilters)
    }

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])

    const handleChangePage = useCallback((newPage) => {
        //e.preventDefault();
        setInvoicePage(newPage);
        getInvoicesPage(newPage, filtersQuery, sortQuery, reverse, pageSize);
    }, [filtersQuery, reverse, sortQuery, pageSize]);

    const handleSort = useCallback((sort, newReverse) => {
        getInvoicesPage(currentPage, filtersQuery, sort, newReverse, pageSize)
    }, [currentPage, filtersQuery, sortQuery, reverse, pageSize])

    const handleApplyFiltering = useCallback((filters) => {
        getInvoicesPage(1, filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);

    const handleUpdateViewed = useCallback((e, id) => {
        if (userType && userType === "client") {
            Axios.put("/invoice/" + id + "/firstAccessDate", {
                firstAccessDate: new Date().getTime()
            }).then(response => getInvoicesPage(currentPage, filtersQuery, sortQuery, reverse, pageSize))
                .catch(err => console.error(err))
        }
    }, [pageSize])

    const messagesToggleClickHandler = () => {
        let newValue = !messagesToggleOn;
        setMessagesToggleOn(newValue);
        let filtersTemp = {
            ...initialFiltersQuerry,
            unreadMessages: (newValue ? 'on' : 'off')
        }
        setInvoiceFilters(filtersTemp)
        handleApplyFiltering(filtersTemp)
    }

    const editStatus = (invoiceUid, status, reason = null) => {
        Axios.put('/invoice//updateStatus',
            {
                "InvoiceId": invoiceUid,
                "status": status,
                reason
            }).then(response => {
                getInvoicesPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
                notyf.success(t("global:success", "Statut modifié"))
            }).catch(err => {
                notyf.error(t('global:error', "Une erreur s'est produite"))
            })
    }

    const handleReset = useCallback(() => {
        let newFilters = {
            ...initialFiltersQuerry
        }
        setInvoiceQuickFilter(null)
        setSortQuery("clientName")
        setReverse({ clientName: false })
        setInvoiceFilters(newFilters);
        setInvoicePage(1);
        getInvoicesPage(1, newFilters, "clientName", { clientName: false }, pageSize);
    }, [])

    const attachmentHandler = (e, invoice) => {
        e.preventDefault();
        setSelectedInvoice(invoice);
        setAttachmentModalShow(true);
    }

    const filtersChangeHandler = (newFilters) => {
        const filtersTemp = {
            ...newFilters,
            quickCreationDate: null,
            quickSearch: null
        }
        setInvoiceQuickFilter(null)
        setInvoiceFilters(filtersTemp)
        handleApplyFiltering(filtersTemp)
    }

    const getQuickFilterLabel = () => {
        return dateFilterOptions?.find(val => val.value === quickFilter)?.label
            || dateFilterOptions[0].label
    }

    const quickFilterChangeHandler = (value) => {
        switch (value) {
            case 'thisYear':
                thisYearClickHandler()
                break;
            case 'thisMonth':
                thisMonthClickHandler()
                break;
            case 'lastMonth':
                lastMonthClickHandler()
                break;
            default:
                resetQuickFilter()
                break;
        }
    }

    const resetQuickFilter = () => {
        setInvoiceQuickFilter(null)
        setInvoiceFilters(initialFiltersQuerry)
        handleApplyFiltering(initialFiltersQuerry)
    }

    const thisMonthClickHandler = () => {
        const currentDate = new Date();
        const monthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getTime();
        const monthLastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1).getTime() - 1;
        let filtersTemp = {
            ...initialFiltersQuerry,
            quickCreationDate: monthFirstDay + ":" + monthLastDay
        }
        setInvoiceQuickFilter('thisMonth')
        setInvoiceFilters(filtersTemp)
        handleApplyFiltering(filtersTemp)
    }

    const lastMonthClickHandler = () => {
        const currentDate = new Date();
        const monthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1).getTime();
        const monthLastDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getTime() - 1;
        let filtersTemp = {
            ...initialFiltersQuerry,
            quickCreationDate: monthFirstDay + ":" + monthLastDay
        }
        setInvoiceQuickFilter('lastMonth')
        setInvoiceFilters(filtersTemp)
        handleApplyFiltering(filtersTemp)
    }

    const thisYearClickHandler = () => {
        const currentDate = new Date();
        const monthFirstDay = new Date(currentDate.getFullYear(), 0, 1).getTime();
        const monthLastDay = new Date(currentDate.getFullYear() + 1, 0, 1).getTime() - 1;
        let filtersTemp = {
            ...initialFiltersQuerry,
            quickCreationDate: monthFirstDay + ":" + monthLastDay
        }
        setInvoiceQuickFilter('thisYear')
        setInvoiceFilters(filtersTemp)
        handleApplyFiltering(filtersTemp)
    }

    const quickDateIntervalChangeHandler = (date) => {
        let filtersTemp = {
            ...initialFiltersQuerry,
            quickCreationDate: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        setInvoiceQuickFilter('quickDateInterval')
        setInvoiceFilters(filtersTemp)
        handleApplyFiltering(filtersTemp);
    }

    const quickSearchChange = (quickSearchValue) => {
        let filtersTemp = {
            ...initialFiltersQuerry,
            quickSearch: quickSearchValue
        }
        setInvoiceQuickFilter('quickSearch')
        setInvoiceFilters(filtersTemp)
        handleApplyFiltering(filtersTemp);
    }


    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner animation="border" variant="primary" />
        </SpinnerContainer>
    ) : (
        <NoContent>
            <span>{t("invoiceList:noInvoices")}</span>
        </NoContent>
    );

    if (!loading && invoicesData?.length > 0 && columns?.length > 0) {
        tSpinner = null;
        tBody = (
            <TableBody
                columns={columns}
                data={invoicesData}
                formatDate={formatDate}
                setViewed={handleUpdateViewed}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                userLevel={userLevel}
                userType={userType}
                attachmentHandler={attachmentHandler}
                editStatus={editStatus}
                disableStatus={entityFilter === "client"}
                entityFilter={entityFilter}
                statusFilter={status}
            />
        )
    }


    return (
        <SIContainer>
            <TitleHelmet title={"Demo Fournisseur | " + t("menu:invoices")} />
            <Header>
                <HeaderInfos>
                    <HeaderLocation>{entityFilter === "supplier" ? t("invoiceList:supplier", "Fournisseur") : t("client:customer", "Client")}</HeaderLocation>
                    <HeaderTitle>
                        {t("invoiceDetail:overDueForPayment", "Liste des factures en dépassement de délai de paiement")}
                        {
                            newMessages > 0 ?
                                <div
                                    className={`messagesToggle ${messagesToggleOn ? ' messagesToggleOn' : ''}`}
                                    onClick={() => messagesToggleClickHandler()}
                                    style={{
                                        display: 'flex',
                                        marginLeft: '0.5rem',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <TextsmsIcon fontSize="medium" /><br />
                                    <span>{newMessages}</span>
                                </div>
                                : null
                        }
                    </HeaderTitle>
                </HeaderInfos>
                <HeaderActions>
                    <SearchInputContainer>
                        <SearchInput
                            type="text"
                            placeholder={t("global:search", "Rechercher ...")}
                            autoComplete={"off"}
                            name="quickSearch"
                            value={filtersQuery?.quickSearch || ''}
                            onChange={(e) => quickSearchChange(e.target.value)}
                        />
                        <SearchIcon className="ICDBlue" />
                    </SearchInputContainer>
                    <SelectPeriod
                        value={getQuickFilterLabel()}
                        onChange={quickFilterChangeHandler}
                        options={dateFilterOptions}
                    />
                    <TableActions
                        filterState={showFilters}
                        showFilters={handleShowFilters}
                        filtering={filtering}
                        sortQuery={sortQuery}
                        reverse={reverse}
                        filters={filtersQuery}
                        resetData={handleReset}
                        formatDate={formatDate}
                        getCompressedDoc={getCompressedDoc}
                        selectedRows={selectedRows}
                        selectedAllRows={selectedAllRows}
                        setSelectedAllRows={setSelectedAllRows}
                        userLevel={userLevel}
                        userType={userType}
                        entityFilter={entityFilter}
                    />

                </HeaderActions>
            </Header>
            <SIBody>
                <SITableContainer>
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage}
                        pageChange={handleChangePage}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setInvoicePageSize}
                    />
                    <Table>
                        <TableHead
                            columns={columns}
                            sortQuery={sortQuery}
                            reverse={reverse}
                            setSortQuery={setSortQuery}
                            setReverse={setReverse}
                            getData={handleSort}
                            selectedRows={selectedRows}
                            selectedAllRows={selectedAllRows}
                            setSelectedAllRows={setSelectedAllRows}
                            entityFilter={entityFilter}
                        />
                        <TBody height={density}>
                            {showFilters && <TableFilters
                                columns={columns}
                                show={showFilters}
                                formatDate={formatDate}
                                filters={filtersQuery}
                                filtersChange={filtersChangeHandler}
                                applyFilters={handleApplyFiltering}
                                amountBTTotal={amountBTTotal}
                                amountATTotal={amountATTotal}
                                amountBTValues={amountBTValues}
                                amountATValues={amountATValues}
                                userLevel={userLevel}
                                userType={userType}
                                entityFilter={entityFilter}
                            />}
                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner}
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage}
                        pageChange={handleChangePage}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setInvoicePageSize}
                    />
                    <AttachmentModal show={attachmentModalShow} modalClosed={() => setAttachmentModalShow(false)} invoice={selectedInvoice}></AttachmentModal>
                </SITableContainer>
            </SIBody>
        </SIContainer>
    )
}
const mapStateToProps = (state) => ({
    isAuth: state.auth.token !== null,
    token: state.auth.token,
    loading: state.commandesDeadlineExceeded.loading,
    invoicesData: state.commandesDeadlineExceeded.data,
    count: state.commandesDeadlineExceeded.count,
    currentPage: state.commandesDeadlineExceeded.currentPage,
    pageSize: state.commandesDeadlineExceeded.pageSize,
    filtersQuery: state.commandesDeadlineExceeded.filtersQuery,
    quickFilter: state.commandesDeadlineExceeded.quickFilter,
    filtering: state.commandesDeadlineExceeded.filtering,
    sortQuery: state.commandesDeadlineExceeded.sortQuery,
    reverse: state.commandesDeadlineExceeded.reverse,
    showFilters: state.commandesDeadlineExceeded.showFilters,
    selectedRows: state.commandesDeadlineExceeded.selectedRows,
    selectedAllRows: state.commandesDeadlineExceeded.selectedAllRows,
    userLevel: state.auth.userLevel,
    userType: state.auth.userType,
    amountBTTotal: state.commandesDeadlineExceeded.amountBTTotal,
    amountATTotal: state.commandesDeadlineExceeded.amountATTotal,
    amountBTValues: state.commandesDeadlineExceeded.amountBTValues,
    amountATValues: state.commandesDeadlineExceeded.amountATValues
})
const mapDispatchToProps = dispatch => ({
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    getInvoicesPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.commandesDeadlineExceededGetPage(page, filters, sort, reverse, pageSize)),
    setInvoicePage: (page) => dispatch(actions.commandesDeadlineExceededSetPage(page)),
    setInvoicePageSize: (pageSize) => dispatch(actions.commandesDeadlineExceededSetPageSize(pageSize)),
    setInvoiceFilters: (filters) => dispatch(actions.commandesDeadlineExceededSetFiltersQuery(filters)),
    setInvoiceQuickFilter: (quickFilterField) => dispatch(actions.commandesDeadlineExceededSetQuickFilter(quickFilterField)),
    setSortQuery: (sortQ) => dispatch(actions.commandesDeadlineExceededSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.commandesDeadlineExceededSetReverseSort(reverseS)),
    updateShowFilters: (show) => dispatch(actions.commandesDeadlineExceededUpdateShowFilters(show)),

    setSelectedRows: (rowId, selceted) => dispatch(actions.commandesDeadlineExceededSelectRow(rowId, selceted)),
    setSelectedAllRows: (selectedAll) => dispatch(actions.commandesDeadlineExceededSelectAllRows(selectedAll)),
    getCompressedDoc: (selection) => dispatch(actions.commandesDeadlineExceededGetCompressedDocument(selection))
})
export default connect(mapStateToProps, mapDispatchToProps)(CommandesDeadlineExceeded)
