import React, { useCallback } from 'react'
import Axios from '../../../axios-proas';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TD } from '../../../styles/Table.styled';
import { showColumn, getAlignPosition } from '../../../shared/utility';
import { SupplierLink } from '../../../containers/ReferentielFournisseur/ReferentielFournisseur.Styled';

function TableBody(props) {
    const { clientsData, userRole, setEntitydetail, entityOwnerDetail, columns } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleBoxChecked = (event, row) => {
        Axios.put('/clientbusiness/' + row.uid + '/receivesAttachment').then(response => {
            event.target.checked = response.data;
            row.receivesAttachment = event.target.checked;
        }).catch(error => {
            console.error(error);
        })
    }
    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])


    const getTokenVisualisation = (uid) => {
        Axios.get('/clientbusiness/' + uid + '/userTokenVisualisation')
            .then(response => {
                Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                //window.location.reload(false);
                //Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('loginVisualisation', response.data.login)
                navigate("/userVisualisation")
            })
    }

    const getDematType = (client) => {
        var dematExpression = new Array()
        if (client?.edi == 1) dematExpression.push('EDI');
        if (client?.chorus == 1) dematExpression.push('CHORUS');
        if (client?.paper == 1) dematExpression.push('PAPER');
        if (client?.sendMail == 1) dematExpression.push('EMAIL');
        if (client?.accessPortal == 1) dematExpression.push('Accès portail');
        return dematExpression
    }



    return (
        <>
            {
                clientsData ? clientsData.map(row => (
                    <tr key={row.id} className="clientTableBody__tr">
                        <TD style={{ display: !showColumn(columns, "instanceName") && 'none', textAlign: getAlignPosition(columns, "instanceName") }}>
                            <SupplierLink
                                to={`/entity/${row.uid}`}
                            >
                                {row?.name}
                            </SupplierLink>
                        </TD>
                        <TD style={{ display: !showColumn(columns, "creationDate") && 'none', textAlign: getAlignPosition(columns, "creationDate") }}>{formatDate(+row.creationDate)}</TD>
                        <TD style={{ display: !showColumn(columns, "usersNumber") && 'none', textAlign: getAlignPosition(columns, "usersNumber") }}>{row?.totalUsers}</TD>
                        <TD style={{ display: !showColumn(columns, "status") && 'none', textAlign: getAlignPosition(columns, "status") }}>{row?.status}</TD>
                    </tr>
                )) : null
            }
        </>
    )
}

export default TableBody
