import React, { useEffect, useState } from "react";
import { Notyf } from "notyf";
import Axios from "../../axios-proas";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { formatDate, formatNumber, getNotyfObject } from "../../shared/utility";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { useTheme } from "styled-components";
import {
  PrimaryBtnOutlineAction,
  SecondaryBtnOutlineAction,
} from "../../styles/Common";
import {
  IDBody,
  IDContainer,
  IDHeader,
  IDHeaderActions,
  IDHeaderContainer,
  IDHeaderTitle,
  IDHeaderInvoiceDate,
  IDHeaderWrapper,
  IDInvoiceDate,
  IDInvoiceDateLabel,
  IDInvoiceDateValue,
  IDProgressLineWrapper,

} from "./InvoiceDetail.styled";
import IDProgressLine from "../../components/InvoiceDetail/IDProgressLine/IDProgressLine";
import SupplierProgressLine from "../../components/InvoiceDetail/SupplierProgressLine/IDProgressLine";
import CustomerProgressLine from "../../components/InvoiceDetail/CustomerProgressLine/IDProgressLine";
import InvoiceView from "../../components/InvoiceDetail/InvoiceBody/InvoiceMain/InvoiceView/InvoiceView";
import InvoiceSide from "../../components/InvoiceDetail/InvoiceBody/InvoiceSide/InvoiceSide";

function InvoiceDetail(props) {
  const {
    loadingData,
    getInvoiceDataByToken,
    invoiceData,
    invoiceError,
    getInvoiceData,
    resetAdmToInitialState,
    userType,
    origin
  } = props;
  const theme = useTheme();
  let { uid, token } = useParams();
  const notyf = getNotyfObject();
  const { t } = useTranslation();
  let location = useLocation();
  let navigate = useNavigate();
  console.log(userType)
  console.log(origin)

  const [navTabsList, setNavTabsList] = useState([]);
  const [allEventsInvoice, setAllEventsInvoice] = useState();
  const typeInvoiceToNavListe = (xml, edi, pdf, chorus) => {
    var list = [];
    if (pdf !== null) list = [...list, { label: "PDF", value: "pdf" }];
    if (xml !== null) list = [...list, { label: "XML", value: "xml" }];
    if (edi !== null) list = [...list, { label: "EDI", value: "edi" }];
    if (chorus !== null) list = [...list, { label: "CHORUS", value: "chorus" }];

    setNavTabsList(list);
  };

  useEffect(() => {
    typeInvoiceToNavListe(
      invoiceData?.xmlFile,
      invoiceData?.ediFile,
      invoiceData?.docFile,
      invoiceData?.chorusFile
    );
  }, [
    invoiceData?.xmlFile,
    invoiceData?.ediFile,
    invoiceData?.docFile,
    invoiceData?.chorusFile,
  ]);


  const editStatus = (status, comment = null, reason = null) => {
    Axios.put("/invoice//updateStatus", {
      InvoiceId: uid,
      status: status,
      reason,
      comment,
    })
      .then((response) => {
        getInvoiceData(uid);
        notyf.success(t("global:success", "Statut modifié"));
      })
      .catch((err) => {
        notyf.error(t('global:error', "Une erreur s'est produite"));
      });
  };


  // if(error) {
  //     notyf.error(t("mandat:generatedErrorMessage"));
  // }

  const getTimelineData = () => {

    Axios.get("invoice/" + uid + "/invoiceAllEvent", {

    }).then(response => {
      setAllEventsInvoice(response?.data?.events)
    }).catch(response => {

      notyf.error(t("mandat:generatedErrorMessage", t('global:error', "Une erreur s'est produite")));
    })
  }

  useEffect(() => {
    getTimelineData()
    if (token) {
      getInvoiceDataByToken(token)
    } else {
      getInvoiceData(uid)
    }
    console.log(invoiceData)
    return () => {
      resetAdmToInitialState()
    }


  }, [uid, token])

  let mandatJsx = null;

  // if(error) {
  //     notyf.error(t("mandat:generatedErrorMessage"));
  // }

  if (loadingData) mandatJsx = (<Spinner />)

  else if (invoiceData)
    mandatJsx = (
      <>
        <IDHeaderWrapper>
          <IDHeaderContainer>
            <IDHeader>
              <IDHeaderTitle>
                {t("invoiceEvent:invoice", "Facture :")} {invoiceData?.number}&nbsp;
                ({invoiceData?.supplierName} - {invoiceData?.clientName})
              </IDHeaderTitle>
              {
                invoiceData?.status !== "ERROR" &&
                <>
                  <IDHeaderInvoiceDate>
                    {formatNumber(invoiceData?.inclusiveTaxAmount)} {t("invoiceDetail:TTC", "TTC")}
                  </IDHeaderInvoiceDate>
                  <IDInvoiceDate>
                    <IDInvoiceDateLabel>{t("invoiceDetail:invoicingDate", "Date de facturation :")} </IDInvoiceDateLabel>
                    <IDInvoiceDateValue>{formatDate(+invoiceData?.issuingDate)}</IDInvoiceDateValue>
                  </IDInvoiceDate>
                </>
              }
            </IDHeader>
            <IDProgressLineWrapper>
              {
                (userType == "supplier" || origin == 'supplier') ?
                  <SupplierProgressLine
                    allEventsInvoice={allEventsInvoice}
                    error={+invoiceData?.error}
                    status={invoiceData?.status}
                    editStatus={editStatus}
                    entityFilter={location?.state?.entityFilter}
                    accessToken={token}
                    lastMessage={invoiceData?.lastMessage}

                  />
                  : ((userType == "client" || origin == 'client') ?
                    <CustomerProgressLine
                      allEventsInvoice={allEventsInvoice}
                      error={+invoiceData?.error}
                      status={invoiceData?.status}
                      editStatus={editStatus}
                      entityFilter={location?.state?.entityFilter}
                      accessToken={token}
                      lastMessage={invoiceData?.lastMessage}
                      origin={origin}
                    />
                    :
                    (invoiceData?.enttityFilter == "supplier" ?
                      <IDProgressLine
                        allEventsInvoice={allEventsInvoice}
                        error={+invoiceData?.error}
                        status={invoiceData?.status}
                        editStatus={editStatus}
                        entityFilter={location?.state?.entityFilter}
                        accessToken={token}
                        lastMessage={invoiceData?.lastMessage}
                      /> :
                      <CustomerProgressLine
                        allEventsInvoice={allEventsInvoice}
                        error={+invoiceData?.error}
                        status={invoiceData?.status}
                        editStatus={editStatus}
                        entityFilter={location?.state?.entityFilter}
                        accessToken={token}
                        lastMessage={invoiceData?.lastMessage}
                      />
                    )


                  )
              }
            </IDProgressLineWrapper>
          </IDHeaderContainer>
          {
            !token &&
            <IDHeaderActions>
              <SecondaryBtnOutlineAction
                border={"#D9E1E7"}
                color={"#2174B9"}
                onClick={() => navigate(-1)}
              >
                {t("global:back", "Retour")}
              </SecondaryBtnOutlineAction>
            </IDHeaderActions>
          }
        </IDHeaderWrapper>

        <IDBody accessToken={token}>
          <InvoiceView invoiceData={invoiceData} navTabsList={navTabsList} />
          {
            !token ?
              <InvoiceSide invoiceIntegratedDetail={true} entityFilter={invoiceData?.enttityFilter} />
              : null
          }
        </IDBody>
      </>
    )

  return (
    <>
      <IDContainer>
        {mandatJsx}
      </IDContainer>
    </>
  )
}
const mapStateToProps = (state) => ({
  loadingData: state.invoiceDetail.loadingData,
  invoiceData: state.invoiceDetail.invoiceData,
  invoiceError: state.invoiceDetail.invoiceError,

  userType: state.auth.userType,
  // role: state.auth.role,
  // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
  // creatorName : state.mandat?.admData?.creatorName
});
const mapDispatchToProps = (dispatch) => ({
  getInvoiceData: (invoiceUid) => dispatch(actions.getInvoiceData(invoiceUid)),
  getInvoiceDataByToken: (invoiceToken) =>
    dispatch(actions.getInvoiceDataByToken(invoiceToken)),
  // setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
  // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
  resetAdmToInitialState: () => dispatch(actions.resetAdmToInitialState()),
});
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail);
