import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTheme } from "styled-components";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckIcon from "@mui/icons-material/Check";
import {
  THead,
  TH,
  THContainer,
  TD,
  Table,
  TBody,
} from "./InvoiceListCommand.styled";
import { TitleTable } from "./CommandModal/research.styled";
import { EventEmitter } from "stream";
import DropdownList from "react-widgets/DropdownList";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from "react-i18next";
function CommandTable(props) {
  const {
    loadingData,
    invoiceData,
    invoiceError,
    isCommandModal,
    commandLineData,
    setCommandLineSelected,
    commandLineSelected,
    additionalLineData,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const [color, setColor] = useState(true)
  const [columns, setColumns] = useState([
    { title: "#", field: "code", show: true, alignSelf: "center" },
    { title: t("invoiceList:commandNumber", "N° commande"), field: "name", show: true, alignSelf: "center" },
    { title: t("invoiceList:ref", "Ref"), field: "zipCode", show: true, alignSelf: "center" },
    { title: t("invoiceDetail:designation", "Désignation"), field: "city", show: true, alignSelf: "center" },
    { title: t("invoiceList:Qty", "Qty"), field: "channel", show: true, alignSelf: "center" },
    {
      title: t("invoiceDetail:unitPrice", "Prix unitaire"),
      field: "creationDate",
      show: true,
      alignSelf: "center",
    },
    { title: "%R", field: "status", show: true, alignSelf: "center" },
    { title: t("invoiceList:vatCode", "Code TVA"), field: "city", show: true, alignSelf: "center" },
    { title: t("invoiceList:exclusiveAmount", "Montant HT"), field: "channel", show: true, alignSelf: "center" },
    { title: t("invoiceList:vatAmount", "Montant TVA"), field: "channel", show: true, alignSelf: "center" },
    { title: t("invoiceList:inclusiveAmount", "Montant TTC"), field: "channel", show: true, alignSelf: "center" },
    { title: t("global:account", "Compte"), field: "creationDate", show: true, alignSelf: "center" },
  ]);

  const dataOptions = [
    { value: '6241', name: "[6241] " + t("invoiceDetail:transportCosts", "Transport sur achat") },
    { value: '606100', name: "[606100] " + t("invoiceDetail:unstockedSupplies", "Fourniture non stockable") },
    { value: '611400', name: "[611400] " + t("invoiceDetail:overhead", "Frais généraux") }
  ]

  // useEffect(() => {}, [commandLineSelected]);

  let head = (
    <THead>
      <tr>
        <TH
          key={"column654"}
          width="50px"
          scope="col"
          style={{ border: "1px solid {theme.colors.secondary} !important;" }}
        >
          <THContainer style={{ justifyContent: "center" }}></THContainer>
        </TH>
        {columns.map((column, index) => (
          <TH
            key={index}
            scope="col"
            width={column?.width}
            onClick={() => columnClickHandler(column.field, reverse)}
          >
            <THContainer style={{ justifyContent: column.alignSelf }}>
              <span>{column.title}</span>
              <div className="columnHead__sort"></div>
            </THContainer>
          </TH>
        ))}
      </tr>
    </THead>
  );

  const handleCheckboxChange = (e) => {
    console.log(commandLineSelected);
    // console.log(e.target.name)
    // console.log(e.target.checked)
    let uidCld = e.target.name;
    let checked = e.target.checked;
    let jsk = null;
    let index = commandLineSelected.findIndex((line) => line.uid == uidCld);
    if (index >= 0) {
      // console.log(index);
      jsk = commandLineSelected[index];
      // console.log(jsk);
      jsk.checked = checked;
    }
    setCommandLineSelected(
      Object.values({ ...commandLineSelected, [index]: jsk })
    );
  };

  const inputChangedHandler = () => {
    setColor(false)
  }

  // const getSelectedRowValue = (uid) => {
  //   const index = commandLineSelected?.findIndex((cld) => cld.uid == uid);
  //   return index >= 0 ? true : false;
  // };

  let options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }

  let body = (
    <>
      {commandLineSelected?.map((row, index) => (
        <tr key={index}>
          <TD>
            <input
              type="checkbox"
              name={row.uid}
              onChange={(e) => handleCheckboxChange(e)}
              checked={+row?.checked}
              className="form-check-input"
            />
          </TD>
          <TD>{index + 1}</TD>
          <TD
            style={{
              backgroundColor: +row.additional ? theme.colors.gray : "white",
            }}
          >
            {row.number}
          </TD>
          <TD>{row.ref}</TD>
          <TD>{row.designation}</TD>
          <TD>{row.quantity}</TD>
          {/* <TD>{new Intl.NumberFormat('fr-FR').format(parseFloat(+row.price).toFixed(2))} €</TD> */}
          <TD>{parseFloat(+row.price).toLocaleString(undefined, options)} €</TD>
          <TD>{row.percentageReduction}</TD>
          {/* <TD>{row.price.toLocaleString(undefined, options)}</TD> */}
          <TD>{row.codeTVA}</TD>
          {/* <TD>{new Intl.NumberFormat('fr-FR').format(parseFloat(+row.eta).toFixed(2))} €</TD> */}
          <TD>{parseFloat(+row.eta).toLocaleString(undefined, options)} €</TD>

          {/* <TD>{new Intl.NumberFormat('fr-FR').format(parseFloat(+row.tva).toFixed(2))} €</TD> */}
          <TD>{parseFloat(+row.tva).toLocaleString(undefined, options)} €</TD>

          {/* <TD>{new Intl.NumberFormat('fr-FR').format(parseFloat(+row.ita).toFixed(2))} €</TD> */}
          <TD>{parseFloat(+row.ita).toLocaleString(undefined, options)} €</TD>
          {/* {(!row.eta.includes(",") && !row.eta.includes(".")) ? ",00" : null} */}
          <TD style={{ backgroundColor: (row?.price == 7340.92 && color) ? "rgb(255, 221, 221)" : "" }}>
            {
              (row?.price == 7340.92 && color) ?
                <DropdownList
                  style={{ backgroundColor: (row?.price == 7340.92 && color) ? "rgb(255, 221, 221)" : "" }}
                  value={row?.account}
                  data={dataOptions}
                  name="account"
                  dataKey="value"
                  textField="name"
                  busy
                  busySpinner={
                    <ExpandMoreIcon style={{ width: "21px" }} />
                  }
                  onChange={(value) => inputChangedHandler(value, "account")}
                />
                : (!color ? "606100" : row?.account)
            }
          </TD>
        </tr>
      ))}
    </>
  );

  return (
    <>
      {isCommandModal && <TitleTable>{t("invoiceDetail:invoiceLineReconcile", "Ligne de facture à rapprocher")}</TitleTable>}
      <Table style={{ width: "100%" }}>
        {head}
        <TBody>{body}</TBody>
      </Table>
    </>
  );
}
const mapStateToProps = (state) => ({
  loadingData: state.invoiceDetail.loadingData,
  invoiceData: state.invoiceDetail.invoiceData,
  invoiceError: state.invoiceDetail.invoiceError,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(CommandTable);
