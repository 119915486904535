import React, { useCallback, useEffect, useState } from 'react';
import TableActions from '../../components/ToDoListe/TableActions/TableActions';
import TableHead from '../../components/ToDoListe/TableHead/TableHead';
import TableBody from '../../components/ToDoListe/TableBody/TableBody';
import TableFilters from '../../components/ToDoListe/TableFilters/TableFilters';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import { NoContent, SpinnerContainer, Table, TBody } from '../../styles/Table.styled';
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import { EMBody, EMContainer, EMTableContainer } from './ToDo.styled';
import { Spinner } from 'react-bootstrap';
import Axios from '../../axios-proas';

function ToDo(props) {
    const {
        userType,
        loading,
        tasksData,
        count,
        currentPage,
        pageSize,
        getTasksPage,
        setTasksPage,
        setTasksPageSize,
        sortQuery,
        reverse,
        filtersQuery,
        showFilters,
        userRole,
        updateShowFilters,
        entityOwnerDetail,
        setEntitydetail,
        setTasksFilters,
        setSortQuery,
        setReverse
    } = props;
    const { t } = useTranslation();
    const [density, setDensity] = useState("2.25rem")
    const [columns, setColumns] = useState([

        { title: t("userTasks:tasksObject", "Objet de la tâche"), field: 'object', show: true, alignSelf: "left" },
        { title: t("userTasks:processType", "Type processus"), field: 'processType', show: true, alignSelf: "left" },
        { title: t("userTasks:status", "Statut"), field: 'status', show: true, alignSelf: "left" },
        { title: t("userTasks:statusDate", "Date du statut"), field: 'creationDate', show: true, alignSelf: "left" }
    ])


    useEffect(() => {
        // Axios.get("/user//tasks").then(res => {
        //     const eventsData = res.data?.events ? res.data.events : [];
        //     setTasks(eventsData);
        // }).catch(res => {
        //     notyf.error("Une erreur s'est produite!");
        // }).finally(() => {
        // })
        getTasksPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)

    }, [currentPage, pageSize])

    const isEmptyFilters = () => {
        if (filtersQuery === null)
            return true;
        let filtersList = columns.map(column => column.field)
        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (!showFilters && !isEmpty) {
            resetFilters()
        }
    }, [showFilters]);

    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue = reverse[field] ? reverse[field] : false
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getTasksPage(currentPage, filtersQuery, field, newReverse, pageSize)
    }

    const handlePageChange = useCallback((newPage) => {
        setTasksPage(newPage);
    }, [currentPage]);

    const handleApplyFiltering = useCallback((filters) => {
        console.log("to do")
        console.log(filters)
        getTasksPage(1, filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);

    const resetFilters = () => {
        setTasksFilters(null);
        setTasksPage(1)
        getTasksPage(1, null, sortQuery, reverse, pageSize)
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
    ) : (
        <NoContent>
            <span>{t("clientList:noCFFT", "Liste vide")}</span>
        </NoContent>
    );

    if (!loading && tasksData?.length > 0) {
        tSpinner = null;
        tBody = (<TableBody
            userType={userType}
            columns={columns}
            clientsData={tasksData}
            tasks={tasksData}
            userRole={userRole}
            setEntitydetail={setEntitydetail}
            entityOwnerDetail={entityOwnerDetail}
        />)
    }

    return (
        <EMContainer>
            <TitleHelmet title={"Démo Portail | " + t("menu:Tasks")} />
            <Header>
                <HeaderInfos>
                    <HeaderLocation>{t("global:tasks", "Mes tâches")}</HeaderLocation>
                    <HeaderTitle>{t("global:list", "Liste")}</HeaderTitle>
                </HeaderInfos>
                <HeaderActions>
                    <TableActions
                        resetFilters={resetFilters}
                        showFilters={showFilters}
                        setShowFilters={updateShowFilters}
                        tasksFilters={filtersQuery}
                        filtersChange={setTasksFilters}
                        handleApplyFiltering={handleApplyFiltering}
                    />
                </HeaderActions>
            </Header>
            <EMBody>
                <EMTableContainer>
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setTasksPageSize}
                    />
                    <Table>
                        <TableHead
                            columns={columns}
                            reverse={reverse}
                            columnClickHandler={columnClickHandler}
                            userRole={userRole}
                        />
                        <TBody height={density}>
                            <TableFilters
                                columns={columns}
                                showFilters={showFilters}
                                tasksFilters={filtersQuery}
                                filtersChange={setTasksFilters}
                                applyFilters={handleApplyFiltering}
                            />
                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner}
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setTasksPageSize}
                    />
                </EMTableContainer>
            </EMBody>
        </EMContainer>
    )
}

const mapStateToProps = (state) => ({
    loading: state.tasks.loading,
    tasksData: state.tasks.data,
    count: state.tasks.count,
    currentPage: state.tasks.currentPage,
    pageSize: state.tasks.pageSize,
    sortQuery: state.tasks.sortQuery,
    reverse: state.tasks.reverse,
    filtersQuery: state.tasks.filtersQuery,
    showFilters: state.tasks.showFilters,
    userRole: state.auth.role,
    userType: state.auth.userType,

})
const mapDispatchToProps = dispatch => ({
    getTasksPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.tasksGetPage(page, filters, sort, reverse, pageSize)),
    setTasksPage: (page) => dispatch(actions.tasksSetPage(page)),
    setTasksPageSize: (pageSize) => dispatch(actions.tasksSetPageSize(pageSize)),
    setSortQuery: (sortQ) => dispatch(actions.tasksSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.tasksSetReverseSort(reverseS)),
    setTasksFilters: (filters) => dispatch(actions.tasksSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.tasksUpdateShowFilters(show))
})
export default connect(mapStateToProps, mapDispatchToProps)(ToDo)
