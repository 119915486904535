import React, { useMemo } from 'react';
import { formatDate } from '../../../shared/utility';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { SIProgressLine, SIProgressLineBar, SIProgressLineItem, SIProgressLineItemTitle } from './StatusProgressLine.styled';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

function StatusProgressLine({ status, creationDate, campaignDate, endDate }) {
    const theme = useTheme();
    const { t } = useTranslation()
    const statusList = useMemo(() => [
        { title: t("global:creation", "Création : ") + formatDate(creationDate), value: "scheduled" },
        { title: t("global:start", "Début : ") + formatDate(campaignDate), value: "inProgress" },
        { title: t("global:end", "Fin : ") + formatDate(endDate), value: "completed" },
    ], [t]);

    const getStatusColor = (value, index) => {
        const statusIndex = statusList.findIndex(item => item.value.toUpperCase() === status.toUpperCase())
        if (status.toUpperCase() === "completed".toUpperCase())
            return theme.colors.secondary;

        if (value.toUpperCase() === status.toUpperCase())
            return theme.colors.secondary;

        if (index < statusIndex)
            return theme.colors.secondary;

        return theme.colors.gray;
    }

    const showProgressLineBar = (value, index) => {
        const statusIndex = statusList.findIndex(item => item.value.toUpperCase() === status.toUpperCase());
        return index <= statusIndex;
    }

    return (
        <>
            <SIProgressLine>
                {
                    statusList.map((item, index) => (
                        <SIProgressLineItem
                            key={index}
                        >
                            <SIProgressLineItemTitle
                            // color={getStatusColor(item.value, index)}
                            >
                                <span style={{ color: theme.colors.secondary }}>
                                    {item.title}
                                </span>
                                {/* <CheckCircleOutlineIcon fontSize="small" /> */}
                            </SIProgressLineItemTitle>
                            {
                                showProgressLineBar(item.value, index) ? (
                                    <SIProgressLineBar
                                        isFirst={index === 0}
                                        isCurrent={item.value.toUpperCase() === status.toUpperCase()}
                                        color={getStatusColor(item.value, index)}
                                    >
                                    </SIProgressLineBar>
                                ) : <SIProgressLineBar
                                    color={getStatusColor(item.value, index)}
                                >
                                </SIProgressLineBar>
                            }
                        </SIProgressLineItem>
                    ))

                }



            </SIProgressLine>

        </>
    );
}

export default StatusProgressLine;
