import React, { useEffect, useState } from "react";
import Axios from "../../../../../axios-proas";
import {
  BtnSmOutlineAction,
  FormInput,
  FormLabel,
  FormSelect,
  PrimaryBtnOutlineAction,
  FormGroupInvInf,
  FormGroupInv,
  GroupCommande,
  FormGroupInvCommandes,
} from "../../../../../styles/Common";
import {
  FContainer,
  FFormWrap,
  FormGroupDetail,
  FormGroup,
  FormGroupInf,
  FormGroupDetailChild,
  CheckboxDiv,
  ContentChild,
  CheckboxGroup,
  FHeader,
  FHInfos,
} from "./Fields.styled";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import { DPContainer } from "../../../../../containers/CampaignForm/CampaignForm.styled";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import { checkValidity, getNotyfObject } from "../../../../../shared/utility";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../../../shared/utility";
import { listClasses } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Brightness7SharpIcon from "@mui/icons-material/Brightness7Sharp";
import PersonIcon from "@mui/icons-material/Person";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import StatusModal from "./StatusModal/StatusModal";
import UsersModal from "./UsersModal/UsersModal";
import FileManagerModal from "../../CommandesDeadlineExceededDetailMain/InvoiceView/FileManagerModal/FileManagerModal";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions/index";

function Recouvrement({ invoiceData, setInvoiceData, getInvoiceData }) {
  const notyf = getNotyfObject();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [existInvoice, setExistInvoice] = useState(false);
  const [l1_call, setL1_call] = useState(false);
  const [l2_call, setL2_call] = useState(false);
  const [l3_Rar, setL3_Rar] = useState(false);
  const [contentieux, setContentieux] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [tempEvent, setTempEvent] = useState(null);
  const [showUsersModal, setShowUsersModal] = useState(false);

  const [usersList, setUsersList] = useState([]);

  const validateInvoice = () => {
    Axios.put(
      `/invoice/${invoiceData?.invoiceUid}/validateInvoice`,
      invoiceData
    )
      .then((res) => {
        notyf.success("global:success", "Succés");
        navigate(-1);
      })
      .catch((err) => {
        notyf.error(t('global:error', "Une erreur s'est produite"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateRecovery = (name, value, comment) => {
    Axios.put(`/invoice/${invoiceData?.invoiceUid}/updateRecovery`, { name: name, value: value, comment: comment }).then(res => {
      //   notyf.success("global:success", "Succés")
      getInvoiceData(invoiceData?.invoiceUid);
    })
      .catch((err) => {
        notyf.error(t('global:error', "Une erreur s'est produite"));
      })
      .finally(() => { });
  };

  const checkExistInvoice = (number = null) => {
    let errorsTmp = [];
    setErrors([]);
    setExistInvoice(false);
    Axios.get(`/invoice/${invoiceData?.invoiceUid}/existInvoice`, {
      params: {
        number: number ? number : invoiceData.number,
        supplierUid: invoiceData.supplierUid,
      },
    })
      .then((res) => {
        let exist = res?.data?.exist ? true : false;
        if (exist) {
          errorsTmp.push(
            "Une facture avec ce numéro existe déjà pour ce fournisseur"
          );
          setExistInvoice(true);
        }
        setErrors(errorsTmp);
      })
      .catch((err) => {
        notyf.error(t('global:error', "Une erreur s'est produite"));
      });
  };

  useEffect(() => {
    Axios.get(`/invoice/${invoiceData?.invoiceUid}/usersListByEntityInvoice`)
      .then((response) => {
        console.log(response?.data.entityUsers);
        setUsersList(response?.data.entityUsers);
      })
      .catch((err) => {
        notyf.error(t('global:error', "Une erreur s'est produite"));
      });

    let invoiceDataInit = {
      ...invoiceData,
      documentType: invoiceData.documentType ? invoiceData.documentType : "INV",
      issuingDate: invoiceData.issuingDate
        ? +invoiceData.issuingDate
        : new Date().getTime(),
      dueDate: invoiceData.dueDate
        ? +invoiceData.dueDate
        : new Date().getTime(),
      l1_call: invoiceData.l1_call == "1" ? true : false,
      l2_call: invoiceData.l2_call == "1" ? true : false,
      l3_Rar: invoiceData.l3_Rar == "1" ? true : false,
      contentieux: invoiceData.contentieux == "1" ? true : false,
    };
    setInvoiceData(invoiceDataInit);
  }, []);

  const fieldChangeHandler = (e) => {
    const { name, value } = e.target;
    setInvoiceData({ ...invoiceData, [name]: value });
    if (name === "number") {
      checkExistInvoice(value);
    }
  };

  const checkInvoiceFormValidity = () => {
    let errors = [];
    if (
      !invoiceData.hasOwnProperty("number") ||
      invoiceData?.number === null ||
      invoiceData?.number === "" ||
      !checkValidity(invoiceData?.number, { required: true })
    ) {
      errors.push(
        t("invoiceDetail:error_number", "Le numéro de facture est obligatoire")
      );
    }
    if (
      !invoiceData.hasOwnProperty("documentType") ||
      invoiceData?.documentType === null ||
      invoiceData?.documentType === "" ||
      !checkValidity(invoiceData?.documentType, { required: true })
    ) {
      errors.push(
        t(
          "invoiceDetail:error_documentType",
          "Le type de document est obligatoire"
        )
      );
    }
    if (
      !invoiceData.hasOwnProperty("exclusiveTaxAmount") ||
      invoiceData?.exclusiveTaxAmount === null ||
      parseFloat(invoiceData?.exclusiveTaxAmount) === 0 ||
      !checkValidity(invoiceData?.exclusiveTaxAmount, { required: true })
    ) {
      errors.push(
        t(
          "invoiceDetail:error_exclusiveTaxAmount",
          "Le montant HT est obligatoire"
        )
      );
    }

    if (
      !invoiceData.hasOwnProperty("inclusiveTaxAmount") ||
      invoiceData?.inclusiveTaxAmount === null ||
      parseFloat(invoiceData?.inclusiveTaxAmount) === 0 ||
      !checkValidity(invoiceData?.inclusiveTaxAmount, { required: true })
    ) {
      errors.push(
        t(
          "invoiceDetail:error_inclusiveTaxAmount",
          "Le montant TTC est obligatoire"
        )
      );
    }
    if (
      !invoiceData.hasOwnProperty("supplierUid") ||
      invoiceData?.supplierUid === null ||
      invoiceData?.supplierUid === "" ||
      !checkValidity(invoiceData?.supplierUid, { required: true })
    ) {
      errors.push(
        t("invoiceDetail:error_supplierUid", "Le fournisseur est obligatoire")
      );
    }
    if (
      !invoiceData.hasOwnProperty("clientUid") ||
      invoiceData?.clientUid === null ||
      invoiceData?.clientUid === "" ||
      !checkValidity(invoiceData?.clientUid, { required: true })
    ) {
      errors.push(
        t("invoiceDetail:error_clientUid", "Le client est obligatoire")
      );
    }
    return errors;
  };

  const validateInvoiceHandler = () => {
    let checkErrors = checkInvoiceFormValidity();
    if (existInvoice) {
      checkErrors.push(
        "Une facture avec ce numéro existe déjà pour ce fournisseur"
      );
    }
    if (checkErrors && checkErrors.length > 0) {
      setErrors(checkErrors);
    } else {
      setErrors([]);
      setLoading(true);
      validateInvoice();
    }
  };

  const handleToggle = (comment) => {
    console.log(comment)
    setShowStatusModal(false)
    console.log(tempEvent.target.name);
    console.log(tempEvent.target.checked);
    switch (tempEvent.target.name) {
      case "l1_call":
        setL1_call(!tempEvent.target.checked);
        break;
      case "l2_call":
        setL2_call(!tempEvent.target.checked);
        break;
      case "l3_Rar":
        setL3_Rar(!tempEvent.target.checked);
        break;
      case "contentieux":
        setContentieux(!tempEvent.target.checked);
        break;
      default:
        break;
    }
    updateRecovery(tempEvent.target.name, !tempEvent.target.checked, comment);
  };

  return (
    <FContainer>
      <FormLabel
        color={"#2174B9"}
        htmlFor="number"
        style={{ cursor: "pointer" }}
      >
        <SupervisorAccountIcon color="#2174B9" sx={{ fontSize: 40 }} />
        <p
          style={{ padding: "12px 0px 0px 5px", fontSize: "0.9rem" }}
          onClick={() => setShowUsersModal(true)}
        >
          {t("global:contactList", "Liste de contacts")}
        </p>
      </FormLabel>
      <FFormWrap>
        <FormGroup >
          <FormGroupInf
            active={+invoiceData?.numberReminds > 0 && invoiceData?.l1_call}
          >
            <FormLabel
              style={{ fontSize: "23px", margin: "unset" }}
              color={"#505050"}
              htmlFor="number"
            >
              1{" "}
            </FormLabel>
          </FormGroupInf>
          <FormGroupDetail height={"147px"}>
            <FormGroupDetailChild active={+invoiceData?.numberReminds > 0}>
              <ContentChild>
                <FormLabel color={"#505050"} htmlFor="number">
                  1-7 {t("global:exceedingDays", "jours de dépassement")}{" "}
                </FormLabel>
                <FormLabel color={"#505050"} htmlFor="number">
                  {t("global:1EmailReminder", "1er Email de relance")}{" "}
                </FormLabel>
              </ContentChild>
              <Brightness7SharpIcon />
            </FormGroupDetailChild>
            <FormGroupDetailChild active={invoiceData?.l1_call}>
              <ContentChild>
                <FormLabel color={"#505050"} htmlFor="number">
                  1-7 {t("global:exceedingDays", "jours de dépassement")}{" "}
                </FormLabel>
                <FormLabel color={"#505050"} htmlFor="number">
                  {t("global:1CallRender", "1er Relance téléphonique")}{" "}
                </FormLabel>
              </ContentChild>
              <PersonIcon />
            </FormGroupDetailChild>
          </FormGroupDetail>
          <CheckboxGroup>
            <CheckboxDiv
              edge="end"
              //  onChange={handleToggle(value)}
              checked={+invoiceData?.numberReminds > 0}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleOutlineIcon />}
            />
            <CheckboxDiv
              edge="end"
              name="l1_call"
              onChange={(e) => {
                setTempEvent(e);
                setShowStatusModal(!showStatusModal);
              }}
              checked={invoiceData?.l1_call}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleOutlineIcon />}
            />
          </CheckboxGroup>
        </FormGroup>

        <FormGroup style={{ marginBottom: "16px" }}>
          <FormGroupInf
            active={+invoiceData?.numberReminds > 1 && invoiceData?.l2_call}
          >
            <FormLabel
              style={{ fontSize: "23px", margin: "unset" }}
              color={"#505050"}
              htmlFor="number"
            >
              2{" "}
            </FormLabel>
          </FormGroupInf>
          <FormGroupDetail height={"147px"}>
            <FormGroupDetailChild active={+invoiceData?.numberReminds > 1}>
              <ContentChild>
                <FormLabel color={"#505050"} htmlFor="number">
                  8-30 {t("global:exceedingDays", "jours de dépassement")}{" "}
                </FormLabel>
                <FormLabel color={"#505050"} htmlFor="number">
                  {t("global:2Emaieminder", "2eme Email de relance")}
                </FormLabel>
              </ContentChild>
              <Brightness7SharpIcon />
            </FormGroupDetailChild>
            <FormGroupDetailChild active={invoiceData?.l2_call}>
              <ContentChild>
                <FormLabel color={"#505050"} htmlFor="number">
                  8-30 {t("global:exceedingDays", "jours de dépassement")}{" "}
                </FormLabel>
                <FormLabel color={"#505050"} htmlFor="number">
                  {t("global:2Caleminder", "2eme Relance téléphonique")}{" "}
                </FormLabel>
              </ContentChild>
              <PersonIcon />
            </FormGroupDetailChild>
          </FormGroupDetail>
          <CheckboxGroup>
            <CheckboxDiv
              edge="end"
              //  onChange={handleToggle(value)}
              checked={+invoiceData?.numberReminds > 1}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleOutlineIcon />}
            />
            <CheckboxDiv
              edge="end"
              name="l2_call"
              onChange={(e) => {
                setTempEvent(e);
                setShowStatusModal(!showStatusModal);
              }}
              checked={invoiceData?.l2_call}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleOutlineIcon />}
            />
          </CheckboxGroup>
        </FormGroup>

        <FormGroup
          disabled={!(+invoiceData?.numberReminds > 2 && invoiceData?.l2_call)}
          height={"65px"}
          style={{ marginBottom: "15px" }}
        >
          <FormGroupInf height={"65px"} active={invoiceData?.l3_Rar}>
            <FormLabel
              style={{ fontSize: "23px", margin: "unset" }}
              color={"#505050"}
              htmlFor="number"
            >
              3{" "}
            </FormLabel>
          </FormGroupInf>
          <FormGroupDetail height={"65px"}>
            <FormGroupDetailChild active={invoiceData?.l3_Rar}>
              <ContentChild>
                <FormLabel color={"#505050"} htmlFor="number">
                  31-60 {t("global:exceedingDays", "jours de dépassement")}
                </FormLabel>
                <FormLabel color={"#505050"} htmlFor="number">
                  {t("global:RARReminder", "Relance par courrier RAR")}{" "}
                </FormLabel>
              </ContentChild>
              <PersonIcon />
            </FormGroupDetailChild>
          </FormGroupDetail>
          <CheckboxGroup>
            <CheckboxDiv
              edge="end"
              name="l3_Rar"
              onChange={(e) => {
                setTempEvent(e);
                setShowStatusModal(!showStatusModal);
              }}
              checked={invoiceData?.l3_Rar}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleOutlineIcon />}
            />
          </CheckboxGroup>
        </FormGroup>

        <FormGroup disabled={!invoiceData?.l3_Rar} height={"65px"}>
          <FormGroupDetail height={"65px"} width={"82.7%"}>
            <FormGroupDetailChild active={invoiceData?.contentieux}>
              <ContentChild style={{ width: "92%" }}>
                <FormLabel color={"#505050"} htmlFor="number">
                  {t("global:litigation", "Contentieux")}{" "}
                </FormLabel>
              </ContentChild>
              <PersonIcon />
            </FormGroupDetailChild>
          </FormGroupDetail>
          <CheckboxGroup>
            <CheckboxDiv
              edge="end"
              name="contentieux"
              onChange={(e) => {
                setTempEvent(e);
                setShowStatusModal(!showStatusModal);
              }}
              //  onChange={handleToggle(value)}
              checked={invoiceData?.contentieux}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleOutlineIcon />}
            />
          </CheckboxGroup>
        </FormGroup>
      </FFormWrap>
      {showStatusModal ? (
        <StatusModal
          show={showStatusModal}
          modalClosed={() => setShowStatusModal(false)}
          statusVal={null}
          statusList={null}
          confirm={handleToggle}
        />
      ) : null}
      {showUsersModal ? (
        <UsersModal
          show={showUsersModal}
          modalClosed={() => setShowUsersModal(false)}
          confirm={() => setShowUsersModal(false)}
          usersList={usersList}
        />
      ) : null}
    </FContainer>
  );
}

const mapStateToProps = (state) => ({
  userType: state.auth.userType,
  tabSideActive: state.invoiceDetail.tabSideActive,
  invoiceData: state.invoiceDetail.invoiceData,
});
const mapDispatchToProps = (dispatch) => ({
  getInvoiceData: (invoiceUid) => dispatch(actions.getInvoiceData(invoiceUid)),
  setTabSideActive: (tabSideActive) =>
    dispatch(actions.setTabSideActive(tabSideActive)),
  setInvoiceData: (invoiceData) =>
    dispatch(actions.invoiceDataSuccess(invoiceData)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Recouvrement);
