import React, { useEffect, useState } from 'react';
import { Notyf } from 'notyf';
import Axios from '../../axios-proas'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { formatDate, formatNumber, getNotyfObject } from '../../shared/utility';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, CancelBtnOutlineAction } from '../../styles/Common';
import { IDBody, IDContainer, IDHeader, IDHeaderActions, IDHeaderContainer, IDHeaderTitle, IDHeaderInvoiceDate, IDHeaderWrapper, IDInvoiceDate, IDInvoiceDateLabel, IDInvoiceDateValue, IDProgressLineWrapper } from './SupplierRequestDetail.styled';
import IDProgressLine from '../../components/SupplierRequestDetail/IDProgressLine/IDProgressLine';
import InvoiceView from '../../components/SupplierRequestDetail/InvoiceBody/InvoiceMain/InvoiceView/InvoiceView';
import InvoiceSide from '../../components/SupplierRequestDetail/InvoiceBody/InvoiceSide/InvoiceSide';
import ActionsModal from '../../components/SupplierRequestDetail/ActionsModal/ActionsModal';
import { convertFileToBase64 } from "../../shared/utility"

function SupplierRequestDetail(props) {
    const {
        loadingData,
        getInvoiceDataByToken,
        invoiceData,
        invoiceError,
        getInvoiceData,
        resetAdmToInitialState
    } = props
    const theme = useTheme()
    let { uid, token } = useParams();
    const notyf = getNotyfObject();
    const { t } = useTranslation();
    let location = useLocation();
    let navigate = useNavigate()
    const [entity, setEntity] = useState(null);
    const [lastEvent, setLastEvent] = useState(null);
    const [owners, setOwners] = useState(null);
    const [entityDocuments, setEntityDocuments] = useState(null);
    const [activeVisuDocument, setActiveVisuDocument] = useState(0)
    const [entityDocument, setEntityDocument] = useState(null);
    const [navTabsList, setNavTabsList] = useState([])
    const [showActionsModal, setShowActionsModal] = useState(false)
    const [eventUpdate, setEventUpdate] = useState(false)
    const [renderList, setRenderList] = useState()
    const [formErrors, setFormErrors] = useState(false)
    const [formErrorsList, setFormErrorsList] = useState([])

    const [newSupplierAccountEvent, setNewSupplierAccountEvent] = useState(null)

    const typeInvoiceToNavListe = (xml, edi, pdf, chorus) => {
        var list = [];
        // if(pdf!==null)
        list = ([...list,
        { label: 'PDF', value: 'pdf' }
        ])
        setNavTabsList(list)
    }


    // useEffect(() => {
    //     typeInvoiceToNavListe(invoiceData?.xmlFile, invoiceData?.ediFile ,invoiceData?.docFile,invoiceData?.chorusFile)
    // }, [invoiceData?.xmlFile, invoiceData?.ediFile ,invoiceData?.docFile,invoiceData?.chorusFile])

    useEffect(() => {
        setFormErrors(false)
        setFormErrorsList([])
        loadContent()
    }, [])

    const newDocumentHandler = (e) => {
        console.log("je sui la")
        let file = e.target.files[0]
        let fileB64 = "";
        if (file) {
            let tempEntityDocuments = entityDocuments
            let existFile = tempEntityDocuments?.find(row => (row.document.substr(0, (row.document.length - 4)) === (file.name.substr(0, (file.name.length - 4)) + "_" + row.parent)))
            console.log(tempEntityDocuments)
            console.log(file.name.substr(0, (file.name.length - 4)))
            console.log(existFile)
            if (existFile) {
                notyf.error("Un fichier avec le même nom a déjà été ajouté")
            } else {

                convertFileToBase64(file).then(result => {
                    fileB64 = result;
                    if (fileB64 != "" && fileB64 != "data:") {
                        let data = {
                            document: file.name,
                            expirationDate: "",
                            pdfFile: fileB64,
                            type: "",
                            new: true
                        }
                        addDocumentHandler(data)
                    } else
                        notyf.error(t("Document endommagé"))
                }).catch(err => {
                    notyf.error(t("global:error", t('global:error', "Une erreur s'est produite")))
                })
            }
        }
    }

    const addDocumentHandler = (data) => {
        Axios.put('/entity/' + entity?.uid + '/addSupplierRequestDocument',
            data
        ).then(response => {
            loadContent()
            // notyf.success("Document Ajouté")
            // setEventUpdate(!eventUpdate)
        }).catch(err => {
            notyf.error(t('global:error', "Une erreur s'est produite"))
        })
    }
    const deleteDocumentHandler = (uid) => {
        Axios.put('/entity/' + uid + '/deleteSupplierRequestDocument').then(response => {
            loadContent()
            notyf.success(t("global:success", "Modifications enregistrées"))
            // setEventUpdate(!eventUpdate)
        }).catch(err => {
            notyf.error(t('global:error', "Une erreur s'est produite"))
        })
    }


    const loadContent = () => {

        let list = [{ label: 'PDF', value: 'pdf' }]
        setNavTabsList(list)
        Axios.get('/entity/' + uid + '/supplierRequestDetail').then(response => {
            let entityOwners = response?.data?.entity?.owners
            let ownersdb = response?.data?.owners
            setEntityDocuments(response?.data?.entityDocuments)
            console.log(response?.data?.entityDocuments)
            setEntityDocument(response?.data?.entityDocuments[activeVisuDocument])
            ownersdb.map(owner => {
                (entityOwners?.find(o => o.uid === owner.uid))
                    ? owner.selected = 1
                    : owner.selected = 0
            })
            setEntity({
                ...response?.data?.entity,
                owners: ownersdb,
                documents: response?.data?.entityDocuments
            })
            setOwners(ownersdb)
            setLastEvent(response?.data?.lastEvent)

        }).catch(err => {
            notyf.error(t('global:error', "Une erreur s'est produite"))
        })
    }

    const editStatus = (status, comment = null, reason = null) => {
        Axios.put('/invoice//updateStatus',
            {
                "InvoiceId": uid,
                "status": status,
                reason,
                comment
            }).then(response => {
                getInvoiceData(uid)
                notyf.success(t("global:success", "Statut modifié"))
            }).catch(err => {
                notyf.error(t('global:error', "Une erreur s'est produite"))
            })
    }

    const addEventHandler = (event) => {
        Axios.put('/entity/' + uid + '/newSupplierAccountRequestEvent',
            { event }).then(response => {
                notyf.success(t("global:success", "Evénement ajouté"))
                loadContent()
                setEventUpdate(!eventUpdate)
                navigate(-1)
            }).catch(err => {
                notyf.error(t('global:error', "Une erreur s'est produite"))
            })
    }

    const updateSupplierRequest = () => {
        // checkRequestForm()
        // entity data to save
        // if (!formErrors) {
        const owners = entity?.owners.filter(owner => owner.selected == true)
        const channel = entity?.channel
        let attachments = []
        entity?.documents?.map(doc => {
            attachments.push({ uid: doc?.uid, expirationDate: doc?.expirationDate, type: doc?.type, othertypename: doc?.othertypename })
        })

        Axios.put('/entity/' + entity?.uid + '/updateSupplierRequest',
            {
                name: entity?.name,
                siret: entity?.siret,
                siren: entity?.siren,
                vat: entity?.vat,
                address: entity?.address,
                addressComplement: entity?.addressComplement,
                zipCode: entity?.zipCode,
                city: entity?.city,
                countryCode: entity?.countryCode,
                entityAdmin: entity?.login,
                telephone_number: entity?.telephone_number,
                userRef: entity?.adminContact,
                owners: owners,
                channel: channel,
                attachments: attachments
            }
        ).then(response => {
            loadContent()
            notyf.success(t("global:success", "Modifications enregistrées"))
        }).catch(err => {
            notyf.error(t('global:error', "Une erreur s'est produite"))
        })
        // }
    }

    const checkRequestForm = () => {
        const formFields = entity
        let errors = []
        // if( ("INSURANCE, URSSAF").includes( formFields.) ) errors.push("Canal d'échange")
        if (formFields.channel.length == 0) errors.push("Canal d'échange")
        if (formFields.documents.filter(doc => doc.type == "IBAN").length > 1 ||
            formFields.documents.filter(doc => doc.type == "URSSAF").length > 1 ||
            formFields.documents.filter(doc => doc.type == "INSURANCE").length > 1 ||
            formFields.documents.filter(doc => doc.type == "FOREIGN").length > 1)
            errors.push("Type document en double")
        if (formFields.owners.filter(owner => (owner?.selected == false)).length == formFields.owners.length) errors.push("Entité facturée")
        if (formFields.documents.filter(doc => (doc?.type == null || (doc?.type == "OTHER" && (doc?.othertypename == "" || doc?.othertypename == null)))).length > 0) errors.push("Type documents")

        if (errors.length > 0) {
            setFormErrors(true)
            setFormErrorsList(errors)
        } else {
            setFormErrors(false)
            setFormErrorsList([])
        }
    }

    let mandatJsx = null;
    if (loadingData) mandatJsx = (<Spinner />)
    else if (entity)
        mandatJsx = (
            <>
                <IDHeaderWrapper>
                    <IDHeaderContainer>
                        <IDHeader>
                            <div>
                                <IDHeaderTitle>
                                    {t("supplier:name", "N° Référencement")}  : REF{entity?.id}
                                </IDHeaderTitle>
                                <IDInvoiceDate>
                                    <IDInvoiceDateLabel>{t("supplier:referenceDate", "Date de demande")} : {formatDate(+entity?.creationDate)}</IDInvoiceDateLabel>
                                    {/* <IDInvoiceDateValue>{formatDate(+invoiceData?.issuingDate)}</IDInvoiceDateValue> */}
                                </IDInvoiceDate>
                            </div>
                            <IDHeaderActions>
                                <div>
                                    {/* {
                                    formErrors &&
                                        formErrorsList?.map(error => {
                                            return <li>{error}</li>
                                        })
                                } */}
                                </div>
                                <PrimaryBtnOutlineAction
                                    color={theme.colors.danger}
                                    border={theme.colors.danger}
                                    onClick={() => setShowActionsModal(true)}
                                >
                                    Actions
                                </PrimaryBtnOutlineAction>
                                <PrimaryBtnOutlineAction
                                    color={theme.colors.danger}
                                    border={theme.colors.danger}
                                    onClick={() => updateSupplierRequest()}
                                >
                                    {t("global:save", "Sauvegarder")}
                                </PrimaryBtnOutlineAction>
                                <SecondaryBtnOutlineAction
                                    onClick={() => navigate(-1)}
                                >
                                    {t("global:back", "Retour")}
                                </SecondaryBtnOutlineAction>
                            </IDHeaderActions>
                        </IDHeader>
                        <IDProgressLineWrapper>
                            <IDProgressLine
                                lastEvent={lastEvent}
                                status={entity?.status}
                                editStatus={editStatus}
                                entityFilter={location?.state?.entityFilter}
                                accessToken={token}
                            />
                        </IDProgressLineWrapper>
                    </IDHeaderContainer>

                </IDHeaderWrapper>

                <IDBody accessToken={token}>
                    <InvoiceView document={entityDocument} invoiceData={invoiceData} navTabsList={navTabsList} />
                    {
                        !token ?
                            <InvoiceSide
                                deleteDocumentHandler={deleteDocumentHandler}
                                newDocumentHandler={newDocumentHandler}
                                addEventHandler={addEventHandler}
                                entity={entity} setEntity={setEntity}
                                setEntityDocument={setEntityDocument}
                                setEntityDocuments={setEntityDocuments}
                                setActiveVisuDocument={setActiveVisuDocument}
                                activeVisuDocument={activeVisuDocument}
                                entityDocuments={entityDocuments}
                                owners={owners}
                                eventUpdate={eventUpdate}
                            />
                            : null
                    }
                </IDBody>
            </>
        )

    return (
        <>
            <IDContainer>
                {mandatJsx}
            </IDContainer>
            {
                showActionsModal &&
                <ActionsModal
                    show={showActionsModal}
                    handleClose={() => setShowActionsModal(false)}
                    addEventHandler={addEventHandler}
                    entityDocuments={entityDocuments}
                />
            }
        </>
    )
}
const mapStateToProps = (state) => ({
    loadingData: state.invoiceDetail.loadingData,
    invoiceData: state.invoiceDetail.invoiceData,
    invoiceError: state.invoiceDetail.invoiceError,
    // role: state.auth.role,
    // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
    // creatorName : state.mandat?.admData?.creatorName
})
const mapDispatchToProps = dispatch => ({
    getInvoiceData: (invoiceUid) => dispatch(actions.getInvoiceData(invoiceUid)),
    getInvoiceDataByToken: (invoiceToken) => dispatch(actions.getInvoiceDataByToken(invoiceToken)),
    // setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
    // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
    resetAdmToInitialState: () => dispatch(actions.resetAdmToInitialState())
})
export default connect(mapStateToProps, mapDispatchToProps)(SupplierRequestDetail)
