import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PdfViewer from "./PdfViewer/PdfViewer";
import { useParams } from "react-router-dom";
import { getNotyfObject } from "../../../shared/utility";
import Spinner from 'react-bootstrap/Spinner'
import { IVContainer, EntHeaderWrapper, XmlDiv, IVWrapper, IVContainer2, IVDownloadContainer, IVDownloadIcon } from "./DocumentationView.styled";
import Axios from "../../../axios-proas";
import { useNavigate, useLocation } from 'react-router-dom';
import DownloadIcon from "@mui/icons-material/Download";
import ConfirmDelete from './DeleteDocumentationModal/ConfirmDelete';
import { useTheme } from "styled-components";
import { useTranslation } from 'react-i18next';
import { NDContainer, DocVisu, NoContent, NDCol6, NDCol62, NDrow, ISContainer, EIFormGroup, EIFormLabel, Input, EIFormGroupChecked, ToggleDiv, InputCheckbox, EntitiesContainer } from '../../../containers/NewDocumentation/NewDocumentation.styled'
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle, BtnOutlineAction, BtnAction } from '../../../styles/Common'
import { Link } from 'react-router-dom';
import { TableAction, TableActionLink, TAContainer, SelectFilter } from '../../../styles/Table.styled.js';
import EastIcon from '@mui/icons-material/East';
import NorthIcon from '@mui/icons-material/North';
import FileUploaderBtn from '../../../components/UI/FileUploaderBtn/FileUploaderBtn';
import EntitiesComponent from '../../../components/NewDocumentation/EntitiesComponent/EntitiesComponent';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import MandatoryMarker from '../../../components/UI/MandatoryMarker';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { CTooltip } from '../../../components/UI/CTooltip/CTooltip';

function DocumentationView({ userLevel, userType, userProfile }) {
  const [tab, setTab] = useState();
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfFileName, setPdfFileName] = useState(null);
  const [documentation, setDocumentation] = useState()
  const [listEntityUid, setListEntityUid] = useState()
  const [listClientsUid, setListClientsUid] = useState()
  const [selectedOption, setSelectedOption] = useState()
  const theme = useTheme();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false)
  const [categoryName, setCategoryName] = useState("")
  const [categoriesUpdates, setCategoriesUpdates] = useState(false)
  const [visible, setVisible] = useState(false)
  let { uid, token } = useParams();
  const [showError, setShowError] = useState(false)
  const notyf = getNotyfObject();
  const [ifClient, setIfClient] = useState(false)
  const [ifSupplier, setIfSupplier] = useState(false)
  const [showNewCategoryInputField, setShowNewCategoryInputField] = useState(false);
  const customTheme = {
    attributeKeyColor: "#FF0000",
    attributeValueColor: "#000FF",
    overflowBreak: true,
  };
  const uploader = useRef(null);
  const entRef = useRef();

  const navigate = useNavigate();
  let location = useLocation()

  const changeTab = (tab) => {
    setTab(tab);
  };

  const downloadClickHandler = (tab) => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // IE workaround
      if (tab == "xml") {
        var byteCharacters = atob(xmlFile);
      } else {
        var byteCharacters = atob(ediFile);
      }

      let byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let blob = new Blob([byteArray], { type: "text/plain" });
      window.navigator.msSaveOrOpenBlob(
        blob,
        tab == "xml" ? xmlFileName : ediFileName || "facture.xml"
      );
    } else {
      // much easier if not IE
      //________Direct Download_____
      var blob = null;
      switch (tab) {
        case "xml":
          blob = new Blob([xmlFile], { type: "text/plain" });
          break;
        case "edi":
          blob = new Blob([ediFile], { type: "" });
          break;
        case "chorus":
          blob = new Blob([chorusFile], { type: "text/plain" });
          break;
        default:
          break;
      }

      //const downloadUrl = URL.createObjectURL(blob)
      let a = document.createElement("a");
      //a.setAttribute('href', window.URL.createObjectURL(blob));
      a.href = window.URL.createObjectURL(blob);
      a.download =
        tab == "xml"
          ? xmlFileName
          : tab == "edi"
            ? ediFileName
            : chorusFileName || "facture.xml";
      a.dataset.downloadurl = ["", a.download, a.href].join(":");
      document.body.appendChild(a);
      a.click();

      //__________Visualize In The Browser _____
      // const blob = dataURItoBlob(data);
      // const url = URL.createObjectURL(blob);

      // // to open the PDF in a new window
      // window.open(url, '_blank');
    }
  };

  console.log(location)

  const handleDocumentationDownload = (uid, entity, typeEntity) => {
    Axios.get('/documentation/' + uid + '/download',
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          entity: entity,
          typeEntity: typeEntity
        }
      })
      .then(response => {
        if (response.status === 200) {
          let name = response.data.docName
          setPdfFile(response.data?.pdfFile)
          setPdfFileName(name + ".pdf")
          setDocumentation(response.data)
          setVisible(response.data?.visible)
          var listUid = response.data?.suppliers?.map(function (numbe) {
            return numbe.uid
          })
          setListEntityUid(listUid)
          var lisClienttUid = response.data?.clients?.map(function (numbe) {
            return numbe.uid
          })
          setListClientsUid(lisClienttUid)

        } else {
          notyf.error("document non trouvé");
        }
      }).catch(err => {
        notyf.error('Une erreur s\'est produite !')
      })
  }

  useEffect(() => {
    handleDocumentationDownload(location.state?.uid, location.state?.entity, location.state?.type)
  }, [location])



  useEffect(() => {
    entRef?.current?.scrollTo({
      bottom: 0,
      left: 0
    })
  }, [])

  const updateVisible = () => {

    if (visible) {
      setVisible(false)
      setSelectedOption(null)
      let docTempp = {
        ...documentation,
        visible: 0,
        supplier: 0,
        client: 0,
        allUsers: 0
      }
      setDocumentation(docTempp)
    } else {
      setVisible(true)
      let docTemp = {
        ...documentation,
        visible: 1,

      }
      setDocumentation(docTemp)
    }
  }


  const inputChangeHandler = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    console.log(e.target)

    let docTemp = {
      ...documentation,
      [name]: value
    }
    setDocumentation(docTemp)
  }

  const entitiesRadioChangeHandler = (name, value) => {
    let docTemp = {
      ...documentation,
      [name]: +value
    }
    setDocumentation(docTemp)
  }

  const handleFileChange = (e) => {
    console.log(e)
    console.log(e?.target.files[0])
    if (e?.target?.files[0]?.type == "application/pdf") {
      if (e?.target.files && e?.target.files[0]) {
        setUrl(URL.createObjectURL(e.target.files[0]))
        setFile(e.target.files[0].name)
      }
      console.log(e?.target.files[0])
      let tmpDoc = {
        ...documentation,
        formData: e?.target.files[0],
        fileName: e?.target.files[0].name,
        fileType: e?.target.files[0].type
      }
      setDocumentation(tmpDoc)
      console.log(tmpDoc)
    } else {
      setUrl("")
      notyf.error(t("documentDetails:docFormat"));
    }
  }

  const addDocumentationclickHandler = () => {
    const formData = new FormData();
    formData.append('file', documentation.formData);

    let tmpDoc = {
      ...documentation,

    }
    Axios.put("documentation//documentation", tmpDoc).then(response => {
      if (response?.data.success == true) {
        notyf.success(t("global:success", "Documentation sauvegardée avec succès"));
        handleDocumentationDownload(location.state?.uid, location.state?.entity, location.state?.type)
      } else {
        notyf.error("Échec de la sauvegarde de la documentation");
      }

    })


  }

  const deleteSupplierClickHandler = (supplier) => {
    let docTemp = { ...documentation }
    if (documentation?.id && !supplier?.new) {
      docTemp = {
        ...docTemp,
        suppliersToDelete: docTemp?.suppliersToDelete ? [...docTemp?.suppliersToDelete, supplier.uid] : [supplier.uid]
      }
    }
    let docSuppliers = [...documentation?.suppliers]
    let supplierIndex = documentation?.suppliers.findIndex(ent => ent.uid === supplier.uid);
    if (supplierIndex >= 0) {
      docSuppliers.splice(supplierIndex, 1)
    }
    setDocumentation({
      ...docTemp,
      suppliers: docSuppliers
    })
  }
  const suppliersChangeHandler = (suppliersList) => {
    console.log(suppliersList)
    let docTemp = {
      ...documentation,
      suppliers: suppliersList,
      clients: null,
    }
    setDocumentation(docTemp)

    setIfClient(false);
  }

  const deleteClientClickHandler = (client) => {
    let docTemp = { ...documentation }
    if (documentation?.id && !client?.new) {
      console.log(docTemp)
      docTemp = {
        ...docTemp,
        clientsToDelete: docTemp?.clientsToDelete ? [...docTemp?.clientsToDelete, client.uid] : [client.uid]
      }
      console.log(docTemp)
    }
    let docClients = [...documentation?.clients]
    let clientIndex = documentation?.clients.findIndex(ent => ent.uid === client.uid);
    if (clientIndex >= 0) {
      docClients.splice(clientIndex, 1)
    }
    setDocumentation({
      ...docTemp,
      clients: docClients
    })
  }
  const clientsChangeHandler = (clientsList) => {
    let docTemp = {
      ...documentation,
      clients: clientsList,
      suppliers: null
    }
    setDocumentation(docTemp)
    setIfSupplier(false);
  }


  let documentLien = null;
  if (documentation?.documentationType == "lien") {
    documentLien = <div className="form-group">
      <a href={documentation?.lien} target="_blank">Visiter le lien</a>
    </div>
  }


  let admView = null;

  if (!pdfFile) admView = <spinner />;
  else {
    if (pdfFile)
      admView = (
        <>
          <PdfViewer pdfFile={pdfFile} pdfFileName={pdfFileName} />
        </>
      );
    else
      admView = (
        <>
          <div>
            {" "}

            <h5> </h5>//Cette facture ne contient pas de PDF

          </div>
        </>
      );
  }

  const deleteDocumentationHandler = (documantationRef) => {
    Axios.put('/documentation/' + documantationRef + '/deleteDocumentation').then(response => {
      if (response?.data?.success) {
        navigate(-1)
      } else {
        // e.preventDefault()
        notyf.error("Une erreur s\'est produite !")
      }
    })
  }


  const chekedDocumentation = () => {

    if ((documentation?.docName && documentation.docName != "") && ((documentation?.fileName && documentation.fileName != ""))) {
      return false
    } else {
      return true
    }

  }

  const addCategoriesHandle = (categoryName) => {
    Axios.put('/documentation//addCategory', { categoryName: categoryName }).then(response => {
      if (response?.data?.success) {
        setCategoriesUpdates(!categoriesUpdates)
      } else if (response?.data?.alreadyExist) {
        notyf.error('Cette catégorie existe déjà!')
      } else {
        notyf.error("Une erreur s\'est produite !")
      }
    })
  }

  const addCategorie = (category) => {
    if (category !== "") {
      addCategoriesHandle(category)
    } else
      setShowError(true)
  }
  const inputChangeHandler2 = (e) => {

    let value = e.target.value;
    setSelectedOption(e.target.value)
    switch (value) {
      case "visible":
        let docTemps = {
          ...documentation,
          entitiesType: 'FOURNISSEURSETCLIENTS',


        }
        setDocumentation(docTemps)

        break;
      case "supplier":
        let docTemp = {
          ...documentation,
          entitiesType: 'FOURNISSEUR',

        }
        setDocumentation(docTemp)

        break;
      case "client":
        let docTempc = {
          ...documentation,
          entitiesType: 'CLIENT',

        }
        setDocumentation(docTempc)
        break;
      case "edi":
        let docTempp = {
          ...documentation,
          [name]: value
        }
        setDocumentation(docTempp)
        break;
      default:
        break;
    }
  }
  console.log(userType)

  return (
    <div>
      <Header>
        <div style={{ display: 'block' }}>
          <HeaderLocation>Documentation</HeaderLocation>
          {
            userType == "owner" ?
              <HeaderTitle>Edition de document</HeaderTitle> :
              <HeaderTitle>{documentation?.docName}</HeaderTitle>
          }
        </div>

        <HeaderActions>
          {<>
            {
              userType == "owner" &&
              <>
                <BtnAction
                  color="#2174B9"
                  border="#2174B9"

                  onClick={() => setShowModal(true)}
                >
                  <span>Supprimer</span>
                </BtnAction>
                <BtnAction
                  color="#2174B9"
                  border="#2174B9"
                  disabled={chekedDocumentation()}
                  onClick={() => addDocumentationclickHandler()}
                >
                  <span>Sauvegarder les modifications</span>
                </BtnAction>
              </>

            }

            <BtnAction
              border={"#D9E1E7"}
              color={"#2174B9"}
              onClick={() => navigate(-1)}
            >
              Retour
            </BtnAction>

          </>





          }
        </HeaderActions>
      </Header>
      {
        showModal ? (
          <ConfirmDelete handleClose={() => setShowModal(false)}
            show={showModal}
            item={documentation?.uid}
            deleteDocumentationHandler={deleteDocumentationHandler}
          />
        ) : null
      }
      <NDrow>
        <NDCol6>
          <IVContainer >{pdfFile ? admView : documentLien}</IVContainer>
        </NDCol6>
        <NDCol62 >
          {/* <IVContainer2>   
                            <ISContainer>
                                <EIFormGroup style={{justifyContent: 'space-between'}}>
                                    <EIFormLabel for="first_name">Nom du document* :</EIFormLabel>
                                    <Input 
                                       placeHolder={t('documentDetails:name')} 
                                        id="first_name"
                                        name="docName" 
                                        type="text"
                                        color="#06152B" 
                                        autoComplete="off"
                                        value={documentation?.docName || ""}
                                        onChange={(e) => inputChangeHandler(e)} 
                                        disabled={userLevel=="admin" && userProfile=="ADMIN"?false:true}
                                    />
                                </EIFormGroup>
                              
                                <EIFormGroup style={{justifyContent: 'space-between'}}>
                                    <EIFormLabel  for="visible" className="accountRequest__canalItemLabel">Visible aux fournisseurs</EIFormLabel>
                                    <ToggleDiv>
                                        {
                                        documentation?.visible=="1"? (
                                            <ToggleOnIcon 
                                                style={{marginLeft: '0.25rem', color: userProfile=="ADMIN" ? theme.colors.success : "#b8edb7", cursor: 'pointer'}} 
                                                fontSize="small"
                                                name="edi"
                                                id="visible"
                                                onClick={() => updateVisible()}
                                            />   
                                        ) : 
                                            <ToggleOffIcon 
                                                style={{marginLeft: '0.25rem', color: userProfile=="ADMIN" ? "black" : "grey", cursor: 'pointer'}} 
                                                fontSize="small"
                                                name="edi"
                                                onClick={() => updateVisible()}
                                            />
                                    
                                        }
                                  </ToggleDiv>
                               </EIFormGroup>
                            
                            
                             </ISContainer>
                                
                                
                            </IVContainer2>  */}
          <ISContainer>



            <EIFormGroup>

              <EIFormLabel for="first_name">Nom<MandatoryMarker /></EIFormLabel>
              <Input
                placeHolder={t('documentDetails:name')}
                id="first_name"
                name="docName"
                type="text"
                color="#06152B"
                autoComplete="off"
                value={documentation?.docName || ""}
                onChange={(e) => inputChangeHandler(e)}
              />
            </EIFormGroup>
            {userType == "owner" &&
              <>
                <EIFormGroup>
                  <EIFormLabel for="visible" className="accountRequest__canalItemLabel">Visible</EIFormLabel>
                  <ToggleDiv>
                    {
                      visible ? (
                        <ToggleOnIcon
                          style={{ marginLeft: '0.25rem', color: theme.colors.success, cursor: 'pointer' }}
                          fontSize="small"
                          name="edi"
                          id="visible"
                          onClick={() => updateVisible()}

                        />

                      ) :
                        <ToggleOffIcon
                          style={{ marginLeft: '0.25rem', cursor: 'pointer' }}
                          fontSize="small"
                          name="edi"

                          onClick={() => updateVisible()}
                        />

                    }
                  </ToggleDiv>
                </EIFormGroup>
                <EIFormLabel for="first_name">Visible par<MandatoryMarker /></EIFormLabel>
                <EIFormGroup >
                  <EIFormGroupChecked >
                    <InputCheckbox type="radio" style={{ marginRight: '10px' }}
                      id="tous"
                      value="visible"
                      name="web"
                      onChange={visible && inputChangeHandler2}
                      checked={documentation?.entitiesType == "FOURNISSEURSETCLIENTS"}

                    />
                    <EIFormLabel for="tous" class="form-check-label" >Tous</EIFormLabel>
                  </EIFormGroupChecked>

                  <EIFormGroupChecked >
                    <InputCheckbox type="radio" style={{ marginRight: '10px' }}
                      id="client"
                      name="web"
                      value="client"
                      onChange={visible && inputChangeHandler2}
                      checked={documentation?.entitiesType == "CLIENT"}

                    />
                    <EIFormLabel class="form-check-label" for="client">
                      Client
                    </EIFormLabel>
                  </EIFormGroupChecked>




                  <EIFormGroupChecked >
                    <InputCheckbox type="radio" style={{ marginRight: '10px' }}
                      id="fournisseur"
                      name="web"
                      value="supplier"
                      checked={documentation?.entitiesType == "FOURNISSEUR"}
                      onChange={visible && inputChangeHandler2}

                    />
                    <EIFormLabel class="form-check-label" for="fournisseur">
                      Fournisseurs
                    </EIFormLabel>
                  </EIFormGroupChecked>


                </EIFormGroup >
              </>
            }
            {
              userType == "owner" && documentation?.entitiesType == 'CLIENT' ?
                <EntitiesContainer ref={entRef} >
                  <EntitiesComponent
                    show={true}
                    label={"Clients"}
                    radioName={"accessClient"}
                    radioValue={documentation?.accessClient}
                    onChangeRadio={entitiesRadioChangeHandler}
                    entities={documentation?.clients}
                    deleteEntity={deleteClientClickHandler}
                    addEntity={clientsChangeHandler}

                    entitiesType={"client"}
                    userType={documentation?.type}
                    userUid={false}
                  /></EntitiesContainer> : null
            }



            {
              userType == "owner" && documentation?.entitiesType == 'FOURNISSEUR' ?
                <EntitiesContainer ref={entRef}>
                  <EntitiesComponent
                    show={true}
                    label={"Fournisseurs"}
                    radioName={"accessSupplier"}
                    radioValue={documentation?.accessSupplier}
                    radioGender={"M"}
                    onChangeRadio={entitiesRadioChangeHandler}
                    entities={documentation?.suppliers}

                    deleteEntity={deleteSupplierClickHandler}
                    addEntity={suppliersChangeHandler}
                    entitiesType={"supplier"}
                    userType={documentation?.type}
                    userUid={false}
                  />  </EntitiesContainer> : null

            }
            {/* {documentation?.fileName &&
                                 <EIFormGroup>
                                       <EIFormLabel for="first_name">{t('Fichier')}</EIFormLabel>
                                         <Input 
                                             placeHolder={t('documentDetails:name')} 
                                             name="docName" 
                                             color="#06152B"
                                             type="text" 
                                             autoComplete="off"
                                             value={documentation?.fileName || ""}
                                         />
                                 </EIFormGroup>
                             } */}



          </ISContainer>
        </NDCol62>

      </NDrow>
    </div>
  );
}


const mapStateToProps = (state) => ({

  userRole: state.auth.role,
  userType: state.auth.userType,
  userLevel: state.auth.userLevel,
  userProfile: state.auth.profile,
  userUid: state.auth.userUid
})




const mapDispatchToProps = (dispatch) => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentationView);
