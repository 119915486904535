import React, { useCallback, useEffect, useState } from 'react';

import TablePagination from '../../components/UI/TablePagination/TablePagination';
import TableActions from '../../components/ClientsTable/TableActions/TableActions';
import TableHead from '../../components/ClientsTable/TableHead/TableHead';
import TableBody from '../../components/ClientsTable/TableBody/TableBody';
import TableFilters from '../../components/ClientsTable/TableFilters/TableFilters';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import { NoContent, SpinnerContainer, Table, TBody } from '../../styles/Table.styled';
import { RFContainer, RFBody, RFTableContainer } from './ReferentielClient.Styled';
import { Spinner } from 'react-bootstrap';

function ReferentielClient(props) {
    const {
        loading,
        clientsData,
        count,
        currentPage,
        pageSize,
        getClientsPage,
        setClientsPage,
        setClientsPageSize,
        sortQuery,
        reverse,
        filtersQuery,
        showFilters,
        userRole,
        updateShowFilters,
        setClientsFilters,
        setSortQuery,
        setReverse
    } = props;
    const { t } = useTranslation();
    const { state } = useLocation();
    const userType = localStorage.getItem('type');
    const [density, setDensity] = useState("2.25rem")
    const [columns, setColumns] = useState([
        { title: t("refClient:client"), field: 'code', show: true, alignSelf: "left" },
        { title: t("refClient:clientName"), field: 'name', show: true, alignSelf: "left" },
        { title: t("refClient:codePostal"), field: 'zipCode', show: true, alignSelf: "left" },
        { title: t("refClient:city"), field: 'city', show: true, alignSelf: "left" },
        { title: t("refClient:channel", "Canal"), field: 'channel', show: true, alignSelf: "left" },
        { title: t("refClient:creationDate"), field: 'creationDate', show: true, alignSelf: "center" },
        { title: t("refClient:status", "Statut"), field: 'status', show: true, alignSelf: "center" }
        // {title: t("refClient:visualiser"), field: 'visu', show:true, alignSelf:"left"}
    ])

    useEffect(() => {
        let filtersTemp = {
            ...filtersQuery
        }
        let filtersProps = null;
        if (state?.filtersProps) {
            filtersProps = state.filtersProps;
            filtersTemp = {
                ...filtersQuery,
                ...filtersProps
            }
            setClientsFilters(filtersTemp);
        }
        getClientsPage(currentPage, filtersTemp, sortQuery, reverse, pageSize)

        return () => {
            resetFilters();
        }
    }, [state, currentPage, pageSize])

    const isEmptyFilters = () => {
        if (filtersQuery === null)
            return true;
        let filtersList = columns.map(column => column.field)
        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (!showFilters && !isEmpty) {
            resetFilters()
        }
    }, [showFilters]);

    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue = reverse[field] ? reverse[field] : false
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getClientsPage(currentPage, filtersQuery, field, newReverse, pageSize)
    }

    const handlePageChange = useCallback((newPage) => {
        setClientsPage(newPage);
    }, [currentPage]);

    const handleApplyFiltering = useCallback((filters) => {
        getClientsPage(1, filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery]);

    const resetFilters = () => {
        setClientsFilters(null);
        setClientsPage(1)
        getClientsPage(1, null, sortQuery, reverse, pageSize)
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
    ) : <NoContent>
        <span>{t("clientList:noClients")}</span>
    </NoContent>;

    if (!loading && clientsData?.length > 0) {
        tSpinner = null;
        tBody = (<TableBody columns={columns} clientsData={clientsData} userRole={userRole} />)
    }

    return (
        <RFContainer>
            <TitleHelmet title={"Démo Portail | " + t("Clients")} />
            <Header>
                <HeaderInfos>
                    <HeaderLocation>{t("menu:client", "client")}</HeaderLocation>
                    <HeaderTitle>{t("global:reference", "Référentiel")}</HeaderTitle>
                </HeaderInfos>
                <HeaderActions>
                    <TableActions
                        resetFilters={resetFilters}
                        showFilters={showFilters}
                        setShowFilters={updateShowFilters}
                        userType={userType}
                    />
                </HeaderActions>
            </Header>
            <RFBody>
                <RFTableContainer>
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setClientsPageSize}
                    />
                    <Table>
                        <TableHead
                            columns={columns}
                            reverse={reverse}
                            columnClickHandler={columnClickHandler}
                            userRole={userRole}
                        />
                        <TBody height={density}>
                            <TableFilters
                                columns={columns}
                                showFilters={showFilters}
                                clientsFilters={filtersQuery}
                                filtersChange={setClientsFilters}
                                applyFilters={handleApplyFiltering}
                            />
                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner}
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setClientsPageSize}
                    />
                </RFTableContainer>
            </RFBody>
        </RFContainer>
    )
}

const mapStateToProps = (state) => ({
    loading: state.clients.loading,
    clientsData: state.clients.data,
    count: state.clients.count,
    currentPage: state.clients.currentPage,
    pageSize: state.clients.pageSize,
    sortQuery: state.clients.sortQuery,
    reverse: state.clients.reverse,
    filtersQuery: state.clients.filtersQuery,
    showFilters: state.clients.showFilters,
    userRole: state.auth.role
})
const mapDispatchToProps = dispatch => ({
    getClientsPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.clientsGetPage(page, filters, sort, reverse, pageSize)),
    setClientsPage: (page) => dispatch(actions.clientsSetPage(page)),
    setClientsPageSize: (pageSize) => dispatch(actions.clientsSetPageSize(pageSize)),
    setSortQuery: (sortQ) => dispatch(actions.clientsSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.clientsSetReverseSort(reverseS)),
    setClientsFilters: (filters) => dispatch(actions.clientsSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.clientsUpdateShowFilters(show)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ReferentielClient)
