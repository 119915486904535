import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import TimeLine from './Timeline/TimeLine';
import ModificationsChat from './ModificationsChat/ModificationsChat';
import { getNotyfObject, formatDate, specFormatDate } from '../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { FormInput } from '../../../../styles/Common'
import { NavTabsLi, NavTabsLink, NavTabsUl } from '../../../../containers/Entity/Entity.styled';
import { useParams, useLocation } from 'react-router-dom';
import { ISContainer, ISNavContainer, DRNavContainer, ISNavContent, ISDivision, ISDocumentContainer, DPContainer } from './InvoiceSide.styled';
import Fields from './Fields/Fields';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import frLocale from "date-fns/locale/fr";
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import TextField from '@mui/material/TextField'
import DocumentList from './DocumentList/DocumentList'
import FileUploaderBtn from './DocumentList/FileUploaderBtn/FileUploaderBtn'
import ConfirmationModal from '../../../UI/ConfirmationModal/ConfirmationModal';

function InvoiceSide(props) {
    const {
        newDocumentHandler,
        entityDocuments,
        setEntityDocument,
        setEntityDocuments,
        setEntity,
        entity,
        addEventHandler,
        eventUpdate,
        deleteDocumentHandler,
        setActiveVisuDocument,
        activeVisuDocument,
        tabSideActive,
        setTabSideActive,
        supplierRequestData,
        setSupplierRequestData,
        userType
    } = props
    let notyf = getNotyfObject();
    const { t } = useTranslation();
    const uploader = useRef(null);
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const [tempDocumentToDelete, setTempDocumentToDelete] = useState()
    const documentTypeList = {
        "IBAN": t("supplier:iban", "Coordonnées bancaires"),
        "FOREIGN": t("supplier:foreign", "Liste des salariés étrangers"),
        "KBIS": t("supplier:kbis", "Justificatif immatriculation KBIS"),
        "URSSAF": t("supplier:urssaf", "Attestation sociale de vigilance"),
        "INSURANCE": t("supplier:insurance", "Attestation d'assurance")
    }

    // useEffect(() => {
    //     // if(supplierRequestData && (supplierRequestData?.status === 'ERROR' && userType === 'supplier')) {
    //     //     setTabSideActive('fields')
    //     // }
    // }, [supplierRequestData, entityDocuments])

    // console.log(specFormatDate(+entityDocuments[0]?.expirationDate))

    const documentClickHandler = (ref) => {
        setEntityDocument(entityDocuments[ref])
        setActiveVisuDocument(ref)
    }

    const inputChangeHandler = (offset, value) => {
        let tempEntityDocument = entityDocuments[offset]
        tempEntityDocument.othertypename = value
        setEntityDocuments(Object.values({ ...entityDocuments, [offset]: tempEntityDocument }))
    }

    const documentTypeChangeHandler = (offset, type) => {
        let tempEntityDocument = entityDocuments[offset]
        tempEntityDocument.type = type
        setEntityDocuments(Object.values({ ...entityDocuments, [offset]: tempEntityDocument }))
        setEntity({ ...entity, documents: Object.values({ ...entityDocuments, [offset]: tempEntityDocument }) })
    }

    const downloadDocument = (offset) => {
        const linkSource = (!entityDocuments[offset].new ? 'data:application/octet-stream;base64,' : '') + entityDocuments[offset].pdfFile;
        const link = document.createElement("a");
        link.href = linkSource;
        link.download = entityDocuments[offset].document;
        link.click();
    }

    const addDateHandler = (offset, value) => {
        console.log(value)
        let tempEntityDocument = entityDocuments[offset]
        tempEntityDocument.expirationDate = value
        setEntityDocuments(Object.values({ ...entityDocuments, [offset]: tempEntityDocument }))
    }

    const deleteDocumentModal = (offset) => {
        setConfirmationModalShow(true)
        setTempDocumentToDelete(offset)
    }

    const deleteDocument = () => {
        setConfirmationModalShow(false)
        deleteDocumentHandler(tempDocumentToDelete)
    }


    return (
        <ISDivision>
            <ISDocumentContainer>
                <div className="row" style={{ color: "#809FB8", fontSize: "0.9rem", marginBottom: "7px" }}>
                    <div className="col-md-7">Document
                        <FileUploaderBtn
                            btnLabel={t("contract:import", "Importer")}
                            handleChange={newDocumentHandler}
                            name="file"
                            icon={<AddCircleIcon style={{ fontSize: '1.2rem', marginLeft: '0.5rem' }} />}
                        />
                    </div>
                    <div className="col-md-3" style={{ textAlign: "center" }}>{t("supplier:expirationDate", "Date de péremption")}</div>
                    <div className="col-md-2"></div>
                </div>

                <div style={{ height: "143px", minHeight: "143px", overflow: "hidden auto" }}>
                    {
                        entityDocuments?.map((doc, index) => {
                            return (
                                <>
                                    <div className="row" style={{ color: "#505050", fontSize: "0.9rem" }}>
                                        <div className="col-md-7" style={{ display: "flex", justifyContent: "space-between", background: activeVisuDocument == index ? "#F1F4F9" : "white" }}>
                                            <span style={{ cursor: "pointer", color: activeVisuDocument == index ? "#2174B9" : "#505050" }} onClick={e => documentClickHandler(index)}>{documentTypeList[doc.type] || doc?.othertypename || doc.document}</span>
                                            <div >
                                                <DocumentList
                                                    name={index}
                                                    type={doc?.type}
                                                    documentTypeChangeHandler={documentTypeChangeHandler}
                                                    inputChangeHandler={inputChangeHandler}
                                                    othertypename={doc?.othertypename || ""}
                                                    entityDocuments={entityDocuments}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3" style={{ display: "flex", background: activeVisuDocument == index ? "#F1F4F9" : "white" }}>
                                            {
                                                ("URSSAF, INSURANCE").includes(doc?.type) &&
                                                <FormInput
                                                    style={{ textAlign: "center", padding: "0px 0px 0px 9px", background: activeVisuDocument == index ? "#F1F4F9" : "white" }}
                                                    className="accountRequest__formInput"
                                                    type="date"
                                                    name="datePeremption"
                                                    placeholder="JJ/MM/AAAA"
                                                    value={specFormatDate(+doc?.expirationDate)}
                                                    // placeholder={t("signin:name", "Nom")}
                                                    onChange={e => addDateHandler(index, e.target.value)}
                                                />
                                            }
                                        </div>
                                        <div className="col-md-2" style={{ display: "flex" }}>
                                            {
                                                <ArrowCircleDownIcon style={{ color: "#809FB8", marginRight: "7px", cursor: "pointer" }} onClick={e => downloadDocument(index)} />
                                            }
                                            <CancelIcon style={{ color: "#EE5A5A", cursor: "pointer" }} onClick={e => deleteDocumentModal(doc?.uid)} />
                                        </div>
                                    </div>
                                    {/* <hr color="#D9E1E7"/> */}
                                </>
                            )
                        })
                    }
                </div>

            </ISDocumentContainer>

            <ISContainer>

                <DRNavContainer>
                    <NavTabsUl isCommand={true}>
                        {
                            <NavTabsLi>
                                <NavTabsLink
                                    active={tabSideActive === "fields"}
                                    onClick={() => setTabSideActive("fields")}
                                    to="#"
                                >
                                    {t("global:information", "Informations")}
                                </NavTabsLink>
                            </NavTabsLi>
                        }
                        <NavTabsLi>
                            <NavTabsLink
                                active={tabSideActive === "comments"}
                                onClick={() => setTabSideActive("comments")}
                                to="#"
                            >
                                {t('supplier:comments', "Commentaires")}
                            </NavTabsLink>
                        </NavTabsLi>
                        <NavTabsLi>
                            <NavTabsLink
                                active={tabSideActive === "timeline"}
                                onClick={() => setTabSideActive("timeline")}
                                to="#"
                            >
                                {t("mandat:timeline", "TimeLine")}
                            </NavTabsLink>
                        </NavTabsLi>

                    </NavTabsUl>
                </DRNavContainer>

                <ISNavContent>
                    {tabSideActive === "fields" && (
                        <Fields
                            setEntity={setEntity}
                            entity={entity}
                            invoiceData={supplierRequestData}
                            setInvoiceData={setSupplierRequestData}
                        />
                    )}
                    {tabSideActive === "timeline" &&
                        <TimeLine entityUid={entity?.uid} eventUpdate={eventUpdate} />
                    }
                    {tabSideActive === "comments" &&
                        <ModificationsChat addEventHandler={addEventHandler} entityUid={entity?.uid} />
                    }
                </ISNavContent>

            </ISContainer>

            <ConfirmationModal
                show={confirmationModalShow}
                modalClosed={() => setConfirmationModalShow(false)}
                confirm={() => deleteDocument()}
                cancel={() => setConfirmationModalShow(false)}
                title={"Confirmation"}
                message={t("global:deleteDocument", `Voullez-vous supprimer ce document?`)}
            />
        </ISDivision>
    )
}

const mapStateToProps = (state) => ({
    userType: state.auth.userType,
    tabSideActive: state.supplierRequestDetail.tabSideActive,
    supplierRequestData: state.supplierRequestDetail.supplierRequestData,
})
const mapDispatchToProps = dispatch => ({
    setTabSideActive: (tabSideActive) => dispatch(actions.setSupplierRequestTabSideActive(tabSideActive)),
    setSupplierRequestData: (supplierRequestData) => dispatch(actions.supplierRequestDataSuccess(supplierRequestData))
})
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSide)
