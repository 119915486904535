import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { useTheme } from "styled-components";
import { Modal } from "react-bootstrap";
import {
  CancelBtnOutlineAction,
  PrimaryBtnOutlineAction,
} from "../../../../../../styles/Common";
import CommandTable from "../CommandTable";
import InvoiceView from "../../InvoiceViewCommands/InvoiceView";
import {
  IDContainer,
  IDContainerTable,
  MyModalContainer,
} from "../InvoiceListCommand.styled";
import Research from "./Research";
import CommandTableModal from "./table/CommandTableModal";
import Axios from "../../../../../../axios-proas";
import TableAdditionalLine from "./table/TableAdditionalLine";
import { getNotyfObject } from "../../../../../../shared/utility";
import "./Modal.css";
import { useTranslation } from "react-i18next";

function AdditionalLineModal({
  show,
  modalClosed,
  cancel,
  uid,
  // getCommandesDataByInvoiceUid,
  update,
  setUpdate,
}) {
  const notyf = getNotyfObject;
  const theme = useTheme();
  const { t } = useTranslation();
  const [navTabsList, setNavTabsList] = useState([]);
  const [additionalLine, setAdditionalLine] = useState({
    ref: null, //e.target.value
    designation: null,
    qty: null,
    price: 0,
    reduction: null,
    codeTva: null,
    tva: 0,
    exclusiveTaxAmount: 0,
    account: null,
  });
  //const [uptdate, setUpdate] = useState(false);

  const typeInvoiceToNavListe = (xml, edi, pdf, chorus) => {
    var list = [];
    if (pdf !== null) list = [...list, { label: "PDF", value: "pdf" }];
    if (xml !== null) list = [...list, { label: "XML", value: "xml" }];
    if (edi !== null) list = [...list, { label: "EDI", value: "edi" }];
    if (chorus !== null) list = [...list, { label: "CHORUS", value: "chorus" }];

    setNavTabsList(list);
  };

  //useEffect(() => {}, [uptdate]);
  // useEffect(() => {
  //   typeInvoiceToNavListe(
  //     invoiceData?.xmlFile,
  //     invoiceData?.ediFile,
  //     invoiceData?.docFile,
  //     invoiceData?.chorusFile
  //   );
  // }, [
  //   invoiceData?.xmlFile,
  //   invoiceData?.ediFile,
  //   invoiceData?.docFile,
  //   invoiceData?.chorusFile,
  // ]);

  const saveClickHandler = () => {
    Axios.put("/invoice/" + uid + "/additionalLine", additionalLine)
      .then((response) => {
        //getCommandesDataByInvoiceUid(uid);
        //notyf.scuccess("Ligne ajoutée avec succès !");
        setUpdate(!update);
        setAdditionalLine({
          ref: null, //e.target.value
          designation: null,
          qty: null,
          price: null,
          reduction: null,
          codeTva: null,
          tva: null,
          exclusiveTaxAmount: null,
          account: null,
        });
        modalClosed();
      })
      .catch((err) => {
        console.log(err);
        notyf.error("Une erreur s'est produite !");
      });
  };

  const cancelClickHandler = () => {
    setUpdate(!update);
    setAdditionalLine({
      ref: null, //e.target.value
      designation: null,
      qty: null,
      price: null,
      reduction: null,
      codeTva: null,
      tva: null,
      exclusiveTaxAmount: null,
      account: null,
    });
    cancel();
  };
  return (
    <MyModalContainer>
      <Modal
        dialogClassName="myModal modal-dialog-centered"
        show={show}
        onHide={modalClosed}
        className="modalb"
      // contentClassName="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="ICDBlue">
            {t("invoiceDetail:addAdditionalLine", "Ajouter une ligne additionnelle")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="ADMCreationModal__body"
          style={{ width: "100%", padding: "0 2rem 0.5rem 2rem" }}
        >
          <TableAdditionalLine
            additionalLine={additionalLine}
            setAdditionalLine={setAdditionalLine}
          />
        </Modal.Body>
        <Modal.Footer>
          <PrimaryBtnOutlineAction onClick={() => saveClickHandler()}>
            {t("global:save", "Ajouter la ligne")}
          </PrimaryBtnOutlineAction>
          <CancelBtnOutlineAction onClick={cancelClickHandler}>
            {t("global:cancel", "Annuler")}
          </CancelBtnOutlineAction>
        </Modal.Footer>
      </Modal>
    </MyModalContainer>
  );
}
const mapStateToProps = (state) => ({
  loadingData: state.invoiceDetail.loadingData,
  invoiceData: state.invoiceDetail.invoiceData,
  invoiceError: state.invoiceDetail.invoiceError,
});
const mapDispatchToProps = (dispatch) => ({
  getInvoiceData: (invoiceUid) => dispatch(actions.getInvoiceData(invoiceUid)),
  getInvoiceDataByToken: (invoiceToken) =>
    dispatch(actions.getInvoiceDataByToken(invoiceToken)),
  resetAdmToInitialState: () => dispatch(actions.resetAdmToInitialState()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalLineModal);
