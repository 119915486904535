import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './StatusModal.css';
import { Button, Modal } from 'react-bootstrap';
// import { getStatusWithKey } from '../../../shared/utility';
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, CancelBtnOutlineAction } from '../../../../../../styles/Common';
import { useTranslation } from 'react-i18next';

function StatusModal(props) {
    const { show, modalClosed, confirm } = props;
    const [comment, setComment] = useState(null);
    const { t } = useTranslation();
    useEffect(() => {
    }, [])

    return (
        <div style={{ width: "500px" }}>
            <Modal show={show} onHide={modalClosed}  >
                <Modal.Header closeButton>
                    <Modal.Title className="ICDBlue">
                        {t("global:reminderConfirmation", "Confirmation de relance")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body">
                    <div className="statusModal__status_conatiner">
                        <p style={{ color: "gray", fontSize: "0.9rem" }}>
                            {t("global:noBack", "Cette action est définitive.")}<br />
                            {t("global:noBackConfirmation", "Veuillez confirmer.")}
                        </p>
                    </div>

                    <div className="statusModal__reason_container">
                        <div >
                            <label className="statusModal__status_label" for="comment" >{t("supplier:comment", "Commentaire")} {t("supplier:optional", "(facultatif)")}</label>
                            <textarea
                                className="form-control"
                                id="comment"
                                value={comment || ''}
                                onChange={(e) => setComment(e.target.value)}
                                rows="3"></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <PrimaryBtnOutlineAction
                        variant="primary"
                        onClick={() => confirm(comment)}
                    // disabled={statusValue === "LITIGATION" && !comment}
                    >
                        {t("global:confirm", "Confirmer")}
                    </PrimaryBtnOutlineAction>
                    <CancelBtnOutlineAction variant="secondary" onClick={modalClosed}>
                        {t("global:cancel", "Annuler")}
                    </CancelBtnOutlineAction>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default StatusModal;
