import React, { useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Navigate, Route, useLocation, Routes, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';
import { HelmetProvider } from 'react-helmet-async';
import GlobalStyles from './styles/Global';
import theme from './styles/theme';
import darkTheme from './styles/DarkTheme';
import Auth from './containers/Auth/Auth';
import OwnerRoot from './containers/AccesRoots/OwnerRoot/OwnerRoot';
import SupplierRoot from './containers/AccesRoots/SupplierRoot/SupplierRoot';
import ClientRoot from './containers/AccesRoots/ClientRoot/ClientRoot';
import NewSupplierAccountProgress from './containers/NewSupplierAccountProgress/NewSupplierAccountProgress'
import "./App.css"
import ContractSignatureMessage from './components/ContractMessageSignature/ContractMessageSignature'
import SupplierAccountRequest from './components/AccountRequest/AccountRequest';

function App(props) {
  const { currentTheme, onTryAutoSignup, isAuthenticated, userType, userLevel, authRedirectPath, loading } = props;
  const accessTokenList = ["document", "forgotPassword", "account", "campaign", "invoiceToken", "supplierAccountProgress"];
  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    onTryAutoSignup()
  }, []);

  useEffect(() => {
    const accessToken = accessTokenList.includes(location.pathname.split("/")[1]);
    if (authRedirectPath && !accessToken)
      navigate('/');
  }, [authRedirectPath]);

  let routes;

  if (isAuthenticated) {
    switch (userType) {

      case "owner":
        routes = (
          <OwnerRoot userLevel={userLevel} userType={userType} />
        )
        break;
        case "supplier":
        routes = (
          <SupplierRoot />
        )
        break;
      case "client":
        routes = (
          <ClientRoot userLevel={userLevel} userType={userType} />
        )
        break;

      default:
        break;
    }
  }
  else {
    routes = (
      <>
        <Routes>
          <Route path="/" element={<Auth />} />
          <Route path="/newAccountRequest" element={<SupplierAccountRequest />} />
          <Route path="/supplierAccountProgress/:token" element={<NewSupplierAccountProgress entityUid/>} />
          <Route path="/contractSignature/:token" element={<ContractSignatureMessage entityUid/>} />
          
        </Routes>
      </>
    )
  }

  const getTheme = () => {
    if (currentTheme === "dark") {
      return darkTheme;
    }
    switch (userType) {
      case "owner":
        return theme;
      default:
        return theme;
    }
  }

  return (
    <ThemeProvider theme={getTheme()}>
      <HelmetProvider>
        <GlobalStyles />
        <div className="app">
          {routes}
        </div>
      </HelmetProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {
    currentTheme: state.app.theme,
    // isAuthenticated: localStorage.getItem('token') !== null,
    isAuthenticated: state.auth.token !== null,
    loading: state.auth.loading,
    userType: state.auth.userType,
    userLevel: state.auth.userLevel,
    authRedirectPath: state.auth.authRedirectPath
  }
}

const mapDispatchProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}
export default connect(mapStateToProps, mapDispatchProps)(App);
