import { color } from '@mui/system';
import styled from 'styled-components/macro';

export const UIContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
`

export const BodyDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    border:1px solid #809FB8;
    border-radius: 16px;
    justify-content:space-between;
    align-items: center;
    min-height:500px ;
    padding: 0.5REM;
    &:first-child{
      
        padding: 0.5rem;
        color: red;

    }
`
export const NotDiv = styled.div`
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    border:1px solid #809FB8;
    border-radius: 16px;
    padding:0.5rem 1rem;
 
`
export const UIBody = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
`
export const TD = styled.td`
    padding: 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 300px;
    & > span {
        display: block;
        width: 100%;
        overflow: hidden; 
        text-overflow: ellipsis;
        white-space: nowrap;
        color:${({ err, theme }) => !err ? "#EE5A5A" : "#505050"} ;
        &:hover{
            color:${({ err, theme }) => !err ? "#2174B9" : "#2174B9"} ;
        }
    }
`
export const TR = styled.tr`
    background-color: #fff;
    cursor: pointer;
${({ isactive }) => isactive && `
    background-color: #F1F4F9;
        & td:last-child {
            background-color:#fff ;
        }
`}
    &:hover {
        background-color: #F1F4F9;
        & td:last-child {
            background-color:#fff ;
        }
    
    }
`
export const UISection = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

export const UICol = styled.div`
    width: 49%;
    display: flex;
    flex-direction: column;
    /* padding: 2rem 1.5rem; */
    /* border: 1px solid #D9E1E7CC;  */
    /* border-radius: 16px; */
`

export const DocVisu = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #809FB8; 
    border-radius: 16px;
    height: 850px;
    max-height: 850px;
    overflow: scroll;
`