import React, { useMemo, useState } from 'react';
import { formatDate } from '../../../shared/utility';
import DateProgressLine from '../DateProgressLine/DateProgressLine';
import StatusProgressLine from '../StatusProgressLine/StatusProgressLine';
import { CIActions, CIContainer, CIContent, CIData, CIDeleteBtn, CITitle, CIUsersLink, UpdateLink } from './CampaignItem.styled';
import GroupIcon from '@mui/icons-material/Group';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ConfirmationModal from '../../UI/ConfirmationModal/ConfirmationModal'
import Axios from '../../../axios-proas'
import { Notyf } from "notyf";
import theme from '../../../styles/theme';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { useTranslation } from 'react-i18next';

function CampaignItem({ campaign, getCampaigns, itemIndex }) {
    const monthList = useMemo(() => ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"], [])

    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const notyf = new Notyf();
    const { t } = useTranslation()

    const deleteCompagneClickHandler = (Uid) => {

        Axios.delete(`campaign/${Uid}/deleteCompagne`).then(response => {
            getCampaigns()
            setConfirmationModalShow(false)
            notyf.success(t("global:success", "La campagne a bien été supprimée"))
        }).catch(response => {

            notyf.error("generatedErrorMessage")
        })
    }
    return (
        <>
            <CIContainer style={{ display: "flex" }}>
                <div style={{
                    padding: "4px 0px",
                    fontSize: "22px",
                    margin: "auto 14px auto 0px",
                    width: "61px",
                    border: "1px solid #809FB8",
                    borderRadius: "18px",
                    textAlign: "center",
                    color: "#505050"
                }}>
                    <p style={{ color: "#505050" }}>{itemIndex + 1}</p>
                </div>
                <div style={{ width: "100%" }}>
                    <CIContent>
                        <CIData style={{ display: 'block' }}>
                            <CITitle>
                                {campaign?.title}
                            </CITitle>
                            {/* <DateProgressLine
                                creationDate={+campaign?.creationDate}
                                campaignDate={+campaign?.campaignDate}
                                endDate={+campaign?.endDate}
                            /> */}
                            <StatusProgressLine
                                status={campaign?.status}
                                creationDate={+campaign?.creationDate}
                                campaignDate={+campaign?.campaignDate}
                                endDate={+campaign?.endDate}
                            />
                        </CIData>
                        <CIActions>
                            <div>
                                <div style={{ display: 'flex', marginBottom: "13px" }}>
                                    <span style={{ color: theme.colors.icdgray, fontSize: "small", marginTop: "2px" }}>{t("supplier:status", "Statut")} : </span>
                                    {/* <ToggleOnIcon
                                    style={{ marginLeft: '0.25rem', color: theme.colors.success, cursor: 'pointer' }}
                                    // onClick={() => {
                                    //     setUserToDisable(row.userUid);
                                    //     setConfirmationModalShow(true)
                                    // }}
                                /> */}
                                    <ToggleOffIcon

                                        style={{ color: theme.colors.icdgray, marginLeft: '0.25rem', cursor: 'pointer', fontSize: 'xx-large', marginTop: "-5px" }}
                                    // onClick={() => enableUser(row.userUid)}
                                    />
                                </div>


                                {/* <CIUsersLink 
                                to={`#`}  
                            >
                                    {campaign?.recipients?.split(',').length} 
                                <GroupIcon style={{fontSize: "1.2rem", marginLeft: "2px"}} />
                            </CIUsersLink> */}
                                <div style={{ display: "flex", justifyContent: "right" }}>
                                    {
                                        campaign?.status.toUpperCase() != "scheduled".toUpperCase() ?
                                            (
                                                <>
                                                    {
                                                        campaign?.status.toUpperCase() === "completed".toUpperCase() ?
                                                            <FileDownloadIcon style={{ color: theme.colors.primary, cursor: 'pointer' }} />
                                                            :
                                                            <UpdateLink
                                                                to={"/newCampaign/" + campaign?.uid}
                                                            >
                                                                <EditIcon style={{ color: theme.colors.primary, cursor: 'pointer' }} />
                                                            </UpdateLink>
                                                    }
                                                    <CancelIcon style={{ color: theme.colors.danger, cursor: 'pointer' }} onClick={() => setConfirmationModalShow(true)}>
                                                        {t("global:delete", "Supprimer")}
                                                    </CancelIcon>
                                                </>

                                            ) : null

                                    }
                                    {/* {
                                    campaign?.status.toUpperCase() === "completed".toUpperCase() ?
                                        <FileDownloadIcon style={{color: theme.colors.primary, cursor: 'pointer'}}/>
                                    :
                                        <UpdateLink
                                            to={"/newCampaign/"+campaign?.uid}
                                            > 
                                            <EditIcon style={{color: theme.colors.primary, cursor: 'pointer'}}/>
                                        </UpdateLink>
                                }
                                <CancelIcon style={{color: theme.colors.danger, cursor: 'pointer'}}  onClick={() => setConfirmationModalShow(true)}>
                                    Supprimer
                                </CancelIcon> */}

                                </div>
                            </div>


                            {/* <CIUsersLink
                            style={{marginLeft: "5px"}}
                            color="#fff"
                            border="#FFF"
                            to={"/newCampaign/"+campaign?.uid}
                        
                            > Voir  <ArrowRightIcon/>
                        
                        </CIUsersLink> */}


                        </CIActions>

                    </CIContent>
                </div>
            </CIContainer>
            <ConfirmationModal
                show={confirmationModalShow}
                modalClosed={() => setConfirmationModalShow(false)}
                confirm={() => deleteCompagneClickHandler(campaign?.uid)}
                cancel={() => setConfirmationModalShow(false)}
                title={"Confirmation de suppression"}
                message={`Êtes-vous sûr de vouloir supprimer cette campagne ?`}
            />
        </>
    );
}

export default CampaignItem;
