import React, { useCallback, useEffect, useState } from 'react'
// import './RepertoireModal.css'
import SearchIcon from '@mui/icons-material/Search';
import Axios from '../../../axios-proas'
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { useTranslation } from 'react-i18next';
// import EntitiesTable from './EntitiesTable/EntitiesTable';
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, CancelBtnOutlineAction } from '../../../styles/Common';
import { FormRadioWrapper, FormLabel } from './ActionsModal.styled';
import enIE from 'date-fns/esm/locale/en-IE/index.js';

function ActionsModal({ handleClose, show, addEventHandler, status, userLevel }) {

    const { t } = useTranslation();

    const [event, setEvent] = useState({
        action: 0,
        comment: "",
        type: "PAYMENT_SIGNATURE"
    })

    const handleEvent = (e, value) => {
        setEvent({ ...event, [e]: value })
    }

    useEffect(() => {
        return (
            setEvent({
                action: 0,
                comment: "",
                type: "PAYMENT_SIGNATURE"
            })
        )
    }, [])



    const addClickHandler = () => {
        addEventHandler(event)
        handleClose()
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} dialogClassName="modal_w_mc" contentClassName="modal_w_mc"  >
                <Modal.Header closeButton>
                    <Modal.Title className="repertoireTitle ICDBlue" style={{ width: "483px !important", minWidth: "483px", paddingBottom: "1rem" }}>{t("invoiceDetail:parapheurAction", "Action sur le parapheur")} </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="repertoireModal__entitiesHeader_container" style={{ borderBottom: "2px dashed rgb(128, 159, 184)" }}>
                        <div className="" style={{ width: "100%" }}>
                            <label for="entityType" style={{ color: "#809FB8", fontSize: "19px" }}>Action : </label>
                            <select
                                className="form-control form-select"
                                value={event?.action}
                                name="action"
                                onChange={(e) => handleEvent(e.target.name, e.target.value)}
                            >
                                <option value="0">-- {t("invoiceDetail:chooseAction", "Choisir une action")} --</option>
                                {
                                    (status == 'PENDING' && userLevel == 'accountant') &&
                                    <>
                                        <option value="1">{t("invoiceDetail:paraSignature", "Proposer le parapheur en signature")}</option>
                                        <option value="3">{t("invoiceDetail:paraArchiving", "Archiver le parapheur")}</option>
                                    </>
                                }
                                {
                                    (status == 'TO_VALIDATE' && userLevel == 'superAccountant') &&
                                    <option value="2">{t("invoiceDetail:returnAccounting", "Renvoyer en comptabilité")}</option>
                                }
                                {
                                    (status == 'TO_CONTROL' && userLevel == 'accountant') &&
                                    <option value="3">{t("invoiceDetail:paraArchiving", "Archiver le parapheur")}</option>
                                }
                                {/* <option value="1">Proposer le parapheur en signature</option>
                                    <option value="2">Renvoyer en comptabilité</option>
                                    <option value="3">Archiver le parapheur</option> */}

                            </select>
                        </div>

                    </div>
                    {
                        event?.action == "1" &&
                        <div style={{ margin: "25px 0px 23px 0px" }}>
                            <div className="" style={{ width: "100%" }}>
                                <label for="entityType" style={{ color: "#809FB8", fontSize: "19px" }}>{t("user:selectUser", "Sélectionnez un utilisateur")} : </label>
                                <select
                                    className="form-control form-select"
                                    value={event?.valideur}
                                    name="valideur"
                                //  onChange={(e) => handleEvent(e.target.name, e.target.value)}
                                >
                                    <option value=""></option>
                                    {
                                        <option value={1}>
                                            {t("user:financialDirector", "Directeur financier")}
                                        </option>
                                    }
                                </select>
                            </div>
                        </div>
                    }
                    <div className="">
                        <label for="entityType" style={{ color: "#809FB8", fontSize: "19px" }}>{t("supplier:comment", "Commentaire")} {t("supplier:optional", "(facultatif)")}: </label>
                        <textarea
                            rows="5"
                            className="form-control"
                            value={event?.comment}
                            name="comment"
                            onChange={(e) => handleEvent(e.target.name, e.target.value)}
                            style={{ width: "100% !important" }}
                        />
                    </div>




                </Modal.Body>
                <Modal.Footer>
                    <PrimaryBtnOutlineAction variant="primary"
                        disabled={event?.action == 0}
                        onClick={() => addClickHandler()}
                    >
                        {t('global:confirm', "Confirmer")}
                    </PrimaryBtnOutlineAction>
                    <CancelBtnOutlineAction variant="secondary" onClick={handleClose}>
                        {t('global:cancel', "Annuler")}
                    </CancelBtnOutlineAction>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default ActionsModal
