import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FilterContainer, InputFilter, TD } from '../../../styles/Table.styled';
import DateFilter from '../../UI/DateFilter/DateFilter';

function TableFilters(props) {
    const { showFilters, instanceFilters, filtersChange, applyFilters, columns } = props;
    const [show, setShow] = useState(null)
    const [dateField, setDateField] = useState('')
    const { t } = useTranslation();

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...instanceFilters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        applyFilters(newFilters)
    }
    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])

    const closeModalHandler = () => {
        setShow(false);
    }
    const dateShowHandler = (field) => {
        setShow({
            [field]: true
        })
        setDateField(field);
    }
    const convertDate = (date) => {
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]), formatDate(+dateArray[1])]
    }

    let [dateInputValue, setDateInputValue] = useState({
        creationDate: instanceFilters?.creationDate ? convertDate(instanceFilters.creationDate) : ''
    });

    const getCreationdate = () => {
        return instanceFilters?.creationDate ? convertDate(instanceFilters.creationDate) : [];
    }

    const dateChangeHandler = (date) => {
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(date.startDate) + '-' + formatDate(date.endDate)
        })
        let key = date.field;
        let newFilters = {
            ...instanceFilters,
            [key]: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...instanceFilters,
            [field]: null
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    return (
        <>
            <tr style={{ display: showFilters ? 'table-row' : 'none' }}>

                <TD style={{ display: !columns.filter(column => column.field == "instanceName")[0].show == true && 'none' }}>
                    <FilterContainer>
                        <InputFilter
                            placeholder={t("invoiceList:instanceName", "instanceName")}
                            onChange={(e) => inputChangeHandler(e)}
                            value={instanceFilters?.name || ''}
                            name="name"
                            type="text"
                            autoComplete="off"
                        />
                    </FilterContainer>
                </TD>
                <TD style={{ display: !columns.filter(column => column.field == "creationDate")[0].show == true && 'none' }}>
                    <FilterContainer>
                        <DateFilter
                            modalTitle={t("invoiceList:creationDate", "Date de Création")}
                            from={getCreationdate()[0] || ''}
                            to={getCreationdate()[1] || ''}
                            name="creationDate"
                            activeReset={instanceFilters?.creationDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.creationDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) => dateChangeHandler(date)}
                        />
                    </FilterContainer>
                </TD>
                <TD style={{ display: !columns.filter(column => column.field == "usersNumber")[0].show == true && 'none' }}>
                    <FilterContainer>
                        <InputFilter
                            placeholder={t("invoiceList:usersNumber", "usersNumber")}
                            onChange={(e) => inputChangeHandler(e)}
                            value={instanceFilters?.city || ''}
                            name="usersNumber"
                            type="text"
                            autoComplete="off"
                        />
                    </FilterContainer>
                </TD>
                <TD style={{ display: !columns.filter(column => column.field == "status")[0].show == true && 'none' }}>
                    <FilterContainer>
                        <InputFilter
                            placeholder={t("supplier:status", "Statut")}
                            onChange={(e) => inputChangeHandler(e)}
                            value={instanceFilters?.status || ''}
                            name="status"
                            type="text"
                            autoComplete="off"
                        />
                    </FilterContainer>
                </TD>


            </tr>
        </>
    )
}

export default TableFilters
