import React, { useCallback, useEffect, useRef, useState } from 'react';
import Axios from '../../../axios-proas'
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { EntOwnersSelect, AddOwner, SearchOwner, SelectOwnersHeader, SelectOwnersItem, SelectOwnersItems, SelectOwnersList, ItemLabel, ItemCode } from './EntitiesDropDown.styled';
import { useTranslation } from 'react-i18next';
import { BtnIcon, PrimaryBtnOutlineAction, PrimaryBtnOutlineLink, SearchInput, SearchInputContainer } from "../../../styles/Common";
import MandatoryMarker from '../../../components/UI/MandatoryMarker';

function EntitiesDropDown({ entitiesType, userEntities, addEntitiesUser }) {
    const { t } = useTranslation();
    const [showEntityModal, setShowEntityModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState(null)
    const [entities, setEntities] = useState([]);
    console.log("userEntities", userEntities)
    const selectRef = useRef();

    const clickOutSideHandler = e => {
        if (selectRef.current.contains(e.target)) {
            // inside the div
            return
        }
        // outside click
        setShowEntityModal(false)
    }

    useEffect(() => {
        document.addEventListener("mousedown", clickOutSideHandler);

        return () => {
            document.removeEventListener("mousedown", clickOutSideHandler);
        }
    }, [])

    const getEntitiesList = useCallback(() => {
        let filter = {
            searchQuery: searchQuery,
            type: entitiesType
        }
        Axios.get('/client//modalEntities', {
            params: filter
        })
            .then(res => {
                setEntities(res.data?.result)
            })
    }, [searchQuery])

    useEffect(() => {
        getEntitiesList()
    }, [searchQuery])

    const SelectEntity = (entity) => {
        let entityIndex = userEntities?.findIndex(row => row.uid === entity.uid);
        let selectedTemp = [...userEntities];
        if (entityIndex < 0)
            selectedTemp = [...userEntities, entity];
        addEntitiesUser(selectedTemp);
        setShowEntityModal(false);
    }

    return (
        <>
            <EntOwnersSelect ref={selectRef} >
                {
                    !showEntityModal ? (
                        <AddOwner onClick={() => setShowEntityModal(!showEntityModal)}>
                            <span>{t("global:addEntity", "Ajouter une entité")}<MandatoryMarker /></span>
                            <AddIcon style={{ fontSize: "0.7rem" }} />
                        </AddOwner>
                    ) : (
                        <SearchOwner>
                            <SearchInput
                                placeholder="Rechercher"
                                type="text"
                                onChange={(e) => setSearchQuery(e.target.value)}
                                value={searchQuery || ''}
                            />
                            <CloseIcon style={{ fontSize: "0.7rem" }} />
                        </SearchOwner>
                    )
                }
                {
                    showEntityModal && (
                        <SelectOwnersList>
                            <SelectOwnersHeader>
                                <span>Raison sociale</span>
                                <span>Code</span>
                            </SelectOwnersHeader>
                            <SelectOwnersItems>
                                {
                                    entities?.length > 0 ? entities.map(entity => (
                                        <SelectOwnersItem onClick={() => SelectEntity({ uid: entity.uid, name: entity.name, new: true })}>
                                            <ItemLabel>{entity.name}</ItemLabel>
                                            <ItemCode>{entity.code}</ItemCode>
                                        </SelectOwnersItem>
                                    )) : <div style={{ whiteSpace: "nowrap" }}>
                                        {t('mandat:noAgencies', "Aucune entreprise à afficher")}
                                    </div>
                                }
                            </SelectOwnersItems>
                        </SelectOwnersList>
                    )
                }
            </EntOwnersSelect>
        </>
    );
}

export default EntitiesDropDown;
