import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { EntInfosContainer, documentsList } from './Infos.styled'
import fr from 'react-phone-input-2/lang/fr.json'
import { EIFormCol, EIFormContainer, EntFormItem, EntFormList, EntFormSection } from '../../../containers/Entity/Entity.styled';
import EntitiesDropDown from '../EntitiesDropDown/EntitiesDropDown';
import { FormECILabel, FormECItem, FormECIValue, FormECLabel, FormECListContainer, FormEntCanal, FormGroup, FormInput, FormLabel, FormSelect, TelInput } from '../../../styles/Common';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import MandatoryMarker from '../../../components/UI/MandatoryMarker';
import DropZone from '../../../components/UI/DropZone/DropZone'
import PopoverComp from '../../../components/UI/PopoverComp/PopoverComp'

function Infos({ entity, handleInputChange, entitiesType, entities, addEntity, deleteEntity, canalList, canalChangeHandler, supplierCanalChangeHandler, supplierCanalList, handlePhoneInputChange }) {
    const theme = useTheme();
    const { t } = useTranslation();

    const showEntitiesDropDown = () => {
        if (entity.hasOwnProperty("type") && entity.type !== "owner")
            return true;
        return false;
    };

    const showInoiceEmail = () => {
        let showCases = ['EMAIL', 'PORTAL'];
        if (showCases.includes(entity.channel))
            return true;
        return false;
    };

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles?.map((file) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                setDocuments((prevState) => [
                    ...prevState,
                    { id: "78afaer8gf79aergf", src: e.target.result },
                ]);
            };
            reader.readAsDataURL(file);
            return file;
        });
    }, []);

    const documentsInformation =
        <>
            <span style={{ color: "#809FB8" }}>Liste des documents connus :</span>
            <documentsList >
                <li>Justificatif d’Immatriculation KBIS</li>
                <li>Attestation sociale de vigilance URSSAF</li>
                <li>Attestation d'assurance</li>
                <li>RIB</li>
                <li>Liste des salariés étrangers soumis à autorisation de travail</li>
            </documentsList>
        </>


    return (
        <>
            <EntInfosContainer>
                <EIFormContainer>
                    <EIFormCol>
                        <FormGroup>
                            <FormLabel htmlFor="name" >{t("global:name", "Nom")}<MandatoryMarker /></FormLabel>
                            <FormInput
                                id="name"
                                type="text"
                                name="name"
                                // placeholder="Nom"
                                value={entity?.name || ""}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="code" >Code</FormLabel>
                            <FormInput
                                id="code"
                                type="text"
                                name="code"
                                // placeholder="Code"
                                value={entity?.code || ""}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="siret" >{t("global:firstIdentifier", "Identifiant 1: N° Siret")}<MandatoryMarker /></FormLabel>
                            <FormInput
                                id="siret"
                                type="number"
                                name="siret"
                                // placeholder="Identifiant 1: N° Siret"
                                value={entity?.siret || ""}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="siren" >{t("global:secondIdentifier", "Identifiant 2: N° Siren")}<MandatoryMarker /></FormLabel>
                            <FormInput
                                id="siren"
                                type="number"
                                name="siren"
                                // placeholder="Identifiant 2: N° Siren"
                                value={entity?.siren || ""}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="vat" >{t("global:thirdIdentifier", "Identifiant 3: N° TVA")}</FormLabel>
                            <FormInput
                                id="vat"
                                type="text"
                                name="vat"
                                // placeholder="Identifiant 3: N° TVA"
                                value={entity?.vat || ""}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="telephone" >{t("user:phone", "Téléphone")}</FormLabel>
                            <TelInput
                                style={{ color: "#505050 !important" }}
                                id="telephone"
                                localization={fr}
                                country={'fr'}
                                dropdownStyle={{ width: "auto" }}
                                preferredCountries={['fr', 'us']}
                                enableSearch={false}
                                name="telephone"
                                inputProps={{ name: "telephone" }}
                                placeholder={"33 0 00 00 00 00"}
                                value={entity?.telephone?.substr(2) || ''}
                                onChange={(phone) => handlePhoneInputChange("00" + phone)}
                            />
                            {/* <FormInput 
                            id="telephone"
                            type="text"
                            name="telephone"
                            // placeholder="Téléphone"
                            value={entity?.telephone || ""}
                            onChange={handleInputChange}
                        /> */}
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="entityAdmin" >{t("supplier:adminContact", "Contact administrateur")}<MandatoryMarker /></FormLabel>
                            {
                                entity?.id ? (
                                    <FormSelect
                                        id="adminContact"
                                        name="adminContact"
                                        value={entity?.adminContact || ""}
                                        onChange={handleInputChange}
                                    >
                                        {
                                            entity?.admins && entity?.admins.map(admin => (
                                                <option key={admin.id} value={admin.uid}>{admin.login}</option>
                                            ))
                                        }
                                    </FormSelect>
                                ) : (
                                    <FormInput
                                        id="entityAdmin"
                                        type="email"
                                        name="entityAdmin"
                                        // placeholder="Contact administrateur"
                                        value={entity?.entityAdmin || ""}
                                        onChange={handleInputChange}
                                    />
                                )
                            }
                        </FormGroup>

                        {
                            entity?.type === "client" &&
                            <>
                                <FormEntCanal>
                                    <FormECLabel style={{ marginRight: "10px" }}>{t("signin:canal", "Canal d'échange")}</FormECLabel>
                                    <FormECListContainer>
                                        {
                                            canalList.map((canal, index) => (
                                                <FormECItem isActive={entity?.channel === canal.value} >
                                                    <FormECILabel>{canal?.label} </FormECILabel>
                                                    <FormECIValue>
                                                        {
                                                            entity?.channel === canal.value ? (
                                                                <ToggleOnIcon
                                                                    style={{ marginLeft: '0.25rem', color: theme.colors.success, cursor: 'pointer' }}
                                                                    fontSize="small"
                                                                    name="edi"
                                                                    onClick={() => canalChangeHandler(null)}

                                                                />
                                                            ) :
                                                                <ToggleOffIcon
                                                                    style={{ marginLeft: '0.25rem', cursor: 'pointer' }}
                                                                    fontSize="small"
                                                                    name="edi"
                                                                    onClick={() => canalChangeHandler(canal?.value)}
                                                                />

                                                        }
                                                    </FormECIValue>
                                                </FormECItem>
                                            ))
                                        }
                                    </FormECListContainer>
                                </FormEntCanal>
                                {
                                    showInoiceEmail() &&
                                    <FormGroup>
                                        <FormLabel htmlFor="invoiceMail" >{t("global:emailContact", "Email d'envoi de facture")}</FormLabel>
                                        <FormInput
                                            id="invoiceMail"
                                            type="email"
                                            name="invoiceMail"
                                            // placeholder="Email d'envoi de facture"
                                            value={entity?.invoiceMail || ""}
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                }
                            </>
                        }
                        {
                            entity?.type === "supplier" &&
                            <>
                                <FormEntCanal>
                                    <FormECLabel >{t("supplier:exchangeChannels", "Canal d'échange")}</FormECLabel>
                                    <FormECListContainer>
                                        {
                                            supplierCanalList.map((canal, index) => (
                                                <FormECItem isActive={entity?.channel === canal.value}>
                                                    <FormECILabel>{canal?.label} </FormECILabel>
                                                    <FormECIValue>
                                                        {
                                                            entity?.channel === canal.value ? (
                                                                <ToggleOnIcon
                                                                    style={{ marginLeft: '0.25rem', color: theme.colors.success, cursor: 'pointer' }}
                                                                    fontSize="small"
                                                                    onClick={() => canalChangeHandler(null)}

                                                                />

                                                            ) :
                                                                <ToggleOffIcon
                                                                    style={{ marginLeft: '0.25rem', cursor: 'pointer' }}
                                                                    fontSize="small"
                                                                    onClick={() => canalChangeHandler(canal?.value)}
                                                                />

                                                        }
                                                    </FormECIValue>
                                                </FormECItem>
                                            ))
                                        }
                                    </FormECListContainer>
                                </FormEntCanal>
                            </>
                        }

                        {/* <FormGroup>
                        <FormLabel htmlFor="docs" >Dépôt de documents légaux (KBIS, Attestation URSAF, ...) <PopoverComp message={documentsInformation}/></FormLabel>
                        <DropZone onDrop={onDrop} accept={"image/*"} />
                    </FormGroup> */}

                    </EIFormCol>


                    <EIFormCol>
                        <FormGroup>
                            <FormLabel htmlFor="address" >{t("supplier:address", "Adresse")}<MandatoryMarker /></FormLabel>
                            <FormInput
                                id="address"
                                type="text"
                                name="address"
                                // placeholder="Adresse"
                                value={entity?.address || ""}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="addressComplement" >{t("supplier:additionalAddress", "Complément d'adresse")}</FormLabel>
                            <FormInput
                                id="addressComplement"
                                type="text"
                                name="addressComplement"
                                // placeholder="Complément d'adresse"
                                value={entity?.addressComplement || ""}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="zipCode" >{t("supplier:zipCode", "Code postal")}<MandatoryMarker /></FormLabel>
                            <FormInput
                                id="zipCode"
                                type="number"
                                name="zipCode"
                                // placeholder="Code postal"
                                value={entity?.zipCode || ""}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="city" >{t("supplier:city", "Ville")}<MandatoryMarker /></FormLabel>
                            <FormInput
                                id="city"
                                type="text"
                                name="city"
                                // placeholder="Ville"
                                value={entity?.city || ""}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="countryCode" >{t("supplier:country", "Pays")} <MandatoryMarker /></FormLabel>
                            <FormSelect
                                id="countryCode"
                                name="citcountryCodey"
                                value={entity?.countryCode || ""}
                                onChange={handleInputChange}
                            >
                                <option value="fr">FRANCE</option>
                            </FormSelect>
                        </FormGroup>
                        {
                            entity?.type !== "owner" && (
                                <>
                                    <FormGroup>
                                        <FormLabel htmlFor="family" >{t("supplier:family", "Famille")}</FormLabel>
                                        <FormSelect
                                            id="family"
                                            name="family"
                                            value={entity?.family || ""}
                                            onChange={handleInputChange}
                                        >
                                            <option value="ADMINISTRATION">Administration de l'agriculture de time stamp Administration</option>
                                            <option value="INDUSTRIE">{t("supplier:industry", "Industrie")}</option>
                                            <option value="SERVICE">Service</option>
                                        </FormSelect>
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="lang" >{t("global:language", "Langue")}</FormLabel>
                                        <FormSelect
                                            id="lang"
                                            name="lang"
                                            value={entity?.lang || ""}
                                            onChange={handleInputChange}
                                        >
                                            <option value="fr">{t("global:french", "Français")}</option>
                                            <option value="en">{t("global:english", "Anglais")}</option>
                                        </FormSelect>
                                    </FormGroup>

                                    {/* <FormGroup>
                                    <FormLabel htmlFor="telephone" >Téléphone</FormLabel>
                                    <TelInput
                                        style={{color: "#505050 !important"}}
                                        id="telephone"
                                        localization={fr}
                                        country={'fr'}
                                        dropdownStyle={{width: "auto"}}
                                        preferredCountries={['fr', 'us']}
                                        enableSearch={false}
                                        name="telephone"
                                        inputProps={{name: "telephone"}}
                                        placeholder={"33 0 00 00 00 00"}
                                        value={''}
                                        // onChange={(phone) => handlePhoneInputChange("00" + phone)} 
                                    />
                                </FormGroup> */}
                                </>
                            )
                        }

                    </EIFormCol>
                </EIFormContainer>
            </EntInfosContainer>
        </>
    );
}

export default Infos;
