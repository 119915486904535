import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import logo from '../../assets/images/LogoRotiflex.png';
import { useTheme } from 'styled-components'
import './NewSupplierAccountProgress.css'
import Axios from '../../axios-proas'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import { formatDate } from '../../shared/utility';
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction } from "../../styles/Common";
import { PLineBar, PLineContainer, PLineStatus, PLineRequestInf, PLineLabel, PLineCircle, PLine, PLineHiden, PLineContent, FormLabelInf, FormLabelValues, FormGroup } from "./NewSupplierAccountProgress.styled"
import ContractSignature from '../../components/ContractSignature/ContractSignature';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import AuthCode from 'react-auth-code-input';
import { useTranslation } from 'react-i18next';

function NewSupplierAccountProgress({ }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [showContractModal, setShowContractModal] = useState(false)
  const progressLineStatus = [
    "En validation",
    "Décision",
    "Demande acceptée",
    "Attente de signature de contrat",
    // "Demande de pièces complémentaires",
    // "Demande refusée"
  ]
  const AuthInputRef = useRef();
  const [event, setEvent] = useState()
  const [entity, setEntity] = useState()
  const [comment, setComment] = useState()
  const { token } = useParams();
  const [result, setResult] = useState();

  useEffect(() => {
    //console.log(token)
    getSupplierAccountRequest()
  }, [])


  const handleOnChange = (res) => {
    setResult(res);
    console.log(res)
  };


  const getStatusColors = (index) => {
    let statusIndex = event?.message
    if (index <= statusIndex)
      return theme.colors.secondary
    return theme.colors.gray
  }



  const getSupplierAccountRequest = () => {

    Axios.get("entity/" + token + "/supplierAccountRequest").then(response => {

      setEntity(response?.data?.entity)
      setEvent(response?.data?.entityEvent)
      setComment(response?.data?.entityComment)

    }).catch(err => {

    })
  }


  let progressLine = progressLineStatus.map(status => {
    return (
      <div style={{ textAlign: "center", paddingTop: "10px" }}>
        <p style={{ width: "43px", height: "43px", borderRadius: "50%", color: "#B8B8B8", margin: "auto" }}></p>
        <p>{status}</p>
        {/* <span style={{borderLeft: "2px solid #B8B8B8", alignSelf:"center"}}></span> */}
      </div>
    )
  })

  return (
    <div className="signin__container">
      <div className="signin__FromWrap">
        <div className="signin__fromContent">
          <div className="signin__formm">
            <div style={{ textAlign: "center", marginBottom: "27px" }}>
              <img src={logo} alt="logo" style={{ width: "243px", marginBottom: "30px" }} />
            </div>
            <h4 className="colorSpec">{t("global:statusRequest", "Status de la demande de référencement")} N°REF{entity?.id}</h4>

            <div style={{ display: "flex" }}>
              {/* {
                progressLine
              } */}
              <PLineContainer>
                <PLineContent>
                  <PLineHiden />
                  <PLineCircle color={getStatusColors(0)} />
                  <PLine border={getStatusColors(0)} />
                </PLineContent>
                <PLineLabel color={getStatusColors(0)}>{t("global:registredRequest", "Demande enregistrée")}</PLineLabel>
              </PLineContainer>
              <PLineContainer>
                <PLineContent>
                  <PLine border={getStatusColors(0)} />
                  <PLineCircle color={getStatusColors(0)} />
                  <PLine border={getStatusColors(event?.message > 1 ? 1 : 100)} />
                </PLineContent>
                <PLineLabel color={getStatusColors(0)}>{t("global:inValidation", "En validation")}</PLineLabel>
                <div style={{ textAlign: "center", paddingTop: "10px" }}>
                  <PLineBar color={getStatusColors(event?.message == 1 ? 1 : 100)} />
                  <PLineLabel color={getStatusColors(event?.message == 1 ? 1 : 100)}>{t("global:additionalDocumentRequest", "Demande de pièces complémentaires")}</PLineLabel>
                  {/* <span style={{borderLeft: "2px solid #B8B8B8", alignSelf:"center"}}></span> */}
                </div>
              </PLineContainer>
              <PLineContainer>
                <PLineContent>
                  <PLine border={getStatusColors(2)} />
                  <PLineCircle color={getStatusColors(2)} />
                  <PLine border={getStatusColors(event?.message > 2 ? 1 : 100)} />
                </PLineContent>
                <PLineLabel color={getStatusColors(2)}>{t("global:decision", "Décision")}</PLineLabel>
                <div style={{ textAlign: "center", paddingTop: "10px" }}>
                  <PLineBar color={getStatusColors(event?.message == 2 ? 2 : 100)} />
                  <PLineLabel color={getStatusColors(event?.message == 2 ? 2 : 100)}>{t("global:refusedRequest", "Demande refusée")}</PLineLabel>
                  {/* <span style={{borderLeft: "2px solid #B8B8B8", alignSelf:"center"}}></span> */}
                </div>
              </PLineContainer>
              <PLineContainer >
                <PLineContent>
                  <PLine border={getStatusColors(3)} />
                  <PLineCircle color={getStatusColors(3)} />
                  <PLine border={getStatusColors(3)} />
                </PLineContent>
                <PLineLabel color={getStatusColors(3)}>{t("global:acceptedRequest", "Demande acceptée")}</PLineLabel>
              </PLineContainer>
              <PLineContainer >
                <PLineContent>
                  <PLine border={getStatusColors(3)} />
                  <PLineCircle color={getStatusColors(3)} />
                  <PLine border={getStatusColors(4)} />
                </PLineContent>
                <PLineLabel color={getStatusColors(3)}>{t("global:waitingForSignature", "Attente de signature de contrat")}</PLineLabel>
                <div style={{ textAlign: "center", paddingTop: "10px" }}>
                  {/* <PLineBar color={getStatusColors(event?.message==2 ? 2 : 100)}/>
                  <PLineLabel color={getStatusColors(event?.message==2 ? 2 : 100)}></PLineLabel> */}
                  {/* <span style={{borderLeft: "2px solid #B8B8B8", alignSelf:"center"}}></span> */}
                  {
                    event?.message == 3 &&
                    <div style={{ width: "10px !important", display: "inline-block" }}>

                      <PrimaryBtnOutlineAction onClick={(e) => setShowContractModal(true)} style={{ whiteSpace: "pre-wrap", display: "block", height: "auto", margin: "auto" }} >
                        <DriveFileRenameOutlineOutlinedIcon />
                        <p>{t("global:signOnLigne", "Signer mon contrat en ligne")}</p>
                      </PrimaryBtnOutlineAction>
                    </div>
                  }
                </div>
              </PLineContainer>
              <PLineContainer>
                <PLineContent>
                  <PLine border={getStatusColors(4)} />
                  <PLineCircle color={getStatusColors(4)} />
                  <PLineHiden />
                </PLineContent>
                <PLineLabel color={getStatusColors(4)}>{t("global:referenced", "Référencé")}</PLineLabel>
              </PLineContainer>

            </div>

            <div style={{ marginTop: "33px" }}>
              <h4 className="colorSpec">{t("global:referenceRequestInfo", "Information sur la demande de référencement")} N°REF{entity?.id}</h4>
              <PLineRequestInf>

                <div style={{ width: "25%" }}>
                  <FormGroup>
                    <FormLabelInf htmlFor="docs" >{t("global:socity", "Société :")}</FormLabelInf>
                    <FormLabelValues htmlFor="docs" >{entity?.name}</FormLabelValues>
                  </FormGroup>
                  <FormGroup>
                    <FormLabelInf htmlFor="docs" >{t("global:siret", "Siret :")}</FormLabelInf>
                    <FormLabelValues htmlFor="docs" >{entity?.siret}</FormLabelValues>
                  </FormGroup>
                  <FormGroup>
                    <FormLabelInf htmlFor="docs" >{t("global:siren", "Siren :")}</FormLabelInf>
                    <FormLabelValues htmlFor="docs" >{entity?.siren}</FormLabelValues>
                  </FormGroup>
                  <FormGroup>
                    <FormLabelInf htmlFor="docs" >{t("global:vat", "TVA :")}</FormLabelInf>
                    <FormLabelValues htmlFor="docs" >{entity?.vat}</FormLabelValues>
                  </FormGroup>
                </div>
                <div style={{ width: "35%" }}>
                  <FormGroup>
                    <FormLabelInf htmlFor="docs" style={{ width: "245px" }}>{t("global:requestDate", "Date de la demande :")}</FormLabelInf>
                    <FormLabelValues htmlFor="docs" >{formatDate(+entity?.creationDate)}</FormLabelValues>
                  </FormGroup>
                  <FormGroup >
                    <FormLabelInf htmlFor="docs" style={{ width: "245px" }}>{t("global:commentOwner", "Commentaire de Rotiflex")}</FormLabelInf>
                    {/* <FormLabelValues htmlFor="docs" >Nom</FormLabelValues> */}
                  </FormGroup>
                  {/* <textarea rows="3" style={{width: "-webkit-fill-available"}}/> */}
                  <p className="ownerComment">
                    {comment?.message}
                  </p>
                </div>

                <div style={{ width: "33%" }}>
                  <div style={{ height: "40px" }}></div>
                  {/* <span style={{color:"#809FB8"}}><AlternateEmailIcon style={{marginRight:"20px",fontSize: "47px"}}/>inforeferencement@icdint.fr</span> */}
                  <div style={{ borderLeft: "3px solid #809FB8", paddingLeft: "15px" }}>
                    <div style={{ color: "#809FB8" }}><WifiCalling3Icon style={{ marginRight: "20px", fontSize: "47px" }} />0643905655</div>
                    <div style={{ color: "#809FB8" }}><AlternateEmailIcon style={{ marginRight: "20px", fontSize: "47px" }} />inforeferencement@icdint.fr</div>

                  </div>
                </div>
              </PLineRequestInf>
            </div>

            <ContractSignature
              show={showContractModal}
              handleClose={() => setShowContractModal(false)}
              token={token}
            // addRecipientsHandler={addRecipientsHandler}
            />

          </div>
        </div>
      </div>
    </div>
  )
}

export default NewSupplierAccountProgress