import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FilterContainer, InputFilter, Option, SelectFilter, TD } from '../../../styles/Table.styled';

function TableFilters(props) {
    const { t } = useTranslation();
    const { filters, filtersChange, applyFilters, userType } = props;

    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        applyFilters(newFilters);
    }

    return (
        <>
            <tr style={{ display: props.show ? 'table-row' : 'none' }}>
                <TD ></TD>
                <TD >
                    <FilterContainer>
                        <InputFilter
                            placeholder={t("global:firstName", "Prénom")}
                            onChange={(e) => inputChangeHandler(e)}
                            value={filters?.first_name || ''}
                            name="first_name"
                            type="text"
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD >
                    <FilterContainer>
                        <InputFilter
                            placeholder={t("global:lastName", "Nom")}
                            onChange={(e) => inputChangeHandler(e)}
                            value={filters?.last_name || ''}
                            name="last_name"
                            type="text"
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD >
                    <FilterContainer>
                        <InputFilter
                            placeholder={t("invoiceList:instance", "Instance")}
                            onChange={(e) => inputChangeHandler(e)}
                            value={filters?.instance || ''}
                            name="instance"
                            type="text"
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD >
                    <FilterContainer>
                        <InputFilter
                            placeholder={t("invoiceList:entity", "Entité")}
                            onChange={(e) => inputChangeHandler(e)}
                            value={filters?.entity || ''}
                            name="entity"
                            type="text"
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD >
                    <FilterContainer>
                        <InputFilter
                            placeholder={t("supplier:groupe", "Groupe")}
                            onChange={(e) => inputChangeHandler(e)}
                            value={filters?.groupe || ''}
                            name="Groupe"
                            type="text"
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD >
                    <FilterContainer>
                        <InputFilter
                            placeholder={t("user:role", "Rôle")}
                            onChange={(e) => inputChangeHandler(e)}
                            value={filters?.level || ''}
                            name={t("user:role", "Rôle")}
                            type="text"
                            autoComplete="off" />
                    </FilterContainer>
                </TD>

            </tr>
            <tr></tr>
        </>
    )
}

export default TableFilters
