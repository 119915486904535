import { updateObject } from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    data: [],
    loading: false,
    error: null,
    count: 0,
    currentPage: 1,
    pageSize: 100,
    filtering: false,
    showFilters: false,
    filtersQuery: null,
    sortQuery: 'name',
    reverse: { name: false },
    searchInput: ''
}

const tasksStart = (state, action) => updateObject(state, {
    error: null,
    loading: true
})

const tasksSuccess = (state, action) => updateObject(state, {
    data: action.data,
    count: action.count ? action.count : 0,
    error: null,
    loading: false
})

const tasksFail = (state, action) => updateObject(state, {
    data: [],
    error: action.error,
    loading: false,
    count: 0,
    currentPage: 1
})

const tasksSetPage = (state, action) => updateObject(state, {
    currentPage: action.currentPage
})

const tasksSetPageSize = (state, action) => updateObject(state, {
    pageSize: action.pageSize
})

const tasksSetSearchInput = (state, action) => updateObject(state, {
    searchInput: action.searchInput
})

const tasksSetSortQuery = (state, action) => updateObject(state, {
    sortQuery: action.sortQuery
})

const tasksSetReverseSort = (state, action) => {
    return {
        ...state,
        reverse: { ...action.reverse }
    }
}

const tasksUpdateShowFilters = (state, action) => {
    return updateObject(state, { showFilters: action.showFilters })
}

const tasksSetFiltersQuery = (state, action) => updateObject(state, {
    filtersQuery: {
        ...action.filters
    },
    filtering: action.filtering
}
)

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TASKS_START: return tasksStart(state, action)
        case actionTypes.TASKS_SUCCESS: return tasksSuccess(state, action)
        case actionTypes.TASKS_FAIL: return tasksFail(state, action)
        case actionTypes.TASKS_SET_PAGE: return tasksSetPage(state, action)
        case actionTypes.TASKS_SET_PAGESIZE: return tasksSetPageSize(state, action)
        case actionTypes.TASKS_SEARCH_INPUT: return tasksSetSearchInput(state, action)
        case actionTypes.TASKS_SET_FILTERSQUERY: return tasksSetFiltersQuery(state, action)
        case actionTypes.TASKS_SET_SORTQUERY: return tasksSetSortQuery(state, action)
        case actionTypes.TASKS_SET_REVERSESORT: return tasksSetReverseSort(state, action)
        case actionTypes.TASKS_UPDATE_SHOWFILTERS: return tasksUpdateShowFilters(state, action)
        default:
            return state
    }
}
export default reducer