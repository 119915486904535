import React, { useEffect, useState } from 'react';
import { Notyf } from 'notyf';
import Axios from '../../axios-proas'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { formatDate, formatNumber, getNotyfObject } from '../../shared/utility';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction } from '../../styles/Common';
import { IDBody, IDContainer, IDHeader, IDHeaderActions, IDHeaderContainer, IDHeaderTitle, IDHeaderInvoiceDate, IDHeaderWrapper, IDInvoiceDate, IDInvoiceDateLabel, IDInvoiceDateValue, IDProgressLineWrapper } from './InvoiceIntegratedDetail.styled';
import IDProgressLine from '../../components/InvoiceDetail/IDProgressLine/IDProgressLine';
import StatusModal from '../../components/InvoiceDetail/IDProgressLine/StatusModalInvoiceToValidate/StatusModalInvoiceToValidate';
import InvoiceView from '../../components/InvoiceDetail/InvoiceBody/InvoiceMain/InvoiceView/InvoiceView';
import InvoiceSide from '../../components/InvoiceDetail/InvoiceBody/InvoiceSide/InvoiceSide';

function InvoiceIntegratedDetail(props) {
    const {
        loadingData,
        getInvoiceDataByToken,
        invoiceData,
        invoiceError,
        getInvoiceData,
        resetAdmToInitialState,
        userType
    } = props
    const theme = useTheme()
    let { uid, token } = useParams();
    const notyf = getNotyfObject();
    const { t } = useTranslation();
    let location = useLocation();
    let navigate = useNavigate()
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [navTabsList, setNavTabsList] = useState([])

    const typeInvoiceToNavListe = (xml, edi, pdf, chorus) => {
        var list = [];
        if (pdf !== null)
            list = ([...list,
            { label: 'PDF', value: 'pdf' }
            ])
        if (xml !== null)
            list = ([...list,
            { label: 'XML', value: 'xml' }
            ])
        if (edi !== null)
            list = ([...list,
            { label: 'EDI', value: 'edi' }
            ])
        if (chorus !== null)
            list = ([...list,
            { label: 'CHORUS', value: 'chorus' }
            ])

        setNavTabsList(list)
    }



    useEffect(() => {
        typeInvoiceToNavListe(invoiceData?.xmlFile, invoiceData?.ediFile, invoiceData?.docFile, invoiceData?.chorusFile)


    }, [invoiceData?.xmlFile, invoiceData?.ediFile, invoiceData?.docFile, invoiceData?.chorusFile])


    const [statusList, setStatusList] = useState([]);
    useEffect(() => {
        let statusListTmp = [];
        statusListTmp = [
            { label: "Accepter le bon à payer", value: "TO_BE_PAID" },
            { label: "Refuser le bon à payer", value: "LITIGATION" },

        ];

        setStatusList(statusListTmp)
    }, [])



    useEffect(() => {
        if (token) {
            getInvoiceDataByToken(token)
        } else {
            getInvoiceData(uid)
        }
        return () => {
            resetAdmToInitialState()
        }


    }, [uid, token])

    const editStatus = (origin, status, comment, reason) => {
        Axios.put('/invoice//updateStatus',
            {
                "InvoiceId": uid,
                "status": status,
                reason,
                comment,
                origin
            }).then(response => {
                getInvoiceData(uid)
                notyf.success(t("global:success", "Statut modifié"))
                navigate(`/`)
            }).catch(err => {
                notyf.error(t('global:error', "Une erreur s'est produite"))
            })
    }

    let mandatJsx = null;

    // if(error) {
    //     notyf.error(t("mandat:generatedErrorMessage"));
    // }

    if (loadingData) mandatJsx = (<Spinner />)

    else if (invoiceData)
        mandatJsx = (
            <>
                <IDHeaderWrapper>
                    <IDHeaderContainer>
                        <IDHeader>
                            <IDHeaderTitle>
                                Facture : {invoiceData?.number}&nbsp;
                                ({invoiceData?.supplierName} - {invoiceData?.clientName})
                            </IDHeaderTitle>
                            {
                                invoiceData?.status !== "ERROR" &&
                                <>
                                    <IDHeaderInvoiceDate>
                                        {formatNumber(invoiceData?.inclusiveTaxAmount)} TTC
                                    </IDHeaderInvoiceDate>
                                    <IDInvoiceDate>
                                        <IDInvoiceDateLabel>Date de facturation : </IDInvoiceDateLabel>
                                        <IDInvoiceDateValue>{formatDate(+invoiceData?.issuingDate)}</IDInvoiceDateValue>
                                    </IDInvoiceDate>
                                </>
                            }
                        </IDHeader>
                        <IDProgressLineWrapper>
                            <IDProgressLine
                                invoiceIntegratedDetail={true}
                                error={+invoiceData?.error}
                                status={invoiceData?.status}
                                // editStatus={editStatus}
                                entityFilter={location?.state?.entityFilter}
                                accessToken={token}
                            />
                        </IDProgressLineWrapper>
                    </IDHeaderContainer>
                    {
                        !token &&
                        <IDHeaderActions>
                            <SecondaryBtnOutlineAction
                                color={"#2174B9"}
                                border={"#2174B9"}
                                hov={"#F1F4F9"}
                                onClick={() => setShowStatusModal(true)}
                            >
                                Actions
                            </SecondaryBtnOutlineAction>
                            <SecondaryBtnOutlineAction
                                color={theme.colors.secondary}
                                border={theme.colors.gray}
                                onClick={() => navigate(-1)}
                            >
                                Retour
                            </SecondaryBtnOutlineAction>

                        </IDHeaderActions>
                    }
                </IDHeaderWrapper>

                <IDBody accessToken={token}>
                    <InvoiceView invoiceData={invoiceData} navTabsList={navTabsList} />
                    {
                        !token ?
                            <InvoiceSide invoiceIntegratedDetail={true} />
                            : null
                    }
                </IDBody>
                {
                    showStatusModal ? (
                        <StatusModal
                            show={showStatusModal}
                            modalClosed={() => setShowStatusModal(false)}
                            statusVal={invoiceData?.status}
                            statusList={statusList}
                            editStatus={editStatus}
                        />
                    ) : null
                }
            </>
        )

    return (
        <>
            <IDContainer>
                {mandatJsx}
            </IDContainer>
        </>
    )
}
const mapStateToProps = (state) => ({
    loadingData: state.invoiceDetail.loadingData,
    invoiceData: state.invoiceDetail.invoiceData,
    invoiceError: state.invoiceDetail.invoiceError,
    userType: state.auth.userType,
    // role: state.auth.role,
    // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
    // creatorName : state.mandat?.admData?.creatorName
})
const mapDispatchToProps = dispatch => ({
    getInvoiceData: (invoiceUid) => dispatch(actions.getInvoiceData(invoiceUid)),
    getInvoiceDataByToken: (invoiceToken) => dispatch(actions.getInvoiceDataByToken(invoiceToken)),
    // setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
    // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
    resetAdmToInitialState: () => dispatch(actions.resetAdmToInitialState())
})
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceIntegratedDetail)
