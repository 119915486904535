import React, { useEffect, useState } from 'react'
import Axios from '../../axios-proas'
import { Header, HeaderActions, HeaderInfos, HeaderLocation } from '../../styles/Common'
import { HomeBody, HomeCards, HomeContainer, HomeHeader } from '../Home/Home.styled'
import Card from '../../components/Home/Card/Card'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { getNotyfObject } from '../../shared/utility'
import { KSBody, KSCards, KSContainer, KSStats } from './KPISupplier.styled'
import LineChart from '../../components/Home/LineChart/LineChart'
import Events from '../../components/Home/Events/Events'
import { SupplierLink } from '../ReferentielFournisseur/ReferentielFournisseur.Styled'
import { useTheme } from 'styled-components'
import { connect } from 'react-redux'

function KPISupplier({ userType }) {
    const notyf = getNotyfObject();
    const theme = useTheme();
    const [activeSuppliers, setActiveSuppliers] = useState(0);
    const [inProgressSuppliers, setInProgressSuppliers] = useState(0);
    const [notPaidInvoices, setNotPaidInvoices] = useState(0);
    const [litigationInvoices, setLitigationInvoices] = useState(0);

    const eventsList = [
        { color: theme.colors.success, value: 'Fournisseur 1', date: '5 minuites' },
        { color: theme.colors.warning, value: 'Fournisseur 2', date: '5 minuites' },
        { color: theme.colors.success, value: 'Fournisseur 3', date: '5 minuites' },
        { color: theme.colors.warning, value: 'Fournisseur 4', date: '5 minuites' },
        { color: theme.colors.success, value: 'Campagne 1', date: '5 minuites' },
        { color: theme.colors.danger, value: 'Transdev', date: '5 minuites' },
        { color: theme.colors.success, value: 'Transdev', date: '5 minuites' },
    ]

    const getSuppliersCount = (status) => {
        Axios.get('/entity//suppliersCount', {
            params: {
                status: status
            }
        }).then(res => {
            if (res?.data?.suppliersCount) {
                switch (status) {
                    case 'active':
                        setActiveSuppliers(res.data.suppliersCount)
                        break;
                    case 'inProgress':
                        setInProgressSuppliers(res.data.suppliersCount)
                        break;
                    default:
                        break;
                }
            }
        }).catch(res => {
            notyf.error(t('global:error', "Une erreur s'est produite"));
        })
    }

    const getNotPaidInvoices = () => {
        Axios.get('/invoice//notPaidInvoices').then(res => {
            if (res?.data?.count) {
                setNotPaidInvoices(res.data.count)
            }
        }).catch(res => {
            notyf.error(t('global:error', "Une erreur s'est produite"));
        })
    }

    const getLitigationInvoices = () => {
        Axios.get('/invoice//litigationInvoices').then(res => {
            if (res?.data?.count) {
                setLitigationInvoices(res.data.count)
            }
        }).catch(res => {
            notyf.error(t('global:error', "Une erreur s'est produite"));
        })
    }

    useEffect(() => {
        getSuppliersCount("active");
        getSuppliersCount("inProgress");
        getNotPaidInvoices()
        getLitigationInvoices()
    }, []);

    return (
        <KSContainer>
            <Header>
                <HeaderInfos>
                    <HeaderLocation>KPI</HeaderLocation>
                </HeaderInfos>
                <HeaderActions>
                </HeaderActions>
            </Header>
            <KSBody>
                <KSCards>
                    <Card
                        icon={<ShoppingCartIcon style={{ color: "#21174B9" }} />}
                        title="Nombre de fournisseurs actifs"
                        value={<SupplierLink
                            to="/referentielFournisseur"
                            state={{
                                filtersProps: {
                                    status: 'ACTIVE',
                                    type: 'supplier'
                                }
                            }}
                        >{activeSuppliers}</SupplierLink>}
                    />
                    <Card
                        icon={<CreditCardIcon style={{ color: "#21174B9" }} />}
                        title="Fournisseurs en cours d’embarquement "
                        value={<SupplierLink
                            to="/referentielFournisseur"
                            state={{
                                filtersProps: {
                                    status: 'INPROGRESS',
                                    type: 'supplier'
                                }
                            }}
                        >{inProgressSuppliers}</SupplierLink>}
                    />
                    <Card
                        icon={<ReportProblemIcon style={{ color: "#F9B959" }} />}
                        title="Factures non payées"
                        value={notPaidInvoices}
                    />
                    <Card
                        icon={<ReportProblemIcon style={{ color: "#F9B959" }} />}
                        title="Anomalies"
                        value={litigationInvoices}
                    />
                </KSCards>
                <KSStats>
                    <LineChart />
                    <Events events={eventsList} />
                </KSStats>
            </KSBody>
        </KSContainer>
    )
}

const mapStateToProps = (state) => ({
    userType: state.auth.userType,
    login: state.auth.login
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(KPISupplier)