import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PdfViewer from "./PdfViewer/PdfViewer";
import * as actions from "../../../../../store/actions/index";
import { useParams } from "react-router-dom";
import { getNotyfObject } from "../../../../../shared/utility";
import XMLViewer from "react-xml-viewer";
import {
  IVContainer,
  EntHeaderWrapper,
  XmlDiv,
  IVWrapper,
  IVDownloadContainer,
  IVDownloadIcon,
} from "./InvoiceView.styled";
import {
  NavTabsLi,
  NavTabsLink,
  NavTabsUl,
} from "../../../../../containers/Entity/Entity.styled";
import EdiViewer from "../EdiViewer/EdiViewer";
import DownloadIcon from "@mui/icons-material/Download";
function InvoiceView({
  invoiceData,
  navTabsList,
  loadingPdf,
  commandLine,
  pdfFile,
  ediFileName,
  xmlFile,
  ediFile,
  chorusFile,
  chorusFileName,
  pdfFileName,
  mimeType,
  xmlFileName,
  getInvoicePdf,
  getInvoicePdfByToken,
  isCommand,
  isModal,
}) {
  // console.log(pdfFile)
  const [tab, setTab] = useState("pdf");

  let { uid, token } = useParams();
  const notyf = getNotyfObject();
  const customTheme = {
    attributeKeyColor: "#FF0000",
    attributeValueColor: "#000FF",
    overflowBreak: true,
  };

  // const changeTab = (tab) => {
  //   setTab(tab);
  // };

  // const downloadClickHandler = (tab) => {
  //   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //     // IE workaround
  //     if (tab == "xml") {
  //       var byteCharacters = atob(xmlFile);
  //     } else {
  //       var byteCharacters = atob(ediFile);
  //     }

  //     let byteNumbers = new Array(byteCharacters.length);
  //     for (var i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }
  //     let byteArray = new Uint8Array(byteNumbers);
  //     let blob = new Blob([byteArray], { type: "text/plain" });
  //     window.navigator.msSaveOrOpenBlob(
  //       blob,
  //       tab == "xml" ? xmlFileName : ediFileName || "facture.xml"
  //     );
  //   } else {
  //     // much easier if not IE
  //     //________Direct Download_____
  //     var blob = null;
  //     switch (tab) {
  //       case "xml":
  //         blob = new Blob([xmlFile], { type: "text/plain" });
  //         break;
  //       case "edi":
  //         blob = new Blob([ediFile], { type: "" });
  //         break;
  //       case "chorus":
  //         blob = new Blob([chorusFile], { type: "text/plain" });
  //         break;
  //       default:
  //         break;
  //     }

  //     //const downloadUrl = URL.createObjectURL(blob)
  //     let a = document.createElement("a");
  //     //a.setAttribute('href', window.URL.createObjectURL(blob));
  //     a.href = window.URL.createObjectURL(blob);
  //     a.download =
  //       tab == "xml"
  //         ? xmlFileName
  //         : tab == "edi"
  //         ? ediFileName
  //         : chorusFileName || "facture.xml";
  //     a.dataset.downloadurl = ["", a.download, a.href].join(":");
  //     document.body.appendChild(a);
  //     a.click();

  //     //__________Visualize In The Browser _____
  //     // const blob = dataURItoBlob(data);
  //     // const url = URL.createObjectURL(blob);

  //     // // to open the PDF in a new window
  //     // window.open(url, '_blank');
  //   }
  // };

  useEffect(() => {
    if (token) getInvoicePdfByToken(token);
    else getInvoicePdf(uid, tab);
  }, [uid, token, tab]);

  let admView = null;

  // if(error) {
  //     notyf.error(t("mandat:generatedErrorMessage"));
  // }
  if (loadingPdf) admView = <spinner />;
  else {
    if (invoiceData?.pdfFile)
      admView = (
        <>
          {tab == "pdf" ? (
            <PdfViewer
              commandLine={commandLine}
              pdfFile={invoiceData?.pdfFile}
              pdfFileName={invoiceData?.docFile}
            />
          ) : xmlFile && tab == "xml" ? (
            <XmlDiv>
              <XMLViewer xml={xmlFile} theme={customTheme} />
            </XmlDiv>
          ) : ediFile && tab == "edi" ? (
            <EdiViewer ediFile={ediFile} ediFileName={ediFileName} />
          ) : chorusFile && tab == "chorus" ? (
            <XMLViewer xml={chorusFile} theme={customTheme} />
          ) : null}
        </>
      );
    else
      admView = (
        <>
          <div>
            {" "}
            {tab == "pdf" ? (
              <h5> </h5>//Cette facture ne contient pas de PDF
            ) : tab == "xml" ? (
              <h5>Cette facture ne contient pas de XML </h5>
            ) : tab == "edi" ? (
              <h5>Cette facture ne contient pas de EDI </h5>
            ) : (
              <h5>Cette facture ne contient pas de CHORUS </h5>
            )}
          </div>
        </>
      );
  }

  return (
    <IVWrapper isCommand={isCommand} isModal={isModal}>
      {/* <EntHeaderWrapper className="row">
        <NavTabsUl>
          {navTabsList.map((t, index) => (
            <NavTabsLi>
              <NavTabsLink
                active={tab === t.value}
                onClick={() => changeTab(t.value)}
                to={`#`}
              >
                {t.label}
              </NavTabsLink>
            </NavTabsLi>
          ))}
        </NavTabsUl>
      </EntHeaderWrapper>
      {(tab == "xml" || tab == "edi" || tab == "chorus") && (
          <IVDownloadContainer>
              <IVDownloadIcon
                onClick={() => downloadClickHandler(tab)}
                >
                    <DownloadIcon />
                </IVDownloadIcon>
          </IVDownloadContainer>
      )} */}
      <IVContainer style={{ width: "100%" }} accessToken={token}>
        {admView}
      </IVContainer>
    </IVWrapper>
  );
}
const mapStateToProps = (state) => ({
  loadingPdf: state.invoiceDetail.loadingPdf,
  ediFile: state.invoiceDetail.pdfFile?.ediFile || null,
  pdfFile: state.invoiceDetail.pdfFile?.pdfFile || null,
  xmlFile: state.invoiceDetail.pdfFile?.xmlFile || null,
  errorPdf: state.invoiceDetail.errorPdf,
  pdfFileName: state.invoiceDetail?.pdfFile?.docFile || "facture.pdf",
  xmlFileName: state.invoiceDetail?.pdfFile?.docXml || "facture.xml",
  ediFileName: state.invoiceDetail?.pdfFile?.docEdi || "facture.EDI",
  mimeType: state.invoiceDetail?.pdfFile?.mimeType,
  chorusFile: state.invoiceDetail.pdfFile?.chorusFile || null,
  chorusFileName: state.invoiceDetail?.pdfFile?.docChorus || "facture.xml",
});
const mapDispatchToProps = (dispatch) => ({
  getInvoicePdf: (invoiceUid, type) =>
    dispatch(actions.getInvoicePdf(invoiceUid, type)),
  getInvoicePdfByToken: (invoiceToken) =>
    dispatch(actions.getInvoicePdfByToken(invoiceToken)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceView);
