import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import { Notyf } from 'notyf';
import { getNotyfObject } from '../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import Axios from '../../../../../axios-proas'
import Spinner from 'react-bootstrap/Spinner'
import MessageEvent from './TimelineCards/MessageEvent/MessageEvent';
import StatusEvent from './TimelineCards/StatusEvent/StatusEvent';
import { TLContainer } from './Timeline.styled';

function TimeLine(props) {
    const {
        userUid,
        userType,
        entityFilter
    } = props
    let { uid } = useParams();
    const notyf = getNotyfObject();
    const { t } = useTranslation();
    const [timelineData, setTimelineData] = useState(null);
    const [loading, setLoading] = useState(false)
    const getTimelineData = () => {
        setLoading(true)
        Axios.get("invoice/" + uid + "/invoiceActions", {
            // params: {
            //     invoice: uid
            // }
        }).then(response => {
            let item = { ...response?.data?.events.filter(event => event?.snapshot == "PAID")[0], snapshot: "TRANSMITED_PAYMENT", creationDate: +response?.data?.events.filter(event => event?.snapshot == "PAID")[0]?.creationDate - 60000 }
            setLoading(false)
            console.log(item)
            if (userType === "supplier" && response?.data?.events?.length > 0) {
                let temp = [...response?.data?.events.filter(event => !"VALIDATED,CONTROLLED,TO_BE_VALIDATED,TO_BE_CONTROLLED".includes(event?.snapshot)), item]
                setTimelineData(temp.sort(function (a, b) {
                    return (+b.creationDate) - (+a.creationDate);
                }))
            } else
                setTimelineData(response?.data?.events)
        }).catch(response => {
            setLoading(false)
            notyf.error(t("mandat:generatedErrorMessage", "une erreur s'est produite"));
        })
    }

    useEffect(() => {
        getTimelineData()
    }, [uid])

    let timelineJsx = null;

    if (loading)
        timelineJsx = (<Spinner />)
    else if (timelineData && timelineData?.length > 0) {
        timelineJsx = timelineData?.map((timelineEvent, index) => {
            var eventJsx = null;
            let isLast = index === 0
            let isCreator = userUid === timelineEvent?.user?.userUid;
            switch (timelineEvent?.type) {
                case "STEP_STATUS":
                    // if(userType === 'client'){
                    //     if(['IMPORTED', 'CONTROLLED'].includes(timelineEvent?.snapshot))
                    //         break;
                    // }
                    eventJsx = (<StatusEvent
                        entityFilter={entityFilter}
                        userType={userType}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "RELANCE":
                    eventJsx = (<StatusEvent
                        entityFilter={entityFilter}
                        eventData={timelineEvent}
                        isLast={isLast}
                        isRelance={true}
                    />)
                    break;
                case "USER_CHAT_EVENT":
                    eventJsx = timelineEvent.origin == "ACTION" ?
                        (<MessageEvent
                            entityFilter={entityFilter}
                            eventData={timelineEvent}
                            isLast={isLast}
                        />) : null

                default:
                    break;
            }
            return eventJsx
        })
    }

    return (
        <TLContainer>
            {timelineJsx}
        </TLContainer>
    )
}
const mapStateToProps = (state) => ({
    userUid: state.auth.userUid,
    userType: state.auth.userType,
    // loading: state.mandat.loadingTimeline,
    // timelineData: state.mandat.timelineData,
    // error: state.mandat.errorTimeline,
    // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid
})
const mapDispatchToProps = dispatch => ({
    // getMandatTimelineEvents: (mandatUid) => dispatch(actions.getMandatTimelineEvents(mandatUid)),
    // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status))
})
export default connect(mapStateToProps, mapDispatchToProps)(TimeLine)