import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Notyf } from "notyf";
import { getNotyfObject } from "../../../shared/utility";
import _ from "lodash";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import StatusModal from "./StatusModal/StatusModal";
import {
  IDProgressLineContainer,
  IDProgressLineItem,
  IDProgressLineLabel,
  PLAction,
} from "./IDProgressLine.styled";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import { useTheme } from "styled-components";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { CTooltip } from "../../../components/UI/CTooltip/CTooltip";

function IDProgressLine(props) {
  const {
    lastEvent,
    userType,
    entityFilter,
    status,
    editStatus,
    accessToken,
    error,
  } = props;
  const { t } = useTranslation();
  let notyf = getNotyfObject();
  const theme = useTheme();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [statusList, setStatusList] = useState([]);
  // const statusList = useMemo(() => [
  //     {label: status === 'ERROR' ? 'Erreur' : "Importée", value: status === 'ERROR' ? 'ERROR' : "IMPORTED"},
  //     {label: "Contrôlée", value: "CONTROLLED"},
  //     {label: "Intégrée", value: "INTEGRATED"},
  //     {label: "À payer", value: "TO_BE_PAID"},
  //     {label: "Payée", value: "PAID"}
  // ], [status])
  const [infoStatus, setInfoStatus] = useState("");
  const dangerStatus = useMemo(
    () => ["ERROR", "REJECTED", "REFUSED", "LITIGATION"],
    []
  );

  useEffect(() => {
    let statusListTmp = [
      { label: t("supplier:savedRequest", "Demande enregistrée"), value: "Demande enregistrée" },
      { label: t("invoiceStatus:TO_BE_CONTROLLED", "A contrôler"), value: "A contrôler" },
      { label: t("invoiceStatus:CONTROLLED", "Contrôlée"), value: "Contrôlée" },
      { label: t("supplier:requestAccepted", "Demande acceptée"), value: "Demande acceptée" },
      {
        label: t("supplier:waitingForSIgnature", "Attente signature contrat"),
        value: "Attente signature contrat",
      },
      { label: t("supplier:signedContract", "Contrat signé"), value: "Contrat signé" },
      { label: t("supplier:referenced", "Référencé"), value: "Référencé" },
    ];
    switch (status) {
      case "ERROR":
        setInfoStatus("NEW");
        break;
      case "REJECTED":
        setInfoStatus("CONTROLLED");
        break;
      case "REFUSED":
      case "LITIGATION":
        setInfoStatus("INTEGRATED");
        break;
      default:
        setInfoStatus(status);
        break;
    }
    setStatusList(statusListTmp);
  }, [status]);
  let actionButton = null;

  const getProgressLineIcon = (value, index) => {
    let statusIndex = null;
    if (lastEvent?.message == 0) statusIndex = 0;
    if (lastEvent?.message == 1) statusIndex = 1;
    if (lastEvent?.message == 2) statusIndex = 1;
    if (lastEvent?.message == 3) statusIndex = 3;
    if (lastEvent?.message == 4) statusIndex = 6;

    if (index <= statusIndex)
      return <CheckCircleOutlineIcon style={{ fontSize: "1.25rem" }} />;
    else return <PanoramaFishEyeIcon style={{ fontSize: "1.25rem" }} />;
  };

  const getStatusColors = (value, index) => {
    let statusIndex = null;
    if (lastEvent?.message == 0) statusIndex = 0;
    if (lastEvent?.message == 1) statusIndex = 1;
    if (lastEvent?.message == 2) statusIndex = 1;
    if (lastEvent?.message == 3) statusIndex = 3;
    if (lastEvent?.message == 4) statusIndex = 6;
    if (index <= statusIndex)
      return [theme.colors.secondary, " solid " + theme.colors.secondary];
    if (index === statusIndex + 1)
      return [theme.colors.secondary, " dashed " + theme.colors.secondary];
    return [theme.colors.secondary, " solid " + theme.colors.gray];
  };

  // if((userType === "client" || userType === "owner") && !accessToken && !['ERROR', 'REJECTED', 'REFUSED'].includes(status) ) {
  //     actionButton = (
  //         <PLAction onClick={() => setShowStatusModal(true)} >
  //             <span>
  //                 {"(Modifier le statut)"}
  //             </span>
  //             <EditIcon style={{fontSize: "1.25rem"}}/>
  //         </PLAction>
  //     )
  // }

  return (
    <IDProgressLineContainer>
      {statusList.map((item, index) => (
        <IDProgressLineItem
          color={getStatusColors(item.value, index)[0]}
          border={getStatusColors(item.value, index)[1]}
        >
          <IDProgressLineLabel>{item.label}</IDProgressLineLabel>
          {/* <CheckCircleOutlineIcon style={{fontSize: "1.25rem"}} />
                        <PanoramaFishEyeIcon /> */}
          {getProgressLineIcon(item?.value, index)}
        </IDProgressLineItem>
      ))}
      {lastEvent?.message == 2 ? (
        <CTooltip title={status}>
          <span style={{ color: "#EE5A5A", padding: "0px 17px" }}>
            <WarningAmberIcon
              style={{ fontSize: "30px", margin: "0px 5px 0px 5px" }}
            />
            <small style={{ fontSize: "small" }}>Refus</small>
          </span>
        </CTooltip>
      ) : null}
      {actionButton}
      {showStatusModal ? (
        <StatusModal
          show={showStatusModal}
          modalClosed={() => setShowStatusModal(false)}
          statusVal={status}
          statusList={statusList}
          editStatus={editStatus}
        />
      ) : null}
    </IDProgressLineContainer>
  );
}

const mapStateToProps = (state) => ({
  userType: state.auth.userType,
  // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
  // mandatUid: state.mandat.admData.uid,
  // status : state.mandat?.admData?.status,
  // connectedUser : state.mandat?.admData?.connectedUser,
  // pdfFile: state.mandat.admData?.pdfFile || null,
  // admData: state.mandat.admData,
  // admUpdate: state.mandat.admUpdate
});
const mapDispatchToProps = (dispatch) => ({
  // getMandatData: (mandatUid) => dispatch(actions.getMandatData(mandatUid)),
  // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
  // createMandatTimelineEvent: (mandatUid, eventData) => dispatch(actions.createMandatTimelineEvent(mandatUid, eventData)),
  // setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive))
});
export default connect(mapStateToProps, mapDispatchToProps)(IDProgressLine);
