import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FilterContainer, InputFilter, Option, SelectFilter, TD } from '../../../styles/Table.styled';
import DateFilter from '../../UI/DateFilter/DateFilter';

function TableFilters(props) {
    const { t } = useTranslation();
    const { filters, filtersChange, applyFilters, userType } = props;
    const [show, setShow] = useState(null)
    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value
        }
        filtersChange(newFilters);
        applyFilters(newFilters);
    }


    const getCreationdate = () => {
        return filters?.creationDate ? convertDate(filters.creationDate) : [];
    }

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])

    const closeModalHandler = () => {
        setShow(false);
    }
    const dateShowHandler = (field) => {
        setShow({
            [field]: true
        })
        setDateField(field);
    }
    const convertDate = (date) => {
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]), formatDate(+dateArray[1])]
    }

    let [dateInputValue, setDateInputValue] = useState({
        creationDate: filters?.creationDate ? convertDate(filters.creationDate) : ''
    });

    const dateChangeHandler = (date) => {
        setDateInputValue({
            ...dateInputValue,
            [dateField]: formatDate(date.startDate) + '-' + formatDate(date.endDate)
        })
        let key = date.field;
        let newFilters = {
            ...filters,
            [key]: date.startDate.getTime() + ':' + date.endDate.getTime()
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    const dateResetHandler = (field) => {
        setDateInputValue({
            ...dateInputValue,
            [field]: ''
        })
        let newFilters = {
            ...filters,
            [field]: null
        }
        filtersChange(newFilters)
        applyFilters(newFilters);
    }

    return (
        <>
            <tr style={{ display: props.show ? 'table-row' : 'none' }}>
                {/* <TD ></TD> */}
                <TD >
                    <FilterContainer>
                        <InputFilter
                            placeholder={t("invoiceList:number", "Numéro")}
                            onChange={(e) => inputChangeHandler(e)}
                            value={filters?.first_name || ''}
                            name="first_name"
                            type="text"
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD >
                    <FilterContainer>
                        <DateFilter
                            modalTitle={t("invoiceList:creationDate", "Date de Création")}
                            from={getCreationdate()[0] || ''}
                            to={getCreationdate()[1] || ''}
                            name="creationDate"
                            activeReset={filters?.creationDate ? true : false}
                            resetInterval={dateResetHandler}
                            show={show?.creationDate}
                            showModal={dateShowHandler}
                            closeModal={closeModalHandler}
                            dateChange={(date) => dateChangeHandler(date)}
                        />
                    </FilterContainer>
                    {/* <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:telephone_nr", "Date demande")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.telephone_number || ''} 
                            name="telephone_number" 
                            type="text" 
                            autoComplete="off" />

                    </FilterContainer> */}
                </TD>
                <TD>
                    <FilterContainer>
                        <SelectFilter
                            onChange={(e) => inputChangeHandler(e)}
                            value={filters?.active || ''}
                            name="active"
                        >
                            <Option value=""></Option>
                            <Option value="1">{t('global:yes', 'Oui').toUpperCase()}</Option>
                            <Option value="0">{t('global:no', 'Non').toUpperCase()}</Option>
                        </SelectFilter>
                    </FilterContainer>
                </TD>

                <TD >
                    <FilterContainer>
                        <InputFilter
                            placeholder={t("invoiceList:number", "Numéro")}
                            onChange={(e) => inputChangeHandler(e)}
                            value={filters?.telephone_number || ''}
                            name="telephone_number"
                            type="text"
                            autoComplete="off" />
                    </FilterContainer>
                </TD>
                <TD>
                    <FilterContainer>
                        <SelectFilter
                            onChange={(e) => inputChangeHandler(e)}
                            value={filters?.level || ''}
                            name="level"
                        >
                            <Option value=""></Option>
                            <Option value="admin">{t('admin', "Admin").toUpperCase()}</Option>
                            <Option value="standard">{t('standard', "Standard").toUpperCase()}</Option>
                        </SelectFilter>
                    </FilterContainer>
                </TD>
                {/* <TD >
                    <FilterContainer>
                        <InputFilter 
                            placeholder={t("invoiceList:entities", "+")}
                            onChange={(e) => inputChangeHandler(e)} 
                            value={filters?.entities || ''} 
                            name="entities" 
                            type="text" 
                            autoComplete="off" />
                    </FilterContainer>
                </TD> */}

            </tr>
            <tr></tr>
        </>
    )
}

export default TableFilters
