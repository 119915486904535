import React, { useEffect, useState } from 'react';
import { Notyf } from 'notyf';
import Axios from '../../axios-proas'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { formatDate, formatNumber, getNotyfObject } from '../../shared/utility';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction } from '../../styles/Common';
import { IDBody, IDContainer, IDHeader, IDHeaderActions, IDHeaderContainer, IDHeaderTitle, IDHeaderInvoiceDate, IDHeaderWrapper, IDcommandeDate, IDInvoiceDate, IDInvoiceDateLabel, IDInvoiceDateValue, IDProgressLineWrapper } from './CommandeDetail.styled';
import IDProgressLine from '../../components/CommandeDetail/IDProgressLine/IDProgressLine';
import InvoiceView from '../../components/CommandeDetail/CommandeBody/InvoiceMain/InvoiceView/InvoiceView';
import CommandeSide from '../../components/CommandeDetail/CommandeBody/CommandeSide/CommandeSide';

function CommandeDetail(props) {
    const {
        loadingData,
        getInvoiceDataByToken,
        invoiceData,
        commandeData,
        invoiceError,
        getInvoiceData,
        getCommandeData,
        resetAdmToInitialState
    } = props
    const theme = useTheme()
    let { uid, uidInvoice } = useParams();
    const notyf = getNotyfObject();
    const { t } = useTranslation();
    let location = useLocation();
    let navigate = useNavigate()

    const [navTabsList, setNavTabsList] = useState([])

    const typeInvoiceToNavListe = (xml, edi, pdf, chorus) => {
        var list = [];
        if (pdf !== null)
            list = ([...list,
            { label: 'PDF', value: 'pdf' }
            ])
        if (xml !== null)
            list = ([...list,
            { label: 'XML', value: 'xml' }
            ])
        if (edi !== null)
            list = ([...list,
            { label: 'EDI', value: 'edi' }
            ])
        if (chorus !== null)
            list = ([...list,
            { label: 'CHORUS', value: 'chorus' }
            ])

        setNavTabsList(list)
    }



    useEffect(() => {
        typeInvoiceToNavListe(invoiceData?.xmlFile, invoiceData?.ediFile, invoiceData?.docFile, invoiceData?.chorusFile)


    }, [invoiceData?.xmlFile, invoiceData?.ediFile, invoiceData?.docFile, invoiceData?.chorusFile])






    useEffect(() => {
        if (uidInvoice && uidInvoice != "null") {

            getInvoiceData(uidInvoice)
        }

        if (uid) {
            getCommandeData(uid)

        }

        return () => {
            resetAdmToInitialState()
        }


    }, [uid, uidInvoice])

    const editStatus = (status, comment = null, reason = null) => {
        Axios.put('/invoice//updateStatus',
            {
                "InvoiceId": uid,
                "status": status,
                reason,
                comment
            }).then(response => {
                getInvoiceData(uid)
                notyf.success(t("global:success", "Statut modifié"))
            }).catch(err => {
                notyf.error(t('global:error', "Une erreur s'est produite"))
            })
    }

    let mandatJsx = null;

    // if(error) {
    //     notyf.error(t("mandat:generatedErrorMessage"));
    // }

    if (loadingData) mandatJsx = (<Spinner />)

    else if (true)
        mandatJsx = (
            <>
                <IDHeaderWrapper>
                    <IDHeaderContainer>
                        <IDHeader>
                            <IDHeaderTitle>
                                Commande : {commandeData?.number}&nbsp;
                                ({commandeData?.supplierName} - {commandeData?.clientName})
                            </IDHeaderTitle>
                            {
                                invoiceData?.status !== "ERROR" &&
                                <>
                                    <IDHeaderInvoiceDate>
                                        {formatNumber(commandeData?.inclusiveTaxAmount)} TTC
                                    </IDHeaderInvoiceDate>
                                    <IDcommandeDate>
                                        <IDInvoiceDateLabel>Date de commande : </IDInvoiceDateLabel>
                                        <IDInvoiceDateValue>{formatDate(+commandeData?.issuingDate)}</IDInvoiceDateValue>
                                    </IDcommandeDate>

                                    <IDInvoiceDate>
                                        <IDInvoiceDateLabel>Etat de livraison : </IDInvoiceDateLabel>
                                        <IDInvoiceDateValue>{"Livré"}</IDInvoiceDateValue>
                                    </IDInvoiceDate>
                                </>
                            }
                        </IDHeader>
                        {/* {(uidInvoice!="null")?
                        <IDProgressLineWrapper> 
                            <IDProgressLine 
                                error={+invoiceData?.error}
                                status={invoiceData?.status} 
                                editStatus={editStatus}
                                entityFilter={location?.state?.entityFilter}
                                accessToken={null}
                            />
                        </IDProgressLineWrapper>
                         :null} */}
                    </IDHeaderContainer>
                    {

                        <IDHeaderActions>
                            <SecondaryBtnOutlineAction
                                onClick={() => navigate(-1)}
                            >
                                Retour
                            </SecondaryBtnOutlineAction>
                        </IDHeaderActions>
                    }
                </IDHeaderWrapper>

                <IDBody accessToken={null}>
                    {(uid != "null") ?
                        <InvoiceView invoiceData={invoiceData} navTabsList={navTabsList} /> :
                        null
                    }

                    {

                        <CommandeSide isFull={false} />

                    }
                </IDBody>
            </>
        )

    return (
        <>
            <IDContainer>
                {mandatJsx}
            </IDContainer>
        </>
    )
}
const mapStateToProps = (state) => ({
    loadingData: state.invoiceDetail.loadingData,
    invoiceData: state.invoiceDetail.invoiceData,
    invoiceError: state.invoiceDetail.invoiceError,
    commandeData: state.commande.commandeData,
    // role: state.auth.role,
    // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
    // creatorName : state.mandat?.admData?.creatorName   
})
const mapDispatchToProps = dispatch => ({
    getInvoiceData: (invoiceUid) => dispatch(actions.getInvoiceData(invoiceUid)),
    getCommandeData: (uid) => dispatch(actions.getCommandeData(uid)),
    getInvoiceDataByToken: (invoiceToken) => dispatch(actions.getInvoiceDataByToken(invoiceToken)),
    // setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
    // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
    resetAdmToInitialState: () => dispatch(actions.resetAdmToInitialState())
})
export default connect(mapStateToProps, mapDispatchToProps)(CommandeDetail)
