import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SelectWrap, SelectContainer, SelectValue, SelectIcon, SelectOptions, SelectOption } from './DeepSelect.styled';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import "./DeepSelect.css"
import { useTranslation } from 'react-i18next';

function DeepSelect({ value, onChange, options, width, close, deleteItem }) {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation()
    const [PL, setPL] = useState()
    const [PC, setPC] = useState()
    const [PF, setPF] = useState()
    useEffect(() => {
        console.log(options)
        setPF(options.filter(item => item.family == "PF"))
        setPC(options.filter(item => item.family == "PC"))
        setPL(options.filter(item => item.family == "PL"))
    }, [options])

    const handleClick = (newValue) => {
        setIsOpen(false)
        onChange(newValue)
    }

    return (
        <SelectWrap width={width}>
            <SelectContainer width={width} onClick={() => setIsOpen(!isOpen)}>
                <SelectValue>
                    {value}
                </SelectValue>
                <SelectIcon>
                    {
                        isOpen ? <KeyboardArrowUpIcon className="ICDBlue" />
                            : <KeyboardArrowDownIcon className="ICDBlue" />
                    }
                </SelectIcon>
            </SelectContainer>
            {
                isOpen && (
                    <SelectOptions width={width}>
                        <div>
                            <p style={{ fontStyle: 'italic' }} className="selectionTitle">{t("documentList:orderProof", "Preuves de facturation")}</p>
                            <div className='selectionTitleContainer'>
                                {
                                    PF?.map((opt, index) => {
                                        return <p className={value == opt.label ? "activeFile" : ""} onClick={() => handleClick(opt.value)}>{opt.label.replace(".pdf", "")} </p>
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            <p style={{ fontStyle: 'italic' }} className="selectionTitle">{t("documentList:invoicingProof", "Preuve de commande")}</p>
                            <div className='selectionTitleContainer'>
                                {
                                    PC?.map((opt, index) => {
                                        return <p className={value == opt.label ? "activeFile" : ""} onClick={() => handleClick(opt.value)}>{opt.label.replace(".pdf", "")} </p>
                                    })
                                }
                            </div>
                        </div>
                        <div>
                            <p style={{ fontStyle: 'italic' }} className="selectionTitle">{t("documentList:deliveryProof", "Preuves de livraison")}</p>
                            <div className='selectionTitleContainer'>
                                {
                                    PL?.map((opt, index) => {
                                        return <p className={value == opt.label ? "activeFile" : ""} onClick={() => handleClick(opt.value)}>{opt.label.replace(".pdf", "")} </p>
                                    })
                                }
                            </div>
                        </div>
                    </SelectOptions>
                )
            }
        </SelectWrap>
    );
}

export default DeepSelect;
