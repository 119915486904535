import React, { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import { formatDate, getStatusWithKey, getTimeDate } from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { MessageContent, MessageFrom, MessageWrapper, TEContainer, TEContent, TEHeader, TEIcon, TEPreview, TETime, TETitle } from '../../Timeline.styled';
import { useTheme } from 'styled-components';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EastIcon from '@mui/icons-material/East';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';

function StatusEvent({ isCreator, eventData, isLast, userType }) {
    const [showContent, setShowContent] = useState(false)
    const { t } = useTranslation();
    const theme = useTheme()

    useEffect(() => {
        if (isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast])

    const getStatusColor = (value) => {
        // switch(value) {
        // case '0':
        // case '1':
        // case '3':
        // case '4':
        // return <EastIcon style={{width: "1.2rem"}}/>;
        return theme.colors.secondary;
        // default:
        //     return theme.colors.danger;
        // }
    }

    const getStatusIcon = (value) => {
        // switch(value) {
        //     case '0':
        //     case '1':
        //     case '3':
        //     case '4':
        return <EastIcon style={{ width: "1.2rem" }} />
        //     default:
        //         return <CloseIcon style={{width: "1.2rem"}}/>
        // }
    }

    const getStatusMessage = (value) => {
        switch (value) {
            case '0':
                return t("invoiceEvent:paraphOnHold", 'Parapheur en attente')
                break;
            case '2':
                return t("invoiceDetail:returnAccounting", "Parapheur renvoyé en comptabilité")
                break;
            case '1':
                return t("invoiceDetail:proposeSignature", "Proposition de signature")
                break;
            case '3':
                return t("invoiceDetail:proposeSignature", "Parapheur Archivé")
                // case '4':
                //     return "Signature demande de référencement"
                break;
        }
    }

    return (
        <>
            <TEContainer >
                <TEHeader onClick={() => setShowContent(!showContent)}>
                    <TEIcon borderBold={true} bdColor={getStatusColor(eventData?.message)} color={getStatusColor(eventData?.message)}
                        style={{ border: eventData?.snapshot === "ERROR" ? "none" : "" }}
                    >
                        {getStatusIcon(eventData?.message)}
                    </TEIcon>
                    <TEPreview>
                        <TETitle color={getStatusColor(eventData?.snapshot)}>
                            {getStatusMessage(eventData?.message)}
                            {/* {eventData?.message} */}
                        </TETitle>
                        <TETime>
                            le {formatDate(+eventData?.creationDate)} à {getTimeDate(+eventData?.creationDate)}
                        </TETime>
                    </TEPreview>
                </TEHeader>
                {
                    true && (
                        <TEContent>
                            {/* {
                                eventData?.snapshot === 'LITIGATION' && (
                                    <MessageWrapper>
                                        <MessageFrom>
                                            {t("default:reason", "Motif : ")}
                                        </MessageFrom>
                                        <MessageContent>
                                            {eventData?.message?.slice(0, 128)}
                                            {eventData?.message?.length > 128 ? '...' : null}
                                        </MessageContent>
                                    </MessageWrapper>
                                )
                            } */}
                            {
                                eventData?.comment && (
                                    <MessageWrapper>
                                        <MessageFrom>
                                            {t("default:comment", "Commentaire : ")}
                                        </MessageFrom>
                                        <MessageContent>
                                            {eventData?.comment?.slice(0, 128)}
                                            {eventData?.comment?.length > 128 ? '...' : null}
                                        </MessageContent>
                                    </MessageWrapper>
                                )
                            }
                        </TEContent>
                    )
                }
            </TEContainer>
        </>
    )
}

export default StatusEvent