import React, { useCallback, useEffect, useState } from 'react';
import TableActions from '../../components/EntitiesManager/TableActions/TableActions';
import TableHead from '../../components/EntitiesManager/TableHead/TableHead';
import TableBody from '../../components/EntitiesManager/TableBody/TableBody';
import TableFilters from '../../components/EntitiesManager/TableFilters/TableFilters';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import { NoContent, SpinnerContainer, Table, TBody } from '../../styles/Table.styled';
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import { EMBody, EMContainer, EMTableContainer } from './EntitiesManager.styled';
import { Spinner } from 'react-bootstrap';

function EntitiesManager(props) {
    const {
        loading,
        entitiesData,
        count,
        currentPage,
        pageSize,
        getEntitiesPage,
        setEntitiesPage,
        setEntitiesPageSize,
        sortQuery,
        reverse,
        filtersQuery,
        showFilters,
        userRole,
        updateShowFilters,
        entityOwnerDetail,
        setEntitydetail,
        setEntitiesFilters,
        setSortQuery,
        setReverse
    } = props;
    const { t } = useTranslation();
    const [density, setDensity] = useState("2.25rem")
    const [columns, setColumns] = useState([
        { title: t("entitiesList:entityCode"), field: 'code', show: true, alignSelf: "left" },
        { title: t("entitiesList:entityName"), field: 'name', show: true, alignSelf: "left" },
        { title: t("entitiesList:codePostal"), field: 'zipCode', show: true, alignSelf: "left" },
        { title: t("entitiesList:city"), field: 'city', show: true, alignSelf: "left" },
        { title: t("entitiesList:dematType"), field: 'active', show: true, alignSelf: "left" },
        { title: t("entitiesList:creationDate"), field: 'creationDate', show: true, alignSelf: "center" }
    ])


    useEffect(() => {

        getEntitiesPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)

    }, [currentPage, pageSize])

    const isEmptyFilters = () => {
        if (filtersQuery === null)
            return true;
        let filtersList = columns.map(column => column.field)
        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (!showFilters && !isEmpty) {
            resetFilters()
        }
    }, [showFilters]);

    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue = reverse[field] ? reverse[field] : false
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getEntitiesPage(currentPage, filtersQuery, field, newReverse, pageSize)
    }

    const handlePageChange = useCallback((newPage) => {
        setEntitiesPage(newPage);
    }, [currentPage]);

    const handleApplyFiltering = useCallback((filters) => {
        getEntitiesPage(1, filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);

    const resetFilters = () => {
        setEntitiesFilters(null);
        setEntitiesPage(1)
        getEntitiesPage(1, null, sortQuery, reverse, pageSize)
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
    ) : (
        <NoContent>
            <span>{t("clientList:noClients")}</span>
        </NoContent>
    );

    if (!loading && entitiesData?.length > 0) {
        tSpinner = null;
        tBody = (<TableBody
            columns={columns}
            clientsData={entitiesData}
            userRole={userRole}
            setEntitydetail={setEntitydetail}
            entityOwnerDetail={entityOwnerDetail}
        />)
    }

    return (
        <EMContainer>
            <TitleHelmet title={"Démo Portail | " + t("menu:entities")} />
            <Header>
                <HeaderInfos>
                    <HeaderLocation>{t("global:entitiesManagment", "Gestion des entités")}</HeaderLocation>
                </HeaderInfos>
                <HeaderActions>
                    <TableActions
                        resetFilters={resetFilters}
                        showFilters={showFilters}
                        setShowFilters={updateShowFilters}
                    />
                </HeaderActions>
            </Header>
            <EMBody>
                <EMTableContainer>
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setEntitiesPageSize}
                    />
                    <Table>
                        <TableHead
                            columns={columns}
                            reverse={reverse}
                            columnClickHandler={columnClickHandler}
                            userRole={userRole}
                        />
                        <TBody height={density}>
                            <TableFilters
                                columns={columns}
                                showFilters={showFilters}
                                entitiesFilters={filtersQuery}
                                filtersChange={setEntitiesFilters}
                                applyFilters={handleApplyFiltering}
                            />
                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner}
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setEntitiesPageSize}
                    />
                </EMTableContainer>
            </EMBody>
        </EMContainer>
    )
}

const mapStateToProps = (state) => ({
    loading: state.entities.loading,
    entitiesData: state.entities.data,
    count: state.entities.count,
    currentPage: state.entities.currentPage,
    pageSize: state.entities.pageSize,
    sortQuery: state.entities.sortQuery,
    reverse: state.entities.reverse,
    filtersQuery: state.entities.filtersQuery,
    showFilters: state.entities.showFilters,
    userRole: state.auth.role,
    entityOwnerDetail: state.clients.entity

})
const mapDispatchToProps = dispatch => ({
    getEntitiesPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.entitiesGetPage(page, filters, sort, reverse, pageSize)),
    setEntitiesPage: (page) => dispatch(actions.entitiesSetPage(page)),
    setEntitiesPageSize: (pageSize) => dispatch(actions.entitiesSetPageSize(pageSize)),
    setSortQuery: (sortQ) => dispatch(actions.entitiesSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.entitiesSetReverseSort(reverseS)),
    setEntitiesFilters: (filters) => dispatch(actions.entitiesSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.entitiesUpdateShowFilters(show)),
    setEntitydetail: (entity) => dispatch(actions.setEntitydetail(entity)),
})
export default connect(mapStateToProps, mapDispatchToProps)(EntitiesManager)
