import React, { useEffect, useState } from 'react'
import Axios from '../../../../../axios-proas'
import { BtnSmOutlineAction, FormGroup, FormInput, FormLabel, FormSelect, PrimaryBtnOutlineAction, FormGroupInvInf, FormGroupInv, GroupCommande, FormGroupInvCommandes } from '../../../../../styles/Common'
import { FContainer, FFormWrap, FHeader, FHInfos } from './Fields.styled'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import frLocale from "date-fns/locale/fr";
import { DPContainer } from '../../../../../containers/CampaignForm/CampaignForm.styled';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { checkValidity, getNotyfObject } from '../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { formatDate } from "../../../../../shared/utility"
import { listClasses } from '@mui/material';

function Information({ invoiceData, setInvoiceData }) {
  const notyf = getNotyfObject();
  const { t } = useTranslation();
  const theme = useTheme()
  const navigate = useNavigate()
  const [errors, setErrors] = useState([])
  const [existInvoice, setExistInvoice] = useState(false)



  console.log(invoiceData)
  const checkExistInvoice = (number = null) => {
    let errorsTmp = []
    setErrors([])
    setExistInvoice(false)
    Axios.get(`/invoice/${invoiceData?.invoiceUid}/existInvoice`, {
      params: {
        number: number ? number : invoiceData.number,
        supplierUid: invoiceData.supplierUid
      }
    }).then(res => {
      let exist = res?.data?.exist ? true : false;
      if (exist) {
        errorsTmp.push("Une facture avec ce numéro existe déjà pour ce fournisseur")
        setExistInvoice(true)
      }
      setErrors(errorsTmp)
    }).catch(err => {
      notyf.error(t('global:error', "Une erreur s'est produite"))
    })
  }

  useEffect(() => {
    let invoiceDataInit = {
      ...invoiceData,
      documentType: invoiceData.documentType ? invoiceData.documentType : "INV",
      issuingDate: invoiceData.issuingDate, // invoiceData.issuingDate ? +invoiceData.issuingDate : new Date().getTime(),
      dueDate: invoiceData.dueDate ? +invoiceData.dueDate : new Date().getTime()
    }
    setInvoiceData(invoiceDataInit)

  }, [])

  const fieldChangeHandler = (e) => {
    const { name, value } = e.target
    setInvoiceData({ ...invoiceData, [name]: value })
    if (name === "number") {
      checkExistInvoice(value)
    }
  }




  const formatNumber = (number) => {
    return new Intl.NumberFormat('fr-FR').format(parseFloat(number).toFixed(2))
  }





  return (
    <FContainer>

      <FFormWrap>
        <FormGroupInvInf>
          <FormLabel htmlFor="number" >{t("invoiceEvent:invoiceNumber", "N° de facture")} </FormLabel>
          <FormInput
            id="number"
            type="text"
            name="number"
            placeholder="Numéro"
            value={invoiceData?.number || ""}
          // onChange={(e) => fieldChangeHandler(e)}
          />
        </FormGroupInvInf>


        <FormGroupInvInf>

          <FormLabel htmlFor="supplierUid" >{t("invoiceList:creationDate", "Date de facture")}</FormLabel>

          <FormInput
            id="issuingDate"
            type="text"
            name="issuingDate"
            placeholder="Numéro"
            value={invoiceData?.creationDate ? formatDate(+invoiceData?.creationDate) : null}
          // onChange={(e) => fieldChangeHandler(e)}
          />
        </FormGroupInvInf>
        <FormGroupInvInf>
          <FormLabel htmlFor="supplierUid" >{t("invoiceList:dueDate", "Date d'échéance")}</FormLabel>
          {/* <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
            <DPContainer>
                <DatePicker
                    id="date-picker-dialog"
                    label="Date d'échéance"
                    format="dd/MM/yyyy"
                    // minDate={currentDate}
                    value={invoiceData?.dueDate ? new Date(+invoiceData?.dueDate) : new Date()}
                    
                    onChange={handleDueDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    invalidDateMessage="Format de date invalide"
                    // maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                    // minDateMessage="La date ne doit pas être antérieure à la date minimale"
                    renderInput={(params) => <TextField {...params} />}
                    // open={true} control open dialog state
                />
            </DPContainer>
          </LocalizationProvider> */}
          <FormInput
            id="Date d'échéance"
            type="text"
            name="DateEchéance"
            placeholder="Numéro"
            value={invoiceData?.issuingDate ? formatDate(+invoiceData?.issuingDate) : null}
          // onChange={(e) => fieldChangeHandler(e)}
          />
        </FormGroupInvInf>

        <FormGroupInvInf>
          <FormLabel htmlFor="supplierUid" >{t("invoiceList:supplier", "Fournisseur")}</FormLabel>
          <FormInput
            id="Date d'échéance"
            type="text"
            name="DateEchéance"
            placeholder="Numéro"
            value={invoiceData?.supplierName ? invoiceData?.supplierName : null}
          // onChange={(e) => fieldChangeHandler(e)}
          />
          {/* <FormSelect 
                id="supplierUid"
                name="supplierUid"
                value={invoiceData?.supplierUid || ""}
                 onChange={(e) => fieldChangeHandler(e)}
            >
              <option value=""></option>
              {
                userEntities?.map((supplier, index) => {
                  return (
                    <option key={index} value={supplier.uid}>{supplier.name}</option>
                  )
                })
              }
            </FormSelect> */}
        </FormGroupInvInf>
        <FormGroupInv>
          <FormLabel htmlFor="clientUid" >{t("invoiceList:entity", "Entité")}</FormLabel>
          <FormInput
            id="Date d'échéance"
            type="text"
            name="DateEchéance"
            placeholder="Numéro"
            value={invoiceData?.clientName ? invoiceData?.clientName : null}
          // onChange={(e) => fieldChangeHandler(e)}
          />

        </FormGroupInv>

        <FormGroupInvCommandes>
          <FormLabel htmlFor="amount" >{t("invoiceList:commandNumber", "N° de commande")}</FormLabel>
          {
            invoiceData?.commandNumbers?.split(',').map((item, index) => {
              return (
                <GroupCommande key={index}>
                  <span>{item || ""}</span>
                </GroupCommande>

              )


            })}

        </FormGroupInvCommandes>
        <FormGroupInvInf>
          <FormLabel htmlFor="exclusiveTaxAmount" >{t("invoiceList:exclusiveAmount", "Montant HT")}</FormLabel>
          <FormInput
            id="exclusiveTaxAmount"
            type="text"
            step="0.01"
            name="exclusiveTaxAmount"
            placeholder="Montant HT"
            value={formatNumber(+invoiceData?.exclusiveTaxAmount) + " €"}
          // onChange={(e) => fieldChangeHandler(e)}
          />
        </FormGroupInvInf>
        <FormGroupInvInf>
          <FormLabel htmlFor="exclusiveTaxAmount" >{t("invoiceList:vatAmount", "Montant TVA")}</FormLabel>
          <FormInput
            id="exclusiveTaxAmount"
            type="text"
            step="0.01"
            name="exclusiveTaxAmount"
            placeholder="Montant HT"
            value={formatNumber(+invoiceData?.inclusiveTaxAmount - (+invoiceData?.exclusiveTaxAmount)) + " €" || ""}
          // onChange={(e) => fieldChangeHandler(e)}
          />
        </FormGroupInvInf>
        <FormGroupInv>
          <FormLabel htmlFor="inclusiveTaxAmount" >{t("invoiceList:inclusiveAmount", "Montant TTC")} </FormLabel>
          <FormInput
            id="inclusiveTaxAmount"
            type="text"
            step="0.01"
            name="inclusiveTaxAmount"
            placeholder="Montant TTC"
            value={(formatNumber(+invoiceData?.inclusiveTaxAmount) + " €") || ""}
          // onChange={(e) => fieldChangeHandler(e)}
          />
        </FormGroupInv>
        {/* <FormGroupInvInf>
            <FormLabel htmlFor="clientUid" >Devise</FormLabel>
            <FormSelect 
                id="devise"
                name="devise"
                value={invoiceData?.devise || ""}
                onChange={(e) => fieldChangeHandler(e)}
            >
              <option value="EUR" key={2}>EUR</option>
              <option value="USD" key={3}>USD</option>
              <option value="GBP" key={4}>GBP</option>
        
            </FormSelect>
        </FormGroupInvInf> */}


        <FormGroupInvInf>
          <FormLabel htmlFor="docFile" >{t("invoiceList:file", "Fichier")}</FormLabel>
          <FormInput
            id="docFile"
            type="text"
            name="docFile"
            placeholder="Fichier"
            value={invoiceData?.docFile || ""}
            onChange={(e) => fieldChangeHandler(e)}
            disabled
          />
        </FormGroupInvInf>

        <FormGroupInvInf>
          <FormLabel htmlFor="number" >{t("invoiceList:archiveNumber", "N° archivage (NUA)")} </FormLabel>
          <FormInput
            id="number"
            type="text"
            name="number"
            placeholder="Numéro"
            value={invoiceData?.number || ""}
            onChange={(e) => fieldChangeHandler(e)}
          />
        </FormGroupInvInf>
      </FFormWrap>
    </FContainer>
  )
}

export default Information