import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { connect } from 'react-redux';
import { Notyf } from 'notyf';
import { getNotyfObject } from '../../../shared/utility';
import _ from 'lodash';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import StatusModal from './StatusModal/StatusModal';
import { IDProgressLineContainer, IDProgressLineItem, IDProgressLineLabel, PLAction } from './IDProgressLine.styled';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { useTheme } from 'styled-components';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { CTooltip } from '../../../components/UI/CTooltip/CTooltip'

function IDProgressLine(props) {
    const {
        userType,
        entityFilter,
        status,
        message,
        editStatus,
        accessToken,
        error,
        invoiceIntegratedDetail,
        lastMessage,
        allEventsInvoice
    } = props
    const { t } = useTranslation();
    let notyf = getNotyfObject();
    const theme = useTheme()
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [infoStatus, setInfoStatus] = useState(status);
    const dangerStatus = useMemo(() => ['ERROR', 'REJECTED', 'REFUSED', 'LITIGATION'], [])
    const optionalSatus = "TO_BE_CONTROLLED, TO_BE_VALIDATED"
    console.log("allEventsInvoice", allEventsInvoice)
    const statusIsPassed = (status) => {
        let res = false
        allEventsInvoice?.forEach(event => {
            if (event.snapshot == status) {
                res = true
            }
        })
        return res
    }


    const statusFindIndex = (condition, status) => {
        let Index_TO_BE_VALIDATED = allEventsInvoice.findIndex(event => event.snapshot == status)
        let Index_TO_BE_CONTROLLED = allEventsInvoice.findIndex(event => event.snapshot == condition)
        // console.log("Index_TO_BE_VALIDATED",Index_TO_BE_VALIDATED)
        // console.log("Index_TO_BE_CONTROLLED",Index_TO_BE_CONTROLLED)
        if (Index_TO_BE_VALIDATED > Index_TO_BE_CONTROLLED) {
            return true
        } else {
            return false
        }
    }
    useEffect(() => {
        let statusListTmp = [];
        switch (status) {
            case "VALIDATED":
                statusListTmp = [
                    { label: t("invoiceStatus:RECEIVED", "Réceptionnée"), value: "RECEIVED", show: true },
                    { label: t("invoiceStatus:TO_BE_CONTROLLED", "A contrôler"), value: "TO_BE_CONTROLLED", show: (status == "TO_BE_CONTROLLED" || statusIsPassed("TO_BE_CONTROLLED")) },
                    { label: t("invoiceStatus:CONTROLLED", "Contrôlée"), value: "CONTROLLED", show: true },
                    { label: t("invoiceStatus:LITIGATION", "Litige"), value: "LITIGATION", show: (status == "LITIGATION" || statusIsPassed("LITIGATION")) },
                    { label: t("invoiceStatus:TO_BE_VALIDATED", "A valider"), value: "TO_BE_VALIDATED", show: ("TO_BE_VALIDATED".includes(status) || statusIsPassed("TO_BE_VALIDATED")) },
                    { label: t("invoiceStatus:VALIDATED", "Validée"), value: "", show: true },
                    { label: t("invoiceStatus:TO_BE_PAID", "A payer"), value: "VALIDATED", show: true },
                    { label: t("invoiceStatus:WAITING_FOR_PAYMENT", "Attente de paiment"), value: "TO_BE_PAID", show: true },
                    { label: t("invoiceStatus:PAID", "Payée"), value: "PAID", show: true }
                ];
                break;
            case "CONTROLLED":
                statusListTmp = [
                    { label: t("invoiceStatus:RECEIVED", "Réceptionnée"), value: "RECEIVED", show: true },
                    { label: t("invoiceStatus:TO_BE_CONTROLLED", "A contrôler"), value: "TO_BE_CONTROLLED", show: (status == "TO_BE_CONTROLLED" || statusIsPassed("TO_BE_CONTROLLED")) },
                    { label: t("invoiceStatus:CONTROLLED", "Contrôlée"), value: "", show: true },
                    { label: t("invoiceStatus:LITIGATION", "Litige"), value: "LITIGATION", show: (status == "LITIGATION" || statusIsPassed("LITIGATION")) },
                    { label: t("invoiceStatus:TO_BE_VALIDATED", "A valider"), value: "TO_BE_VALIDATED", show: ("TO_BE_VALIDATED".includes(status) || statusIsPassed("TO_BE_VALIDATED")) },
                    { label: t("invoiceStatus:VALIDATED", "Validée"), value: "CONTROLLED", show: true },
                    { label: t("invoiceStatus:TO_BE_PAID", "A payer"), value: "TO_BE_PAID", show: true },
                    { label: t("invoiceStatus:WAITING_FOR_PAYMENT", "Attente de paiment"), value: "TO_BE_PAID", show: true },
                    { label: t("invoiceStatus:PAID", "Payée"), value: "PAID", show: true }
                ];
                break;

            default:
                statusIsPassed("TO_BE_VALIDATED") ?
                    statusListTmp = [
                        { label: t("invoiceStatus:RECEIVED", "Réceptionnée"), value: "RECEIVED", show: true },
                        { label: t("invoiceStatus:TO_BE_CONTROLLED", "A contrôler"), value: "TO_BE_CONTROLLED", show: (status == "TO_BE_CONTROLLED" || statusIsPassed("TO_BE_CONTROLLED")) },
                        { label: t("invoiceStatus:CONTROLLED", "Contrôlée"), value: "CONTROLLED", show: true },
                        { label: t("invoiceStatus:TO_BE_VALIDATED", "A valider"), value: "TO_BE_VALIDATED", show: ("TO_BE_VALIDATED".includes(status) || statusIsPassed("TO_BE_VALIDATED")) },
                        { label: t("invoiceStatus:LITIGATION", "Litige"), value: "LITIGATION", show: (status == "LITIGATION" || (statusIsPassed("LITIGATION") && statusFindIndex("LITIGATION", status))) },

                        { label: t("invoiceStatus:VALIDATED", "Validée"), value: "VALIDATED", show: true },
                        { label: t("invoiceStatus:TO_BE_PAID", "A payer"), value: "TO_BE_PAID", show: true },
                        { label: t("invoiceStatus:WAITING_FOR_PAYMENT", "Attente de paiment"), value: "WAITING_FOR_PAYMENT", show: true },
                        { label: t("invoiceStatus:PAID", "Payée"), value: "PAID", show: true }
                    ]
                    :
                    statusListTmp = [
                        { label: t("invoiceStatus:RECEIVED", "Réceptionnée"), value: "RECEIVED", show: true },
                        { label: t("invoiceStatus:TO_BE_CONTROLLED", "A contrôler"), value: "TO_BE_CONTROLLED", show: (status == "TO_BE_CONTROLLED" || statusIsPassed("TO_BE_CONTROLLED")) },
                        { label: t("invoiceStatus:CONTROLLED", "Contrôlée"), value: "CONTROLLED", show: true },

                        { label: t("invoiceStatus:LITIGATION", "Litige"), value: "LITIGATION", show: (status == "LITIGATION" || (statusIsPassed("LITIGATION") && statusFindIndex("LITIGATION", status))) },
                        { label: t("invoiceStatus:TO_BE_VALIDATED", "A valider"), value: "TO_BE_VALIDATED", show: ("TO_BE_VALIDATED".includes(status) || statusIsPassed("TO_BE_VALIDATED")) },
                        { label: t("invoiceStatus:VALIDATED", "Validée"), value: "VALIDATED", show: true },
                        { label: t("invoiceStatus:TO_BE_PAID", "A payer"), value: "TO_BE_PAID", show: true },
                        { label: t("invoiceStatus:WAITING_FOR_PAYMENT", "Attente de paiment"), value: "WAITING_FOR_PAYMENT", show: true },
                        { label: t("invoiceStatus:PAID", "Payée"), value: "PAID", show: true }
                    ]
                break;

        }

        setStatusList(statusListTmp)
    }, [status, allEventsInvoice])

    const getProgresseLineIcon = (value, index) => {

        let statusIndex = statusList.filter(item => item.show).findIndex(item => item.value === infoStatus)
        //received case
        if (statusIndex == 0)
            statusIndex = 1;
        //paid case
        if (value == "PAID")
            statusIndex = statusIndex + 1;
        if (index < statusIndex) return <CheckCircleOutlineIcon style={{ fontSize: "1.25rem" }} />
        else return <PanoramaFishEyeIcon style={{ fontSize: "1.25rem" }} />
    }

    const getStatusColors = (value, index) => {

        let statusIndex = statusList.filter(item => item.show).findIndex(item => item.value === infoStatus)
        //received case
        if (statusIndex == 0)
            statusIndex = 1;
        //paid case
        if (value == "PAID")
            statusIndex = statusIndex + 1;
        if (index < statusIndex)
            return [theme.colors.secondary, " solid " + theme.colors.secondary]
        if (index === (statusIndex))
            return [theme.colors.secondary, " dashed " + theme.colors.secondary]
        return [theme.colors.secondary, " solid " + theme.colors.gray]
    }


    return (
        <IDProgressLineContainer>
            {
                statusList.filter(item => item.show).map((item, index) => (
                    <IDProgressLineItem
                        color={getStatusColors(item.value, index)[0]}
                        border={getStatusColors(item.value, index)[1]}
                    >
                        <IDProgressLineLabel>{item.label}</IDProgressLineLabel>
                        {/* <CheckCircleOutlineIcon style={{fontSize: "1.25rem"}} />
                        <PanoramaFishEyeIcon /> */}
                        {getProgresseLineIcon(item?.value, index)}
                    </IDProgressLineItem>
                ))

            }
            {
                error ?
                    <CTooltip title={lastMessage ? lastMessage : t("global:noMessages", "Aucun message")}>
                        <span style={{ color: "#EE5A5A", padding: "0px 17px" }}><WarningAmberIcon style={{ fontSize: "30px", margin: "0px 5px 0px 5px" }} /><small style={{ fontSize: "small" }}>{t("invoiceStatus:ERROR", "Erreur")}</small></span>
                    </CTooltip>
                    :
                    message == 1 ?
                        <CTooltip title={t("invoiceEvent:invoiceLitigation", "Facture en litige")}>
                            <span style={{ color: "#EE5A5A", padding: "0px 17px" }}><WarningAmberIcon style={{ fontSize: "30px", margin: "0px 5px 0px 5px" }} /><small style={{ fontSize: "small" }}></small></span>
                        </CTooltip>
                        : null

            }
        </IDProgressLineContainer>
    )
}

const mapStateToProps = (state) => ({
    userType: state.auth.userType,
    // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
    // mandatUid: state.mandat.admData.uid,
    // status : state.mandat?.admData?.status,
    // connectedUser : state.mandat?.admData?.connectedUser,
    // pdfFile: state.mandat.admData?.pdfFile || null,
    // admData: state.mandat.admData,
    // admUpdate: state.mandat.admUpdate
})
const mapDispatchToProps = dispatch => ({
    // getMandatData: (mandatUid) => dispatch(actions.getMandatData(mandatUid)),
    // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
    // createMandatTimelineEvent: (mandatUid, eventData) => dispatch(actions.createMandatTimelineEvent(mandatUid, eventData)),
    // setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive))
})
export default connect(mapStateToProps, mapDispatchToProps)(IDProgressLine)
