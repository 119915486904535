import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './FileManagerModal.css';
import { Button, Modal } from 'react-bootstrap';
import SearchIcon from '@mui/icons-material/Search';
// import { getStatusWithKey } from '../../../shared/utility';
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, CancelBtnOutlineAction } from '../../../../../../styles/Common';
import DropZone from '../DropZone/DropZone'
import { useTranslation } from 'react-i18next';

function FileManagerModal(props) {
    const { show, modalClosed, confirm, deleteAttachment, checkDocumentsType, onDrop, invoiceAttachement, setInvoiceAttachement } = props;
    const [comment, setComment] = useState(null);
    const { t } = useTranslation()
    useEffect(() => {
        console.log(invoiceAttachement)
    }, [invoiceAttachement])


    const getUserRole = (level) => {
        if (level == 'admin' || level == 'standard') return 'Admin'
        if (level == 'superAccountant') return 'DAF'
        if (level == 'accountant') return 'Comptable'
    }

    return (
        <div style={{ width: "700px" }}>
            <Modal show={show} onHide={modalClosed}  >
                <Modal.Header closeButton style={{ borderBottom: "3px solid #D9E1E7" }}>
                    <Modal.Title className="ICDBlue" style={{ width: "-webkit-fill-available", textAlign: "center" }}>
                        {t("invoiceDetail:filesManagment", "Gestion des fichiers")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ADMCreationModal__body">
                    <p style={{ color: "#809FB8", padding: "4px 0px 7px 9px" }}>{t("invoiceDetail:fileDeposit", "Dépôt de nouveaux fichiers")}</p>
                    <div >
                        <DropZone
                            // checkDocumentsType={checkDocumentsType}
                            deleteAttachment={deleteAttachment}
                            onDrop={onDrop}
                            accept={"pdf/*"}

                            invoiceAttachement={invoiceAttachement}
                            setInvoiceAttachement={setInvoiceAttachement}
                        />
                    </div>
                    {/* <div style={{padding: "0px 3px", maxHeight: "200px", height: "200px"}}>
                        <div className="row">
                            <div className="col-sm-5">Nom</div>
                            <div className="col-sm-4">Type</div>
                            <div className="col-sm-1"></div>
                        </div>
                        {
                            invoiceData?.attachments.map(attachment => {
                                return (
                                    <div className="row">
                                        <div className="col-sm-5">{attachment?.docName}</div>
                                        <div className="col-sm-4">{attachment?.docName}</div>
                                        <div className="col-sm-1"></div>
                                    </div>

                                )

                            })
                        }
                    </div> */}
                </Modal.Body>
                <Modal.Footer style={{ borderTop: "3px solid #D9E1E7" }}>
                    <PrimaryBtnOutlineAction
                        variant="primary"
                        onClick={confirm}
                    // disabled={statusValue === "LITIGATION" && !comment}
                    >
                        {t("global:confirm", "Confirmer")}
                    </PrimaryBtnOutlineAction>
                    <CancelBtnOutlineAction variant="secondary" onClick={modalClosed}>
                        {t("global:cancel", "Annuler")}
                    </CancelBtnOutlineAction>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default FileManagerModal;
