import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PdfViewer from "./PdfViewer/PdfViewer";
import * as actions from "../../../../../store/actions/index";
import { useParams } from "react-router-dom";
import { getNotyfObject, convertFileToBase64 } from "../../../../../shared/utility";
import XMLViewer from "react-xml-viewer";
import FileUploaderBtnNoHover from '../../../../UI/FileUploaderBtn/FileUploaderBtn';
import Select from '../../../../UI/Select/Select';
import DeepSelect from '../../../../UI/DeepSelect/DeepSelect';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Axios from '../../../../../axios-proas'
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { IVContainer, EntHeaderWrapper, XmlDiv, IVWrapper, IVDownloadContainer, ContentSelect, IVDownloadIcon } from "./InvoiceView.styled";
import {
  NavTabsLi,
  NavTabsLink,
  NavTabsUl,
} from "../../../../../containers/Entity/Entity.styled";
import EdiViewer from "../EdiViewer/EdiViewer";
import FileManagerModal from "./FileManagerModal/FileManagerModal";
import DownloadIcon from "@mui/icons-material/Download";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
function RecouvermentView({
  invoiceData,
  navTabsList,
  loadingPdf,
  pdfFile,
  ediFileName,
  xmlFile,
  ediFile,
  chorusFile,
  chorusFileName,
  pdfFileName,
  mimeType,
  xmlFileName,
  getInvoicePdf,
  getInvoicePdfByToken,
  getInvoiceData,
  setIsNew,
  isNew

}) {
  const [showFileManagerModal, setShowFileManagerModal] = useState(false);
  const [tab, setTab] = useState(navTabsList[0]?.value);
  let { uid, token } = useParams();
  const notyf = getNotyfObject();
  const [file, setFile] = useState(pdfFile)
  const [fileName, setFileName] = useState(null)
  const [url, setUrl] = useState('');
  const customTheme = {
    attributeKeyColor: "#FF0000",
    attributeValueColor: "#000FF",
    overflowBreak: true,
  };

  const [invoiceAttachement, setInvoiceAttachement] = useState({ attachments: [] })
  const [options, setOptions] = useState([]);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    let optionsTemp = [];
    if (uid) {

      optionsTemp.push({ label: pdfFileName, value: pdfFile, attachement: null, family: "PF" });
      setOptions(optionsTemp);

    }
    if (invoiceData?.attachments.length > 0) {

      invoiceData?.attachments.map((item) => {
        optionsTemp.push({ label: item.docName, value: item.docFile, attachement: true, family: item.family });
        setOptions(optionsTemp);
      })
      setInvoiceAttachement({
        ...invoiceAttachement,
        attachments: invoiceData?.attachments
      })
      if (isNew) {
        getAttachementPdf(invoiceData?.attachments[invoiceData?.attachments?.length - 1]?.uid)
        setFileName(invoiceData?.attachments[invoiceData?.attachments?.length - 1]?.docName);
      } else {
        setFile(pdfFile);
        setFileName(pdfFileName);
      }

    } else {

      setFile(pdfFile);
      setFileName(pdfFileName);
    }

  }, [pdfFile, isNew, update]);

  function getAttachementPdf(uid) {
    console.log(fileName)
    console.log(invoiceData?.attachments.filter((item) => item.uid == uid)[0]?.docName)
    setFileName(invoiceData?.attachments.filter((item) => item.uid == uid)[0]?.docName);
    Axios.get('/invoice/' + uid + '/attachementPdf/').then((res) => {
      setFile(res.data.pdfFile)
    }).catch((err) => { });


  }
  const downloadClickHandler = (tab) => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // IE workaround
      if (tab == "xml") {
        var byteCharacters = atob(xmlFile);
      } else {
        var byteCharacters = atob(ediFile);
      }

      let byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let blob = new Blob([byteArray], { type: "text/plain" });
      window.navigator.msSaveOrOpenBlob(
        blob,
        tab == "xml" ? xmlFileName : ediFileName || "facture.xml"
      );
    } else {
      // much easier if not IE
      //________Direct Download_____
      var blob = null;
      switch (tab) {
        case "xml":
          blob = new Blob([xmlFile], { type: "text/plain" });
          break;
        case "edi":
          blob = new Blob([ediFile], { type: "" });
          break;
        case "chorus":
          blob = new Blob([chorusFile], { type: "text/plain" });
          break;
        default:
          break;
      }

      //const downloadUrl = URL.createObjectURL(blob)
      let a = document.createElement("a");
      //a.setAttribute('href', window.URL.createObjectURL(blob));
      a.href = window.URL.createObjectURL(blob);
      a.download =
        tab == "xml"
          ? xmlFileName
          : tab == "edi"
            ? ediFileName
            : chorusFileName || "facture.xml";
      a.dataset.downloadurl = ["", a.download, a.href].join(":");
      document.body.appendChild(a);
      a.click();

      //__________Visualize In The Browser _____
      // const blob = dataURItoBlob(data);
      // const url = URL.createObjectURL(blob);

      // // to open the PDF in a new window
      // window.open(url, '_blank');
    }
  };

  useEffect(() => {
    getInvoicePdf(uid, tab);
  }, [uid, tab]);

  let admView = null;


  if (loadingPdf) admView = <spinner />;
  else {
    if (pdfFile || xmlFile || ediFile || true)
      admView = (
        <>
          {tab == "pdf" || true ? (
            <PdfViewer pdfFile={
              file
            } pdfFileName={pdfFileName} />
          ) : xmlFile && tab == "xml" ? (
            <XmlDiv>
              <XMLViewer xml={xmlFile} theme={customTheme} />
            </XmlDiv>
          ) : ediFile && tab == "edi" ? (
            <EdiViewer ediFile={ediFile} ediFileName={ediFileName} />
          ) : chorusFile && tab == "chorus" ? (
            <XMLViewer xml={chorusFile} theme={customTheme} />
          ) : null}
        </>
      );
    else
      admView = (
        <>
          <div>
            {" "}
            {tab == "pdf" ? (
              <h5> </h5>//Cette facture ne contient pas de PDF
            ) : tab == "xml" ? (
              <h5>Cette facture ne contient pas de XML </h5>
            ) : tab == "edi" ? (
              <h5>Cette facture ne contient pas de EDI </h5>
            ) : (
              <h5>Cette facture ne contient pas de CHORUS </h5>
            )}
          </div>
        </>
      );
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  // const addfile = async (e) => {
  //   if (e?.target?.files && e?.target?.files[0] && e?.target?.name === "file") {
  //     setUrl(URL.createObjectURL(e.target.files[0]))
  //    // setFileName(e.target.files[0].name);
  //     let url = URL.createObjectURL(e.target.files[0])
  //     let fileTemp = await toBase64(e.target.files[0]);

  //     console.log(e.target.files[0])
  //     uploadFileHandler(e.target.files[0])
  //     getInvoiceData(uid);

  //   }
  // }

  const uploadFileHandler = (filef, family) => {

    const formData = new FormData();
    formData.append('file', filef);
    formData.append('family', family);

    formData.append('uid', uid);
    Axios.post("/invoice//attachementForInvoice", formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
      getInvoiceData(uid);
      if (response.data.message) {
        setDocumentUploaded(true);
        notyf.success(t("global:success"));
        //navigate(-1);

        // setShowModal(true)
      }
    }).catch(err => {
      const response = err.response.data;
      const exist = response?.hasOwnProperty('exist');
      if (exist) {
        notyf.error(t("invoiceList:exist", "Une facture avec le même nom existe déjà"));
      } else
        notyf.error(t("invoiceList:uploadFailed"));
    }).finally(() => {

    })
  }

  const changefile = (e) => {
    console.log(e)
    if (options.filter((item) => item.value == e)[0]?.attachement) {

      getAttachementPdf(invoiceData?.attachments?.filter((item) => item.docFile == e)[0]?.uid)
      //setFile(e)
    }
    else {
      setFileName(pdfFileName);
      setFile(e)
    }
  }

  const deleteItem = (i) => {

    let fuid = invoiceData?.attachments?.filter((item) => item.docFile == i.value)[0]?.uid
    Axios.delete(`invoice/${fuid}/attachment`).then(response => {
      getInvoiceData(uid);

      notyf.success(t("global:success", "Le fichier a bien été supprimé"))
    }).catch(response => {

      notyf.error("generatedErrorMessage")
    })

  }


  const onDrop = (acceptedFiles) => {
    let attachmentsTmp = invoiceAttachement?.attachments
    acceptedFiles.map(file => {
      if (file) {
        let existFile = attachmentsTmp?.find(row => row.docName === file.name)
        if (existFile) {
          notyf.error("Un fichier avec le même nom a déjà été ajouté")
        } else {
          // uploadFileHandler(file);


          attachmentsTmp.push({
            docName: file.name,
            type: file.type,
            content: file,
            docType: "",
            new: true,
            family: "PF"
          })
          setInvoiceAttachement({
            ...invoiceAttachement,
            attachments: attachmentsTmp
          })


        }
      }

    })
  };


  // const checkDocumentsType = (e, offset) => {
  //     setShowDuplicatedDocumentTypeErrorOnSubmit(false)
  //     let tempEntityAttachments = entity.attachments
  //     let index = entity?.attachments.find(attachment => attachment.docType == e.target.value )
  //     if(index && e.target.value!=""){
  //         setShowDuplicatedDocumentTypeError(true)    
  //     }else{
  //         setShowDuplicatedDocumentTypeError(false)
  //         tempEntityAttachments[offset].docType = e.target.value
  //         setEntity({...entity,
  //             attachments: tempEntityAttachments    
  //         })
  //     } 

  // }

  const deleteAttachment = (index, i) => {

    let copie = invoiceAttachement.attachments;
    let isNew = invoiceAttachement.attachments.filter((item) => item.docFile == i.docFile)[0]?.hasOwnProperty("new");
    if (isNew) {
      copie.splice(index, 1);
      console.log(copie)
      setInvoiceAttachement({ ...invoiceAttachement, attachments: copie })

    } else {
      Axios.delete(`invoice/${i.uid}/attachment`).then(response => {
        getInvoiceData(uid);

        notyf.success(t("global:success", "Le fichier a bien été supprimé"))
      }).catch(response => {

        notyf.error("generatedErrorMessage")
      })
    }

  }

  const updateFileFamily = () => {
    let newAttachments = Object.values(invoiceAttachement.attachments)?.filter((item) => !item.hasOwnProperty("new"))
    // console.log(newAttachments)
    uploadFileFamilyHandler(newAttachments)

  }


  const uploadFileFamilyHandler = (attachments) => {
    let temp =
      attachments.map(a => {
        return {
          "family": a.family,
          "uid": a.uid
        }
      })
    Axios.put(`invoice//updateAttachments`, temp).then(response => {
      setUpdate(!update)
      notyf.success(t("global:success", "Fichier modifié "))
    }).catch(response => {

      notyf.error("error")
    })
  }

  const insertFile = () => {
    let newAttachments = Object.values(invoiceAttachement.attachments)?.filter((item) => item.hasOwnProperty("new"))
    console.log(newAttachments)
    newAttachments.map((file) => {
      uploadFileHandler(file?.content, file?.family)
    })
    updateFileFamily()
    // getInvoiceData(uid);
    setShowFileManagerModal(false)
    setIsNew(true)
  }
  return (
    <IVWrapper style={{
      padding: "1rem",
      border: "1px solid #809FB8",
      borderRadius: "19px",
      marginRight: "13px"
    }}>

      <ContentSelect>
        {/* <Select width={invoiceData.contentieux == 1 ? "100%" : "97%"} */}

        <DeepSelect
          // fileName={fileName}
          // <Select 
          width={"100%"}
          close={false}
          deleteItem={deleteItem}
          options={options}
          value={fileName}
          onChange={changefile}
        />
        <div style={{ width: "4/%", paddingTop: "4px", cursor: "pointer" }} onClick={() => setShowFileManagerModal(true)}>
          <InsertDriveFileIcon style={{ color: "#2174B9" }} />
        </div>

      </ContentSelect>
      {(tab == "xml" || tab == "edi" || tab == "chorus") && (
        <IVDownloadContainer>
          <IVDownloadIcon
            onClick={() => downloadClickHandler(tab)}
          >
            <DownloadIcon />
          </IVDownloadIcon>
        </IVDownloadContainer>
      )}
      <IVContainer accessToken={token}>{admView}</IVContainer>
      {
        showFileManagerModal ? (
          <FileManagerModal
            show={showFileManagerModal}
            modalClosed={() => setShowFileManagerModal(false)}
            confirm={insertFile}
            update={update}

            // usersList={usersList}
            uploadFileHandler={uploadFileHandler}
            onDrop={onDrop}
            invoiceAttachement={invoiceAttachement}
            setInvoiceAttachement={setInvoiceAttachement}
            deleteAttachment={deleteAttachment}
          />
        ) : null
      }
    </IVWrapper>
  );
}

const mapStateToProps = (state) => ({
  loadingPdf: state.invoiceDetail.loadingPdf,
  ediFile: state.invoiceDetail.pdfFile?.ediFile || null,
  pdfFile: state.invoiceDetail.pdfFile?.pdfFile || null,
  xmlFile: state.invoiceDetail.pdfFile?.xmlFile || null,
  errorPdf: state.invoiceDetail.errorPdf,
  invoiceData: state.invoiceDetail.invoiceData,
  pdfFileName: state.invoiceDetail?.pdfFile?.docFile || "facture.pdf",
  xmlFileName: state.invoiceDetail?.pdfFile?.docXml || "facture.xml",
  ediFileName: state.invoiceDetail?.pdfFile?.docEdi || "facture.EDI",
  mimeType: state.invoiceDetail?.pdfFile?.mimeType,
  chorusFile: state.invoiceDetail.pdfFile?.chorusFile || null,
  chorusFileName: state.invoiceDetail?.pdfFile?.docChorus || "facture.xml",
});
const mapDispatchToProps = (dispatch) => ({
  getInvoicePdf: (invoiceUid, type) =>
    dispatch(actions.getInvoicePdf(invoiceUid, type)),
  getInvoicePdfByToken: (invoiceToken) =>
    dispatch(actions.getInvoicePdfByToken(invoiceToken)),
  getInvoiceData: (invoiceUid) => dispatch(actions.getInvoiceData(invoiceUid)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RecouvermentView);
