
import React, { useCallback, useEffect, useState } from 'react'
import Axios from '../../axios-proas';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import * as actions from '../../store/actions/index';
import { useTranslation } from 'react-i18next';
import TableActions from '../../components/SupplierAccountRequest/TableActions/TableActions';
import TableHead from '../../components/SupplierAccountRequest/TableHead/TableHead';
import TableBody from '../../components/SupplierAccountRequest/TableBody/TableBody';
import TableFilters from '../../components/SupplierAccountRequest/TableFilters/TableFilters';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { checkValidity, getNotyfObject } from '../../shared/utility';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useParams } from 'react-router-dom';
import { NoContent, SpinnerContainer, Table, TBody, SearchInputContainer, SearchInput } from '../../styles/Table.styled';
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import { UMBody, UMContainer } from './SupplierAccountRequest.styled';
import SelectPeriod from '../../components/UI/SelectPeriod/SelectPeriod';

function SupplierAccountRequest(props) {
    let {
        setUsersManagementUpdate,
        usersManagementUpdate,
        setConfirmationUserModalShow,
        initUserModal,
        contact,
        contactEntity,
        userType,
        token,
        loading,
        usersData,
        count,
        currentPage,
        pageSize,
        setSupplierRequestPageSize,
        setSupplierRequestPage,
        getSupplierRequestPage,
        setSupplierRequestFilters,
        filtersQuery,
        filtering,
        sortQuery,
        reverse,
        setSortQuery,
        setReverse,
        updateShowFilters,
        showFilters,
        selectedSuppliersRequest,
        selectSupplierRequestRow,
        selectedAllRows,
        selectAllSuppliersRequestRows,
        emptySelectedSupllierRequest,
        setNewUserModal
    } = props;
    const { t } = useTranslation();
    let notyf = getNotyfObject();
    let location = useLocation();
    let { uid: entityUid } = useParams();

    // const entityUid = location.state?.entityUid;
    const dateFilterOptions = [
        { label: "-- " + t("dashboard:period", 'Période') + " --", value: "" },
        { label: t("homeFilter:thisYear", 'Cette année'), value: 'thisYear' },
        { label: t("homeFilter:thisMonth", 'Ce mois-ci'), value: 'thisMonth' },
        { label: t("homeFilter:lastMonth", 'Mois précédent'), value: 'lastMonth' }
    ];




    const columns = [
        { title: t("ref:cl", "N° Référencement"), field: 'name', show: true, alignSelf: "center" },
        { title: t("ref:creationDate", "Date de demande"), field: 'creationDate', show: true, alignSelf: "center" },
        { title: t("ref:co", "Fournisseur"), field: 'supplier', show: true, alignSelf: "left" },
        { title: t("ref:ci", "N° Siret"), field: 'siret', show: true, alignSelf: "center" },
        { title: t("ref:status", "Statut"), field: 'status', show: true, alignSelf: "center" }
        //{title: 'Litige', field: 'litigation'} 
    ]


    useEffect(() => {
        updateShowFilters(false)
        let filtersTemp
        if (entityUid)
            filtersTemp = {
                entityUid
            }
        else
            filtersTemp = {
                entityFilter: userType,
            }
        setSupplierRequestFilters(filtersTemp)
        setSupplierRequestPage(1)
        getSupplierRequestPage(1, filtersTemp, sortQuery, reverse, pageSize);
    }, [getSupplierRequestPage, entityUid, pageSize, usersManagementUpdate])

    const isEmptyFilters = () => {
        const filtersList = columns.map(column => column.field);
        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (!showFilters && !isEmpty) {
            handleReset()
        }
    }, [showFilters]);

    const handleShowFilters = () => {
        updateShowFilters(!showFilters)
    }

    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])

    const handleChangePage = useCallback((newPage) => {
        //e.preventDefault();
        setSupplierRequestPage(newPage);
        getSupplierRequestPage(newPage, filtersQuery, sortQuery, reverse, pageSize);
    }, [filtersQuery, reverse, sortQuery, pageSize]);

    const handleSort = useCallback((sort, newReverse) => {
        getSupplierRequestPage(currentPage, filtersQuery, sort, newReverse, pageSize)
    }, [currentPage, filtersQuery, sortQuery, reverse, pageSize])

    const handleApplyFiltering = useCallback((filters) => {
        getSupplierRequestPage(1, filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);


    const handleReset = useCallback(() => {
        let filtersTemp
        if (entityUid)
            filtersTemp = {
                entityUid
            }
        else
            filtersTemp = {
                entityFilter: userType,
            }
        setSortQuery("creationDate")
        setReverse({ creationDate: false })
        setSupplierRequestFilters(filtersTemp);
        setSupplierRequestPage(1);
        emptySelectedSupllierRequest()
        getSupplierRequestPage(1, filtersTemp, "creationDate", { creationDate: false }, pageSize);
    }, [])

    const getQuickFilterLabel = () => {
        return dateFilterOptions?.find(val => val.value === "")?.label
            || dateFilterOptions[0].label
    }

    const setUsersType = (type) => {
        let filtersTemp = {
            usersType: type
        }
        setSortQuery("creationDate")
        setReverse({ creationDate: false })
        setSupplierRequestFilters(filtersTemp);
        setSupplierRequestPage(1);
        emptySelectedSupllierRequest()
        getSupplierRequestPage(1, filtersTemp, "creationDate", { creationDate: false }, pageSize)
    }

    const enableUser = (userUid) => {
        Axios.put(`user/${userUid}/enableUser`).then(response => {
            if (response.data.hasOwnProperty('success')) {
                notyf.success(t("global:success", "L'utilisateur a été activé"));
                getSupplierRequestPage(currentPage, filtersQuery, sortQuery, reverse, pageSize);
            }
        }).catch(response => {
            notyf.error(t("global:error", "Une erreur s'est produite"))
        })
    }

    const disableUser = (userUid) => {
        Axios.put(`user/${userUid}/disableUser`).then(response => {
            if (response.data.hasOwnProperty('success')) {
                notyf.success(t("global:success", "L'utilisateur a été désactivé"));
                getSupplierRequestPage(currentPage, filtersQuery, sortQuery, reverse, pageSize);
            }
        }).catch(response => {
            notyf.error(t("global:error", "Une erreur s'est produite"))
        })
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner animation="border" variant="primary" />
        </SpinnerContainer>
    ) : (
        <NoContent>
            <span>{t("global:empty", "Aucune demande à afficher")}</span>
        </NoContent>
    )

    if (!loading && usersData?.length > 0) {
        tSpinner = null;
        tBody = (
            <TableBody

                columns={columns}
                data={usersData}
                contact={contact}
                contactEntity={contactEntity}
                formatDate={formatDate}
                selectedUsers={selectedSuppliersRequest}
                selectUserRow={selectSupplierRequestRow}
                unselectUserRow={selectSupplierRequestRow}
                enableUser={enableUser}
                disableUser={disableUser}
                entityUid={entityUid}
                setUsersManagementUpdate={setUsersManagementUpdate}
                usersManagementUpdate={usersManagementUpdate}
                setConfirmationUserModalShow={setConfirmationUserModalShow}
                initUserModal={initUserModal}
                setNewUserModal={setNewUserModal}
            />
        )
    }
    return (
        <UMContainer>
            <TitleHelmet title={"Démo Portail | " + t('menu:userManagement')} />
            <Header>
                <HeaderInfos>
                    <HeaderLocation>{t("invoiceList:supplier", "Fournisseur")}</HeaderLocation>
                    <HeaderTitle>{t("global:referenceListAction", "Liste des actions de référencement")}</HeaderTitle>
                </HeaderInfos>
                <HeaderActions>
                    <SearchInputContainer>
                        <SearchInput
                            type="text"
                            placeholder={t("global:search", "Rechercher ...")}
                            autoComplete={"off"}
                            name="quickSearch"
                            value={filtersQuery?.quickSearch || ''}
                            onChange={(e) => quickSearchChange(e.target.value)}
                        />
                        <SearchIcon className="ICDBlue" />
                    </SearchInputContainer>
                    <SelectPeriod
                        value={getQuickFilterLabel()}
                        // onChange={quickFilterChangeHandler} 
                        options={dateFilterOptions}
                    />
                    <TableActions
                        // resetFilters={resetFilters}
                        showFilters={showFilters}
                        setShowFilters={handleShowFilters}
                        userType={userType}
                    />
                </HeaderActions>
            </Header>
            <UMBody>
                <TablePagination
                    currentPage={currentPage}
                    pageChange={handleChangePage}
                    totalElement={count}
                    perPage={pageSize}
                    perPageChange={setSupplierRequestPageSize}
                />
                <Table>
                    <TableHead
                        columns={columns}
                        sortQuery={sortQuery}
                        reverse={reverse}
                        setSortQuery={setSortQuery}
                        setReverse={setReverse}
                        getData={handleSort}
                        selectedAllRows={selectedAllRows}
                        setSelectedAllRows={selectAllSuppliersRequestRows}
                    />
                    <TBody>
                        <TableFilters
                            userType={userType}
                            show={showFilters}
                            formatDate={formatDate}
                            filters={filtersQuery}
                            filtersChange={setSupplierRequestFilters}
                            applyFilters={handleApplyFiltering}
                        />
                        {tBody}
                    </TBody>
                </Table>
                {tSpinner}
                <TablePagination
                    currentPage={currentPage}
                    pageChange={handleChangePage}
                    totalElement={count}
                    perPage={pageSize}
                    perPageChange={setSupplierRequestPageSize}
                />
            </UMBody>

        </UMContainer>

    )
}
const mapStateToProps = (state) => ({
    isAuth: state.auth.token !== null,
    token: state.auth.token,
    loading: state.supplierRequest.loading,
    usersData: state.supplierRequest.data,
    count: state.supplierRequest.count,
    currentPage: state.supplierRequest.currentPage,
    pageSize: state.supplierRequest.pageSize,
    filtersQuery: state.supplierRequest.filtersQuery,
    filtering: state.supplierRequest.filtering,
    sortQuery: state.supplierRequest.sortQuery,
    reverse: state.supplierRequest.reverse,
    selectedSuppliersRequest: state.supplierRequest.selectedSuppliers,
    showFilters: state.supplierRequest.showFilters,
    selectedAllRows: state.supplierRequest.selectedAllRows,
    userType: state.auth.userType
})
const mapDispatchToProps = dispatch => ({
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    getSupplierRequestPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.supplierRequestGetPage(page, filters, sort, reverse, pageSize)),
    setSupplierRequestPage: (page) => dispatch(actions.supplierRequestSetPage(page)),
    setSupplierRequestPageSize: (pageSize) => dispatch(actions.supplierRequestSetPageSize(pageSize)),
    setSupplierRequestFilters: (filters) => dispatch(actions.supplierRequestSetFilterQuery(filters)),
    setSortQuery: (sortQ) => dispatch(actions.supplierRequestSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.supplierRequestSetReverseSort(reverseS)),
    updateShowFilters: (show) => dispatch(actions.supplierRequestUpdateShowFilters(show)),
    getDocumentFile: (uid, type) => dispatch(actions.invoiceGetDocumentFile(uid, type)),
    selectAllSuppliersRequestRows: (selectAll) => dispatch(actions.setSelectedAllSuppliersRequestRows(selectAll)),
    selectSupplierRequestRow: (uid) => dispatch(actions.selectSupplierRequest(uid)),
    unselectSupplierRequestRow: (uid) => dispatch(actions.unselectSupplierRequest(uid)),
    getCompressedDoc: (selection) => dispatch(actions.invoiceGetCompressedDocument(selection)),
    emptySelectedSupplierRequest: () => dispatch(actions.emptySelectedSupplierRequest())
})
export default connect(mapStateToProps, mapDispatchToProps)(SupplierAccountRequest)
