import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import TimeLine from './Timeline/TimeLine';
import ModificationsChat from './ModificationsChat/ModificationsChat';
import { getNotyfObject } from '../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { NavTabsLi, NavTabsLink, NavTabsUl } from '../../../../containers/Entity/Entity.styled';
import { useParams, useLocation } from 'react-router-dom';
import { ISContainer, ISNavContainer, ISNavContent } from './CommandesDeadlineExceededDetailSide.styled';
import Fields from './Fields/Fields';
import Recouvrement from './Recouvrement/Recouvrement';
function CommandesDeadlineExceededDetailSide(props) {
    const {
        tabSideActive,
        setTabSideActive,
        updateRecovery,
        invoiceData,
        setInvoiceData,
        invoiceIntegratedDetail,
        userType
    } = props
    let notyf = getNotyfObject();
    const { t } = useTranslation();

    useEffect(() => {

        setTabSideActive("Recouvrement")


    }, [invoiceData])


    return (
        <ISContainer>
            <ISNavContainer>
                <NavTabsUl isCommand={true}>
                    <NavTabsLi>
                        <NavTabsLink
                            active={tabSideActive === "Recouvrement"}
                            onClick={() => setTabSideActive("Recouvrement")}
                            to="#"
                        >
                            {t("global:recovery", 'Recouvrement')}
                        </NavTabsLink>
                    </NavTabsLi>

                    <NavTabsLi>
                        <NavTabsLink
                            active={tabSideActive === "comments"}
                            onClick={() => setTabSideActive("comments")}
                            to="#"
                        >
                            {t('supplier:comments', "Commentaires")}
                        </NavTabsLink>
                    </NavTabsLi>
                    <NavTabsLi>
                        <NavTabsLink
                            active={tabSideActive === "timeline"}
                            onClick={() => setTabSideActive("timeline")}
                            to="#"
                        >
                            {t("global:timeline", "TimeLine")}
                        </NavTabsLink>
                    </NavTabsLi>

                </NavTabsUl>
            </ISNavContainer>
            <ISNavContent>

                {tabSideActive === "Recouvrement" &&
                    <Recouvrement
                        invoiceData={invoiceData}
                        setInvoiceData={setInvoiceData} />
                }
                {tabSideActive === "timeline" &&
                    <TimeLine entityFilter={invoiceData?.enttityFilter} />
                }
                {tabSideActive === "comments" &&
                    <ModificationsChat />
                }
            </ISNavContent>
        </ISContainer>
    )
}

const mapStateToProps = (state) => ({
    userType: state.auth.userType,
    tabSideActive: state.invoiceDetail.tabSideActive,
    invoiceData: state.invoiceDetail.invoiceData,
})
const mapDispatchToProps = dispatch => ({
    setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
    setInvoiceData: (invoiceData) => dispatch(actions.invoiceDataSuccess(invoiceData))
})
export default connect(mapStateToProps, mapDispatchToProps)(CommandesDeadlineExceededDetailSide)
