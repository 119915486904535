import React, { useEffect, useState } from 'react';
import { Notyf } from 'notyf';
import Axios from '../../axios-proas'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { formatDate, formatNumber, getNotyfObject } from '../../shared/utility';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import { PrimaryBtnOutlineAction, SecondaryBtnOutlineAction } from '../../styles/Common';
import { IDBody, IDContainer, IDHeader, IDHeaderActions, IDHeaderContainer, IDHeaderTitle, IDHeaderInvoiceDate, IDHeaderWrapper, IDInvoiceDate, IDInvoiceDateLabel, IDInvoiceDateValue, IDProgressLineWrapper } from './CommandesDeadlineExceededDetail.styled';
import IDProgressLine from '../../components/CommandesDeadlineExceededDetail/IDProgressLine/IDProgressLine';
import InvoiceView from '../../components/CommandesDeadlineExceededDetail/CommandesDeadlineExceededDetailBody/CommandesDeadlineExceededDetailMain/InvoiceView/InvoiceView';
import InvoiceSide from '../../components/CommandesDeadlineExceededDetail/CommandesDeadlineExceededDetailBody/CommandesDeadlineExceededDetailSide/CommandesDeadlineExceededDetailSide';
import StatusModal from '../../components/CommandesDeadlineExceededDetail/IDProgressLine/StatusModal/StatusModal';
import RecouvermentView from '../../components/CommandesDeadlineExceededDetail/CommandesDeadlineExceededDetailBody/CommandesDeadlineExceededDetailMain/InvoiceView/RecouvermentView';

function CommandesDeadlineExceededDetail(props) {
    const {
        loadingData,
        getInvoiceDataByToken,
        invoiceData,
        invoiceError,
        getInvoiceData,
        tabSideActive,
        userType,
        resetAdmToInitialState
    } = props
    const theme = useTheme()
    let { uid, token } = useParams();
    const notyf = getNotyfObject();
    const { t } = useTranslation();
    let location = useLocation();
    let navigate = useNavigate()
    const [activTap, setActivTap] = useState(tabSideActive)
    const [showStatusModal, setShowStatusModal] = useState(false);
    useEffect(() => { setActivTap(tabSideActive) }, [tabSideActive])
    const [navTabsList, setNavTabsList] = useState([])
    const [statusList, setStatusList] = useState([]);
    const [infoStatus, setInfoStatus] = useState("");
    const [isNew, setIsNew] = useState(false);
    const typeInvoiceToNavListe = (xml, edi, pdf, chorus) => {
        var list = [];
        if (pdf !== null)
            list = ([...list,
            { label: 'PDF', value: 'pdf' }
            ])
        if (xml !== null)
            list = ([...list,
            { label: 'XML', value: 'xml' }
            ])
        if (edi !== null)
            list = ([...list,
            { label: 'EDI', value: 'edi' }
            ])
        if (chorus !== null)
            list = ([...list,
            { label: 'CHORUS', value: 'chorus' }
            ])

        setNavTabsList(list)
    }



    useEffect(() => {
        typeInvoiceToNavListe(invoiceData?.xmlFile, invoiceData?.ediFile, invoiceData?.docFile, invoiceData?.chorusFile)


    }, [invoiceData?.xmlFile, invoiceData?.ediFile, invoiceData?.docFile, invoiceData?.chorusFile])






    useEffect(() => {

        getInvoiceData(uid)

        console.log(invoiceData)
        return () => {
            resetAdmToInitialState()
        }


    }, [uid])


    const diffToDys = (date2) => {
        let date1 = Date.now();
        let diff = Math.abs(date1 - date2);
        return Math.ceil(diff / (1000 * 3600 * 24));
    }
    const editStatus = (type, status, comment, reason) => {
        Axios.put('/invoice//updateStatus',
            {
                "InvoiceId": uid,
                "status": status,
                reason,
                comment,
                type
            }).then(response => {
                getInvoiceData(uid)
                setShowStatusModal(false)
                navigate(-1)
                notyf.success(t("global:success", "Statut modifié"))

            }).catch(err => {
                notyf.error(t('global:error', "Une erreur s'est produite"))
            })
    }

    let mandatJsx = null;

    // if(error) {
    //     notyf.error(t("mandat:generatedErrorMessage"));
    // }

    if (loadingData) mandatJsx = (<Spinner />)

    else if (invoiceData)
        mandatJsx = (
            <>
                <IDHeaderWrapper>
                    <IDHeaderContainer>
                        <IDHeader>
                            <IDHeaderTitle>
                                {t("invoiceEvent:invoiceNumber", "Facture :")}  {invoiceData?.number}&nbsp;
                                ({invoiceData?.supplierName} - {invoiceData?.clientName})
                            </IDHeaderTitle>
                            {
                                invoiceData?.status !== "ERROR" &&
                                <>
                                    <IDHeaderInvoiceDate>
                                        {t("invoiceEvent:dueDate", "Date d'échéance :")} {formatDate(+invoiceData?.dueDate)}

                                    </IDHeaderInvoiceDate>
                                    <IDInvoiceDate>

                                        <IDInvoiceDateLabel>{t("invoiceDetail:deadlineExceeded", "Dépassement :")} </IDInvoiceDateLabel>
                                        <IDInvoiceDateValue>{diffToDys(+invoiceData?.dueDate)} {t("global:days", "Jours")}</IDInvoiceDateValue>

                                    </IDInvoiceDate>
                                </>
                            }
                        </IDHeader>
                        {/* <IDProgressLineWrapper>
                            <IDProgressLine
                                error={+invoiceData?.error}
                                status={invoiceData?.status}
                                //editStatus={editStatus}
                                entityFilter={location?.state?.entityFilter}
                                accessToken={token}
                            />
                        </IDProgressLineWrapper> */}
                    </IDHeaderContainer>
                    {
                        !token &&
                        <IDHeaderActions>
                            <SecondaryBtnOutlineAction
                                color={theme.colors.primary}
                                border={'#809FB8'}

                                onClick={() => setShowStatusModal(true)}
                            >
                                Action
                            </SecondaryBtnOutlineAction>
                            <SecondaryBtnOutlineAction
                                onClick={() => navigate(-1)}
                            >
                                {t("global:back", "Retour")}
                            </SecondaryBtnOutlineAction>
                        </IDHeaderActions>
                    }
                </IDHeaderWrapper>

                <IDBody >
                    {/* {activTap=="Recouvrement" ?   */}
                    {/* //<InvoiceView invoiceData={invoiceData} navTabsList={navTabsList}/> */}
                    <RecouvermentView isNew={isNew} setIsNew={setIsNew} navTabsList={navTabsList} />
                    {/* :<InvoiceView invoiceData={invoiceData} pdfFile={invoiceData.pdfFile} mimeType={"application/pdf"} pdfFileName={invoiceData.docFile} navTabsList={navTabsList}/> */}
                    {/* } */}

                    <InvoiceSide />

                </IDBody>
                {
                    showStatusModal ? (
                        <StatusModal
                            show={showStatusModal}
                            modalClosed={() => setShowStatusModal(false)}
                            statusVal={invoiceData?.status}
                            statusList={null}
                            editStatus={editStatus}
                        />
                    ) : null
                }
            </>
        )

    return (
        <>
            <IDContainer>
                {mandatJsx}
            </IDContainer>
        </>
    )
}
const mapStateToProps = (state) => ({
    loadingData: state.invoiceDetail.loadingData,
    invoiceData: state.invoiceDetail.invoiceData,
    invoiceError: state.invoiceDetail.invoiceError,
    tabSideActive: state.invoiceDetail.tabSideActive,
    userType: state.auth.userType,
    // role: state.auth.role,
    // isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
    // creatorName : state.mandat?.admData?.creatorName
})
const mapDispatchToProps = dispatch => ({
    getInvoiceData: (invoiceUid) => dispatch(actions.getInvoiceData(invoiceUid)),
    getInvoiceDataByToken: (invoiceToken) => dispatch(actions.getInvoiceDataByToken(invoiceToken)),
    // setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
    // updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
    resetAdmToInitialState: () => dispatch(actions.resetAdmToInitialState())
})
export default connect(mapStateToProps, mapDispatchToProps)(CommandesDeadlineExceededDetail)
