import React, { useCallback, useRef, useState } from 'react'
import Axios from '../../../axios-proas';
import axios from 'axios';
import FilterListIcon from '@mui/icons-material/FilterList';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ArchiveIcon from '@mui/icons-material/Archive';
import ReplayIcon from '@mui/icons-material/Replay';
import { CSVLink } from "react-csv";
import { useTranslation } from 'react-i18next';
import PublishIcon from '@mui/icons-material/Publish';
import { Link } from 'react-router-dom';
import { getNotyfObject, getStatusWithKey } from '../../../shared/utility';
import { CTooltip } from '../../UI/CTooltip/CTooltip';
import { TableAction, TableActionLink, TAContainer } from '../../../styles/Table.styled';
import { useTheme } from 'styled-components';
import Export from '../../UI/Export/Export';

function TableActions(props) {
    const { showFilters, filterState, resetData, getCompressedDoc, selectedRows, formatDate, filters, sortQuery, reverse, userType, entityFilter, disableEreporting } = props;
    const { t } = useTranslation();
    const [csvData, setCsvData] = useState();
    const csvBtn = useRef();
    let notyf = getNotyfObject();
    const theme = useTheme();

    const csvHeaders = [
        { label: t("invoiceList:dueDate"), key: "dueDate" },
        { label: t("invoiceList:creationDate"), key: "creationDate" },
        { label: t("invoiceList:number"), key: "number" },
        { label: t("invoiceList:supplier"), key: "supplierName" },
        { label: t("invoiceList:clientName", "Client"), key: "clientName" },
        entityFilter === "supplier" ? { label: "Canal", key: "channel" } : undefined,
        { label: t("invoiceList:status"), key: "status" },
        { label: t("invoiceList:exclusiveAmount"), key: "exclusiveTaxAmount" },
        { label: t("invoiceList:inclusiveAmount"), key: "inclusiveTaxAmount" },
        { label: t("invoiceList:currency"), key: "currency" }
    ];

    const uploadFile = () => {

    }

    const exportSelectionZip = (selectedRows) => {
        let selectedIds = [];
        for (const key in selectedRows) {

            if (selectedRows[key])
                selectedIds.push(key)
        }
        getCompressedDoc(selectedIds)
    }
    const exportCsv = useCallback((selectedRows, filters) => {
        const selectedIds = [];
        for (const key in selectedRows)
            if (selectedRows[key])
                selectedIds.push(key)

        const newFilters = {};
        if (filters)
            for (const key in filters)
                if (filters[key])
                    newFilters[key] = filters[key];

        if (sortQuery)
            newFilters.orderBy = sortQuery;

        if (reverse)
            newFilters.reverse = reverse[sortQuery];

        Axios.get('/invoice//csvCompressedDocument' + (selectedIds.length > 0 ? '?selection=' + selectedIds : ""), { params: newFilters })
            .then((response) => {
                // console.log(response.data.content);
                const csvDataTemp = response.data.content.map(row => {
                    return {
                        ...row,
                        issuingDate: formatDate(+row.issuingDate),
                        creationDate: formatDate(+row.creationDate),
                        status: getStatusWithKey(row?.status),
                        dueDate: formatDate(+row.dueDate),
                        readStatus: row.firstAccessDate !== null ? t("global:yes") : t("global:no")
                    }
                })
                setCsvData(csvDataTemp);
                csvBtn.current.link.click();
            }).catch(err => notyf.error(t('global:error', "Une erreur s'est produite")))
    }, [selectedRows, filters])

    const createEReporting = useCallback((selectedRows) => {
        const selectedIds = [];
        for (const key in selectedRows)
            if (selectedRows[key])
                selectedIds.push(key)
        Axios.post('/ereporting/', {
            type: entityFilter === "supplier" ? 'RECEIVED' : 'SENT',
            selectedIds
        })
            .then((response) => {
                let success = response?.data?.hasOwnProperty('success') ? response.data.success : false;
                if (success)
                    notyf.success(t("global:success", "E-Reporting créé avec succès"));
                else
                    notyf.error(t("ereporting:error", "Une erreur s'est produite"));
            }).catch(err => notyf.error(t('global:error', "Une erreur s'est produite")))
    }, [selectedRows])

    const showExportZip = (selectedRows) => {
        for (const key in selectedRows) {
            if (selectedRows[key])
                return true
        }
        return false
    }

    const testCron = () => { axios.post("http://localhost/proas/ws/checkOcrResponses.php").then(response => { console.log(response.data.message ? response.data.message : response.data.error) }) }
    //const testPost = () => { Axios.post("/invoice", dataTest).then(response => {console.log(response.data.message ? response.data.message : response.data.error)}) }
    //const testPost = () => { axios.post("https://gsews.preprod.dematrust.com/invoice", dataTest).then(response => {console.log(response.data.message ? response.data.message : response.data.error)}) }
    //const testPost = () => { axios.post("http://dematgse-ws.php73.devmeudon.i.icdsc.fr/invoice", dataTest).then(response => {console.log(response.data.message ? response.data.message : response.data.error)}) }

    return (
        <TAContainer>
            <TableAction active={filterState} onClick={() => showFilters()}>
                <span>{t("global:filters")} </span>
                <FilterListIcon />
            </TableAction>
            {
                showExportZip(selectedRows) && (
                    <Export
                        exportPdf={() => exportSelectionZip(selectedRows)}
                        exportCsv={() => exportCsv(selectedRows, filters)}
                        showEreporting={userType === "owner" && !disableEreporting}
                        createEReporting={() => createEReporting(selectedRows)}
                    />
                )
            }
            {
                userType === "supplier" ? (
                    <TableActionLink color={theme.colors.primary} to="/upload">
                        <span>{t("global:uploadInvoi", "Déposer des factures")}</span>
                        <PublishIcon />
                    </TableActionLink>
                ) : null
            }
            {csvData ? (<CSVLink
                ref={csvBtn}
                data={csvData}
                filename={"documentsCsv.csv"}
                headers={csvHeaders}
                separator={";"}>
            </CSVLink>) : null}
        </TAContainer>
    )
}

export default TableActions
