import React from 'react'
import GroupIcon from '@mui/icons-material/Group';
import { TD } from '../../../styles/Table.styled';
import { SupplierLink, SupplierUsersLink } from '../../../containers/ReferentielFournisseur/ReferentielFournisseur.Styled';
import { getChannelWithKey, getEntityStatusWithKey, showColumn, getAlignPosition } from '../../../shared/utility';
import { useTranslation } from 'react-i18next';

function TableBody(props) {
    const { suppliersData, formatDate, entityFilter, setEntitydetail, columns } = props;
    const { t } = useTranslation();

    return (
        <>
            {
                suppliersData ? suppliersData.map(row => (
                    <tr key={row.id} >
                        <TD style={{ display: !showColumn(columns, "code") && 'none', textAlign: getAlignPosition(columns, "code") }}>
                            {row.code}
                        </TD>
                        <TD style={{ display: !showColumn(columns, "name") && 'none', textAlign: getAlignPosition(columns, "name") }}>
                            <div className="tableBody__companyName">
                                <SupplierLink
                                    to={`/entity/${row.uid}`}
                                    state={{ entityType: 'supplier' }}
                                >
                                    {row.name}
                                </SupplierLink>
                            </div>
                        </TD>
                        <TD style={{ display: !showColumn(columns, "vat") && 'none', textAlign: getAlignPosition(columns, "vat") }}>
                            {row?.vat}
                        </TD>
                        <TD style={{ display: !showColumn(columns, "status") && 'none', textAlign: getAlignPosition(columns, "status") }}>
                            {getEntityStatusWithKey(row.status)}
                        </TD>
                        <TD style={{ display: !showColumn(columns, "channel") && 'none', textAlign: getAlignPosition(columns, "channel") }}>
                            {t("global:" + row?.channel?.toLowerCase(), getChannelWithKey(row?.channel))}
                        </TD>
                        <TD style={{ display: !showColumn(columns, "creationDate") && 'none', textAlign: getAlignPosition(columns, "creationDate") }}>
                            {formatDate(+row.creationDate)}
                        </TD>
                        <TD >
                            <SupplierUsersLink
                                className="hoverLink"
                                to={`/entity/${row.uid}`}
                                state={{ tab: 'contacts', entityType: 'supplier' }}
                            >
                                {row?.totalUsers}
                                <GroupIcon style={{ fontSize: "1rem", marginLeft: "2px" }} />
                            </SupplierUsersLink>
                        </TD>
                    </tr>
                )) : null
            }
        </>
    )
}

export default TableBody
