import * as actionTypes from './actionTypes';
import Axios from '../../axios-proas';

export const tasksStart = () => ({
  type: actionTypes.TASKS_START
})

export const tasksSetPage = (page) => ({
  type: actionTypes.TASKS_SET_PAGE,
  currentPage: page
})

export const tasksSetPageSize = (pageSize) => ({
  type: actionTypes.TASKS_SET_PAGESIZE,
  pageSize: pageSize
})

export const tasksSetSearchInput = (inputValue) => ({
  type: actionTypes.TASKS_SEARCH_INPUT,
  searchInput: inputValue
})



export const tasksSuccess = (data, count) => ({
  type: actionTypes.TASKS_SUCCESS,
  data: data,
  count: count
});

export const tasksFail = (error) => ({
  type: actionTypes.TASKS_FAIL,
  error: error
})

export const tasksGetPage = (page, filters, sort, reverse, searchField, pageSize = 100) => {
  let newFilters = {
    page: page,
    pageSize
  }
  if (filters) {
    for (const key in filters) {
      if (filters[key])
        newFilters = {
          ...newFilters,
          [key]: filters[key]
        }
    }
  }
  if (sort) {
    newFilters = {
      ...newFilters,
      orderBy: sort
    }
  }
  if (searchField) {
    newFilters = {
      ...newFilters,
      searchInput: searchField
    }
  }
  if (reverse) {
    newFilters = {
      ...newFilters,
      reverse: reverse[sort]
    }
  }
  return dispatch => {
    dispatch(tasksStart());
    Axios.get('/user//tasks', {
      params: { ...newFilters }
    })
      .then(response => {
        dispatch(tasksSuccess(response.data.events, response.data.events.length))
      }).catch(err => {
        dispatch(tasksFail(err.response?.data?.error))
      })
  }
}

export const customersGetPage = (page, filters, sort, reverse, searchField) => {
  let newFilters = {
    page: page
  }
  if (filters) {
    for (const key in filters) {
      if (filters[key])
        newFilters = {
          ...newFilters,
          [key]: filters[key]
        }
    }
  }
  if (sort) {
    newFilters = {
      ...newFilters,
      orderBy: sort
    }
  }
  if (searchField) {
    newFilters = {
      ...newFilters,
      searchInput: searchField
    }
  }
  if (reverse) {
    newFilters = {
      ...newFilters,
      reverse: reverse[sort]
    }
  }
  return dispatch => {
    dispatch(tasksStart());
    Axios.get('/entity?type=client', {
      params: { ...newFilters }
    })
      .then(response => {
        dispatch(tasksSuccess(response.data.result, response.data.count))
      }).catch(err => {
        dispatch(tasksFail(err.response?.data?.error))
      })
  }
}

export const tasksSetFilterQuery = (filtersQuery) => ({
  type: actionTypes.TASKS_SET_FILTERSQUERY,
  filters: filtersQuery,
  filtering: tasksUpdateFiltering(filtersQuery)
});

export const tasksUpdateFiltering = (filtersQuery) => {
  let filter = false;
  if (filtersQuery) {
    for (const key in filtersQuery) {
      if (filtersQuery[key]) {
        filter = true
      }
    }
  }
  return filter
}

export const tasksUpdateShowFilters = (show) => ({
  type: actionTypes.TASKS_UPDATE_SHOWFILTERS,
  showFilters: show
});

export const tasksSetSortQuery = (sortQuery) => ({
  type: actionTypes.TASKS_SET_SORTQUERY,
  sortQuery: sortQuery
})

export const tasksSetReverseSort = (reverse) => ({
  type: actionTypes.TASKS_SET_REVERSESORT,
  reverse: reverse
})