import React, { useCallback, useEffect, useState, useRef } from "react"
import TableActions from '../../components/Documentation/TableActions/TableActions';
import TableHead from '../../components/Documentation/TableHead/TableHead';
import TableBody from '../../components/Documentation/TableBody/TableBody';
import TableFilters from '../../components/Documentation/TableFilters/TableFilters';

import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';

import TablePagination from '../../components/UI/TablePagination/TablePagination';

import Axios from "../../axios-proas";
import { Notyf } from "notyf";
import { connect } from 'react-redux';


import * as actions from '../../store/actions/index';
import { useTranslation } from 'react-i18next';

import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import { NoContent, SpinnerContainer, Table, TBody } from '../../styles/Table.styled';
import { DContainer, DBody, DTableContainer } from './Documentation.Styled';
import { Spinner } from 'react-bootstrap';

function Documentation(props) {
    const {
        userUid,
        userLevel,
        loading,
        documentationData,
        count,
        currentPage,
        pageSize,
        getDocumentationPage,
        setDocumentationPage,
        setDocumentationPageSize,
        sortQuery,
        reverse,
        filtersQuery,
        showFilters,
        userRole,
        userType,
        updateShowFilters,
        setDocumentationFilters,
        setSortQuery,
        setReverse
    } = props;
    const { t } = useTranslation();

    const notyf = new Notyf();
    const [file, setFile] = useState(null)
    const [formData, setFormData] = useState()
    const [density, setDensity] = useState("2.25rem")
    const [columns, setColumns] = useState([])

    useEffect(() => {
        let columnsCopy = [
            { title: userType == "owner" ? t("documentList:Visible") : t("documentDetails:Lu"), field: 'visible', width: "80px", show: true, alignSelf: "left" },
            { title: t("documentList:dateCreation"), field: 'creationDate', width: "200px", show: true, alignSelf: "center" },
            { title: t("documentList:docName"), field: 'docName', width: "200px", show: true, alignSelf: "left" },
            { title: t("documentList:docType"), field: 'docType', width: "100px", show: true, alignSelf: "left" }
        ];
        if (userType === "owner") {
            columnsCopy = [
                ...columnsCopy,
                { title: t("documentList:typeEntity"), field: 'entitiesType', width: "120px", show: true, alignSelf: "left" },
                { title: t("documentList:listEntities"), field: 'entitiesList', width: "120px", show: true, alignSelf: "left" }
            ]
        }
        setColumns(columnsCopy);
    }, [userType])


    useEffect(() => {
        getDocumentationPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
    }, [currentPage, pageSize])

    const isEmptyFilters = () => {
        if (filtersQuery === null)
            return true;
        let filtersList = columns.map(column => column.field)
        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (!showFilters && !isEmpty) {
            resetFilters()
        }
    }, [showFilters]);


    const handleDocumentationVisibility = (uid, entity, visible) => {
        Axios.put('/documentation/' + uid + '/visibility', { visible: visible, entity: entity }
        ).then(response => {
            if (response.data.success) {
                notyf.success(t("global:success", 'document modifiié'))
                getDocumentationPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)
            }
        })
    }


    const formatDate = useCallback((date) => {
        if (date !== null && date.valueOf() > 0) {
            let newDate = new Date(date);
            let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
            let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
            return day + '/' + month + '/' + newDate.getFullYear()
        } else return null;
    }, [])
    const convertDate = (date) => {
        let dateArray = date.split(':');
        return [formatDate(+dateArray[0]), formatDate(+dateArray[1])]
    }
    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue = reverse[field] ? reverse[field] : false
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getDocumentationPage(currentPage, filtersQuery, field, newReverse, pageSize)
    }

    const handlePageChange = useCallback((newPage) => {
        setDocumentationPage(newPage);
    }, [currentPage]);

    const handleApplyFiltering = useCallback((filters) => {
        getDocumentationPage(1, filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);

    const resetFilters = () => {
        setDocumentationFilters(null);
        setDocumentationPage(1)
        getDocumentationPage(1, null, sortQuery, reverse, pageSize)
    }
    const handleDocumentationDownload = (uid, entity, typeEntity) => {
        Axios.get('/documentation/' + uid + '/download',
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                params: {
                    entity: entity,
                    typeEntity: typeEntity
                }
            })
            .then(response => {
                if (response.status === 200) {
                    let blob = new Blob([response.data.pdfFile], { type: response.request.getResponseHeader("Content-Type") })
                    let filename = ""
                    const disposition = response.request.getResponseHeader('Content-Disposition')
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(disposition);
                        if (matches !== null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }

                    const downloadUrl = URL.createObjectURL(blob)
                    let a = document.createElement("a");
                    a.href = downloadUrl;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    getDocumentationPage(currentPage, filtersQuery, sortQuery, reverse, pageSize)

                } else {
                    notyf.error("document non trouvé");
                }
            }).catch(err => {
                notyf.error('Une erreur s\'est produite !')
            })
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
    ) : <NoContent><span>{t("documentList:noDocumentation")}</span></NoContent>;

    if (!loading && documentationData?.length > 0 && columns?.length > 0) {
        tSpinner = null;
        tBody = (<TableBody
            columns={columns}
            handleDocumentationVisibility={handleDocumentationVisibility}
            handleDocumentationDownload={handleDocumentationDownload}
            userUid={userUid}
            userLevel={userType}
            documentationData={documentationData}
            userRole={userRole} />)
    }

    return (
        <DContainer>
            <TitleHelmet title={"Démo Portail | " + t("menu:documentation")} />
            <Header>
                <HeaderInfos>
                    <HeaderLocation>
                        Documentation
                    </HeaderLocation>
                    <HeaderTitle>
                    </HeaderTitle>
                </HeaderInfos>
                <HeaderActions>
                    <TableActions
                        resetFilters={resetFilters}
                        showFilters={showFilters}
                        setShowFilters={updateShowFilters}
                        userType={userType}
                    />

                </HeaderActions>
            </Header>
            <DBody>
                <DTableContainer>
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setDocumentationPageSize}
                    />
                    <Table>
                        <TableHead
                            columns={columns}
                            reverse={reverse}
                            columnClickHandler={columnClickHandler}
                            userRole={userRole}
                            userLevel={userType}
                        />
                        <TBody height={density}>
                            {showFilters && <TableFilters
                                columns={columns}
                                showFilters={showFilters}
                                formatDate={formatDate}
                                filters={filtersQuery}
                                filtersChange={setDocumentationFilters}
                                applyFilters={handleApplyFiltering}
                                userLevel={userType}
                                userType={userType}
                                convertDate={convertDate}
                            />}

                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner}
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setDocumentationPageSize}
                    />
                </DTableContainer>
            </DBody>
        </DContainer>
    )
}
const mapStateToProps = (state) => ({
    loading: state.documentation.loading,
    documentationData: state.documentation.data,
    count: state.documentation.count,
    currentPage: state.documentation.currentPage,
    pageSize: state.documentation.pageSize,
    sortQuery: state.documentation.sortQuery,
    reverse: state.documentation.reverse,
    filtersQuery: state.documentation.filtersQuery,
    showFilters: state.documentation.showFilters,
    userRole: state.auth.role,
    userType: state.auth.userType,
    userLevel: state.auth.userLevel,
    userUid: state.auth.userUid
})
const mapDispatchToProps = dispatch => ({
    getDocumentationPage: (page, filters, sort, reverse, pageSize) => dispatch(actions.documentationGetPage(page, filters, sort, reverse, pageSize)),
    setDocumentationPage: (page) => dispatch(actions.documentationSetPage(page)),
    setDocumentationPageSize: (pageSize) => dispatch(actions.documentationSetPageSize(pageSize)),
    setSortQuery: (sortQ) => dispatch(actions.documentationSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.documentationSetReverseSort(reverseS)),
    setDocumentationFilters: (filters) => dispatch(actions.documentationSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.documentationUpdateShowFilters(show))
})
export default connect(mapStateToProps, mapDispatchToProps)(Documentation)

