import React, { useEffect, useState, useMemo, useCallback } from 'react'
import Axios from '../../../../../axios-proas'
import {
  EntInfosContainer, EIFormContainer, EIFormCol,
  TelInput, FormLabelSpec, FormGroup
} from './Fields.styled'
import {
  FormLabel
} from "../../../../../styles/Common";
import { checkValidity, getNotyfObject, formatDate, convertFileToBase64 } from '../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

function Fields({ paymentSignature, setPaymentSignature, formatNumber }) {

  const theme = useTheme();
  const { t } = useTranslation();



  // const selectRowHandler = (e, ref) => {
  //   let temp = { ...selectedOwnersState[ref], selected: e.target.checked }
  //   temp.selected = e.target.checked
  //   setSelectedOwnersState(Object.values({ ...selectedOwnersState, [ref]: temp }))
  // }


  return (
    <>
      <EntInfosContainer>
        <EIFormContainer>

          <FormGroup>
            <FormLabel htmlFor="name" >{t("invoiceEvent:parapheurNumber", "Numéro parapheur")}</FormLabel>
            <FormLabelSpec>{paymentSignature?.paymentNumber || ""} </FormLabelSpec>
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="siret" >{t("invoiceDetail:scheduledPaymentDate", "Date de paiement planifiée")}</FormLabel>
            <FormLabelSpec>{formatDate(+paymentSignature?.paymentDate) || ""} </FormLabelSpec>
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="siren" >{t("invoiceEvent:invoicesNumber", "Nombre de factures")}</FormLabel>
            <FormLabelSpec>{paymentSignature?.nbInvoice || ""} </FormLabelSpec>
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="vat" >{t("dashboard:totalAmount", "Montant total")}</FormLabel>
            <FormLabelSpec>{formatNumber(+paymentSignature?.totalAmount) || "0"} €</FormLabelSpec>
          </FormGroup>

        </EIFormContainer>
      </EntInfosContainer>
    </>
  );
}

export default Fields