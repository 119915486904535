import React, { useState } from 'react'
import Axios from '../../../axios-proas';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link, useNavigate } from 'react-router-dom';
import { formatDate, showColumn, getAlignPosition } from '../../../shared/utility'
import EmailIcon from '@mui/icons-material/Email';
import { Notyf } from "notyf";
import { ELDItems, ELDItemsContainer, ELDItemsListContainer, ELDVal, ELDValue, EntitiesListDetail, TD } from '../../../styles/Table.styled';
import { DocDawnload, EntityComponent } from '../../../containers/Documentation/Documentation.Styled'
import { useTranslation } from 'react-i18next';
import { Content, DivLabel, DivGlp, CBtn, Container, BtnDivContent } from '../../NewDocumentation/EntitiesComponent/EntitiesComponent.Styled'
import DraftsIcon from '@mui/icons-material/Drafts';

function TableBody(props) {
    const { handleDocumentationVisibility, handleDocumentationDownload, documentationData, userRole, userLevel, columns } = props;
    const history = useNavigate();
    const { t } = useTranslation();
    const [visibility, setVisibility] = useState()
    const notyf = new Notyf();
    const [entitiesArray, setEntitiesArray] = useState(false)
    const navigate = useNavigate();
    const ShowEntities = (a) => {

        let array = documentationData.filter(b => b.id == a)[0].entities
        let words = array.split(',')
        setEntitiesArray(words)
        var x = document.getElementById(a);
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }

    const formatEntities = (entities) => {
        return entities.split(',')
    }


    const handleReadDocumentation = () => {

    }


    return (
        <>
            {
                documentationData ? documentationData.map(row => (
                    <tr key={row.id} onClick={() => navigate("/DocumentationView", { state: { uid: row.uid, entity: row.entity, type: "others" } })}
                    >
                        <TD style={{ display: !showColumn(columns, "visible") && 'none', textAlign: getAlignPosition(columns, "visible") }}>

                            {
                                userLevel == "owner" ?
                                    (
                                        row.visible == 1 ?
                                            <VisibilityIcon className="documentation_visibility" onClick={() => handleDocumentationVisibility(row.uid, row.entity, 0)} />
                                            : <VisibilityOffIcon className="documentation_visibility" onClick={() => handleDocumentationVisibility(row.uid, row.entity, 1)} />
                                    ) :

                                    (
                                        row.firstAccessDate != null ?
                                            <DraftsIcon style={{ color: "#B8B8B8" }} />
                                            : <EmailIcon style={{ color: "#505050" }} />)
                            }
                        </TD>
                        <TD style={{ display: !showColumn(columns, "creationDate") && 'none', textAlign: getAlignPosition(columns, "creationDate") }}>
                            {formatDate(+row.creationDate)}
                        </TD>
                        <TD style={{ display: !showColumn(columns, "docName") && 'none', textAlign: getAlignPosition(columns, "docName") }}>
                            <DocDawnload
                            //    onClick={
                            //     userLevel=="owner" ?
                            //         () => handleDocumentationDownload(row.uid, row.entity, "owner")
                            //         : () => handleDocumentationDownload(row.uid, row.entity, "others") 
                            //     } 
                            >
                                {row.docName}
                            </DocDawnload>
                            {/* <span className="documentation_link" 
                                        onClick={
                                            userLevel=="owner" ?
                                                () => handleDocumentationDownload(row.uid, row.entity, "owner")
                                                : () => handleDocumentationDownload(row.uid, row.entity, "others") 
                                            }
                                        > {row.docName}</span> */}

                        </TD>

                        <TD style={{ display: !showColumn(columns, "docType") && 'none', textAlign: getAlignPosition(columns, "docType") }}>
                            <span>{row.docType}</span>
                        </TD>

                        {
                            userLevel == "owner" ?
                                <>
                                    <TD style={{ display: !showColumn(columns, "entitiesType") && 'none', textAlign: getAlignPosition(columns, "entitiesType") }}>
                                        <span>{t('documentList:' + row.entitiesType)}</span>
                                    </TD>
                                    <TD style={{ display: !showColumn(columns, "entitiesList") && 'none' }}>
                                        <EntitiesListDetail>
                                            <ELDValue>
                                                {row.entities}
                                            </ELDValue>
                                            <ELDItemsContainer>
                                                <ELDItemsListContainer>
                                                    <ELDItems>
                                                        {
                                                            formatEntities(row.entities).map(entity => (
                                                                <ELDVal key={entity}  >
                                                                    {entity}
                                                                </ELDVal>
                                                            ))
                                                        }
                                                    </ELDItems>
                                                </ELDItemsListContainer>
                                            </ELDItemsContainer>
                                        </EntitiesListDetail>
                                    </TD>

                                </>
                                : null
                        }

                    </tr>
                )) : null
            }
        </>
    )
}

export default TableBody

