
import ReactModal from 'react-modal-resizable-draggable';
import Draggable from 'react-draggable';
import "./TimeLineModal.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
// import  Timeline from "../../components/InvoiceDetail/InvoiceBody/InvoiceSide/Timeline/Timeline";
import TimeLine from "./../../../components/InvoiceDetail/InvoiceBody/InvoiceSide/Timeline/TimeLine";
import ModificationsChat from "./../../../components/InvoiceDetail/InvoiceBody/InvoiceSide/ModificationsChat/ModificationsChat";
import { useTranslation } from 'react-i18next';


function ModificationsChatModal({ modalIsOpen, setModalIsOpen, openModal, closeModal }) {
    const { t } = useTranslation();
    return (
        <div style={{ display: "flex", width: "99%" }}>
            <Draggable
                handle=".handle"
                defaultPosition={{ x: 0, y: 0 }}
                position={null}
                grid={[25, 25]}
                scale={1}
            // onStart={handleStart}
            // onDrag={handleDrag}
            // onStop={handleStop}
            >

                <div className="box handle">
                    <div className="entete">
                        <div className="enteteTitle">{t("supplier:comments", "Commentaires")}</div>
                        <div className="enteteClose"><FontAwesomeIcon icon={faXmark} style={{ marginLeft: "5px", marginRight: "10px" }} onClick={e => closeModal()} /></div>
                    </div>
                    <div style={{ display: "flex", margin: "7px", height: "427px" }}>
                        <ModificationsChat />
                    </div>
                </div>
            </Draggable>
        </div>


    );
}




export default ModificationsChatModal;