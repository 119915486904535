import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from '../../axios-proas';
import { useParams } from 'react-router-dom';
import logo from '../../assets/images/ICD-international-logo.png';
import './ContractMessageSignature.css'
import { BtnAction, PrimaryBtnOutlineAction, SecondaryBtnOutlineAction, FormGroup, FormLabel } from '../../styles/Common';
import { Notyf } from "notyf";
import AuthCode from 'react-auth-code-input';

function ContractMessageSignature() {
    const notyf = new Notyf();
    const [phoneNumber, setPhoneNumber] = useState()
    const [adminContact, setAdminContact] = useState()
    const randSmsCode = useMemo(() => Math.floor(100000 + Math.random() * 900000), [])
    const { token } = useParams();
    const [smsCode, setSmsCode] = useState("365001")
    const [smsValideCode, setSmsValideCode] = useState(false)
    const [signatureCode, setSignatureCode] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        Axios.get('/entity/' + token + '/adminContact').then(response => {
            // console.log(response?.data.adminContact)
            setAdminContact(response?.data.adminContact)
            //sendSmsCode(response?.data.adminContact?.telephone_number)
        }).catch(response => {
            // setSendSmsLoading(false)
            // notyf.error(t("mandat:failedSendingMessageText"));
        })

    }, [])

    const sendSmsCode = (phone) => {
        Axios.get('/entity/' + phone + '/sendSmsToSign', {
            params: {
                smsMessage: "Bonjour, Votre code de confirmation pour signature est " + randSmsCode
            }
        }).then(response => {
            // notyf.success(t("mandat:sendMessageText"))
            // setSendSmsLoading(false)
        }).catch(response => {
            // setSendSmsLoading(false)
            // notyf.error(t("mandat:failedSendingMessageText"));
        })
        const timer = setTimeout(() => {
            // navigate(-1)
        }, 120000);
        return () => clearTimeout(timer);

    }


    // const editCodeConfirmation = () => {
    //     if (smsCode == randSmsCode) {
    //         setConfirmIdentity(true)
    //     } else {
    //         notyf.error("Code de confirmation n'est pas correct!")
    //     }
    // }


    // const smsCodeChangeHandler = (e, index) => {
    //     let code = e.target.value;
    //     if (checkSignatureSmsCode()) setSmsValideCode(true)
    //     else setSmsValideCode(false)
    //     setSignatureCodeTable(Object.values({ ...signatureCodeTable, [index]: code }))
    // }

    const checkSignatureSmsCode = (res) => {
        const codeMessage = res
        if (codeMessage?.length == 6 && smsCode == codeMessage)
            return true
        else
            return false
    }

    const saveSignatureHandler = () => {
        if (checkSignatureSmsCode(signatureCode)) {
            notyf.success("global:success", "Contrat signé")
            Axios.put('/entity/' + adminContact?.uid + '/newSupplierSignatureEvent', {
                entityUid: adminContact?.entityUid
            }).then(response => {
                navigate(`/supplierAccountProgress/${token}`)
            }).catch(response => {
                // notyf.error(t("mandat:failedSendingMessageText"));
            })
        } else
            notyf.error("code incorrect")
    }

    const handleOnChange = (res) => {
        setSignatureCode(res);
        // if (checkSignatureSmsCode(res)) setSmsValideCode(true)
        // else setSmsValideCode(false)
    };


    return (
        <div className="signin__container">

            <div className="signin__FromWrap">
                <div className="signin__fromContent">
                    <div className="signin__formMess">
                        <div style={{ textAlign: "center", marginBottom: "27px" }}>
                            <img src={logo} alt="logo" style={{ width: "243px", marginBottom: "30px" }} />
                        </div>
                        <p style={{ fontSize: "1rem", padding: "0px 0px 10px 0px", color: "#505050" }}>Un code de signature a été envoyé par sms au numéro de<br /> téléphone se terminant par 86.10 </p>
                        <p style={{ fontSize: "1rem", padding: "0px 0px 10px 0px", color: "#505050" }}>Il est valable pour 30 minutes, vous devez effectuer une<br /> nouvelle demande de code passé ce délai</p>

                        <FormGroup style={{ padding: "15px 0px" }}>
                            <FormLabel htmlFor="docs" style={{ margin: "auto", marginLeft: "0px" }}>Code de signature</FormLabel>
                            <div style={{ width: "60%", display: "flex", justifyContent: "space-between" }}>
                                <AuthCode
                                    containerClassName="autoInputCodeContainer"
                                    inputClassName={checkSignatureSmsCode(signatureCode) ? "autoInputCodeValid" : "autoInputCode"}
                                    allowedCharacters='numeric'
                                    onChange={handleOnChange}
                                // ref={AuthInputRef}
                                />
                            </div>
                        </FormGroup>
                        <PrimaryBtnOutlineAction style={{ marginBottom: "11px" }}>
                            Code non reçu ou nouvelle demande
                        </PrimaryBtnOutlineAction>
                        <PrimaryBtnOutlineAction onClick={e => saveSignatureHandler(e)}>
                            Valider ma signature
                        </PrimaryBtnOutlineAction>

                    </div>
                </div>
            </div>
        </div>
    );

}

export default ContractMessageSignature