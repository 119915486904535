import React, { useCallback, useEffect, useState } from 'react';
import TableActions from '../../components/InstanceManagment/TableActions/TableActions';
import TableHead from '../../components/InstanceManagment/TableHead/TableHead';
import TableBody from '../../components/InstanceManagment/TableBody/TableBody';
import TableFilters from '../../components/InstanceManagment/TableFilters/TableFilters';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import TitleHelmet from '../../components/UI/TitleHelmet/TitleHelmet';
import { useTranslation } from 'react-i18next';
import { NoContent, SpinnerContainer, Table, TBody } from '../../styles/Table.styled';
import { Header, HeaderActions, HeaderInfos, HeaderLocation, HeaderTitle } from '../../styles/Common';
import TablePagination from '../../components/UI/TablePagination/TablePagination';
import { EMBody, EMContainer, EMTableContainer } from './InstanceManagment.styled';
import { Spinner } from 'react-bootstrap';

function InstanceManagment(props) {
    const {
        loading,
        instanceData,
        count,
        currentPage,
        pageSize,
        getInstancePage,
        setInstancePage,
        setInstancePageSize,
        sortQuery,
        reverse,
        filtersQuery,
        showFilters,
        userRole,
        updateShowFilters,
        entityOwnerDetail,
        setEntitydetail,
        setInstanceFilters,
        setSortQuery,
        setReverse
    } = props;
    const { t } = useTranslation();
    const [density, setDensity] = useState("2.25rem")
    const [columns, setColumns] = useState([
        { title: t("client:instanceName"), field: 'instanceName', show: true, alignSelf: "left" },
        { title: t("global:creationDate"), field: 'creationDate', show: true, alignSelf: "center" },
        { title: t("client:usersNumber"), field: 'usersNumber', show: true, alignSelf: "left" },
        { title: t("supplier:status"), field: 'status', show: true, alignSelf: "left" }
    ])


    useEffect(() => {

        getInstancePage(currentPage, filtersQuery, sortQuery, reverse, pageSize)

    }, [currentPage, pageSize])

    const isEmptyFilters = () => {
        if (filtersQuery === null)
            return true;
        let filtersList = columns.map(column => column.field)
        let empty = true;
        filtersList.forEach(filter => {
            if (filtersQuery[filter])
                empty = false;
        })
        return Object.keys(filtersQuery).length === 0 || empty;
    }

    useEffect(() => {
        const isEmpty = isEmptyFilters();
        if (!showFilters && !isEmpty) {
            resetFilters()
        }
    }, [showFilters]);

    const columnClickHandler = (field, reverse) => {
        setSortQuery(field);
        let reverseValue = reverse[field] ? reverse[field] : false
        let newReverse = { [field]: !reverseValue }
        setReverse(newReverse);
        getInstancePage(currentPage, filtersQuery, field, newReverse, pageSize)
    }

    const handlePageChange = useCallback((newPage) => {
        setInstancePage(newPage);
    }, [currentPage]);

    const handleApplyFiltering = useCallback((filters) => {
        getInstancePage(1, filters, sortQuery, reverse, pageSize);
    }, [currentPage, filtersQuery, reverse, sortQuery, pageSize]);

    const resetFilters = () => {
        setInstanceFilters(null);
        setInstancePage(1)
        getInstancePage(1, null, sortQuery, reverse, pageSize)
    }

    let tBody = null;
    let tSpinner = loading ? (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
    ) : (
        <NoContent>
            <span>{t("clientList:noClients")}</span>
        </NoContent>
    );

    if (!loading && instanceData?.length > 0) {
        tSpinner = null;
        tBody = (<TableBody
            columns={columns}
            clientsData={instanceData}
            userRole={userRole}
            setEntitydetail={setEntitydetail}
            entityOwnerDetail={entityOwnerDetail}
        />)
    }

    return (
        <EMContainer>
            <TitleHelmet title={"Démo Portail | " + t("menu:entities")} />
            <Header>
                <HeaderInfos>
                    <HeaderLocation>{t("menu:customerInstance", "Gestion des instance client")}</HeaderLocation>
                </HeaderInfos>
                <HeaderActions>
                    <TableActions
                        resetFilters={resetFilters}
                        showFilters={showFilters}
                        setShowFilters={updateShowFilters}
                    />
                </HeaderActions>
            </Header>
            <EMBody>
                <EMTableContainer>
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setInstancePageSize}
                    />
                    <Table>
                        <TableHead
                            columns={columns}
                            reverse={reverse}
                            columnClickHandler={columnClickHandler}
                            userRole={userRole}
                        />
                        <TBody height={density}>
                            <TableFilters
                                columns={columns}
                                showFilters={showFilters}
                                instanceFilters={filtersQuery}
                                filtersChange={setInstanceFilters}
                                applyFilters={handleApplyFiltering}
                            />
                            {tBody}
                        </TBody>
                    </Table>
                    {tSpinner}
                    <TablePagination setDensity={setDensity} columns={columns} setColumns={setColumns}
                        currentPage={currentPage}
                        pageChange={handlePageChange}
                        totalElement={count}
                        perPage={pageSize}
                        perPageChange={setInstancePageSize}
                    />
                </EMTableContainer>
            </EMBody>
        </EMContainer>
    )
}

const mapStateToProps = (state) => ({
    loading: state.instance.loading,
    instanceData: state.instance.data,
    count: state.instance.count,
    currentPage: state.instance.currentPage,
    pageSize: state.instance.pageSize,
    sortQuery: state.instance.sortQuery,
    reverse: state.instance.reverse,
    filtersQuery: state.instance.filtersQuery,
    showFilters: state.instance.showFilters,
    userRole: state.auth.role,
    entityOwnerDetail: state.clients.entity

})
const mapDispatchToProps = dispatch => ({
    getInstancePage: (page, filters, sort, reverse, pageSize) => dispatch(actions.instanceGetPage(page, filters, sort, reverse, pageSize)),
    setInstancePage: (page) => dispatch(actions.instanceSetPage(page)),
    setInstancePageSize: (pageSize) => dispatch(actions.instanceSetPageSize(pageSize)),
    setSortQuery: (sortQ) => dispatch(actions.instanceSetSortQuery(sortQ)),
    setReverse: (reverseS) => dispatch(actions.instanceSetReverseSort(reverseS)),
    setInstanceFilters: (filters) => dispatch(actions.instanceSetFilterQuery(filters)),
    updateShowFilters: (show) => dispatch(actions.instanceUpdateShowFilters(show)),
    setEntitydetail: (entity) => dispatch(actions.setEntitydetail(entity)),
})
export default connect(mapStateToProps, mapDispatchToProps)(InstanceManagment)
